import React from "react";
import { connect } from "react-redux";
import { useAlert } from "react-alert";
import { compose } from "redux";
import { Grid, makeStyles, Button, Theme } from "@material-ui/core";

import { GlobalState, User, UserState } from "utils/types";
import { reqeustAdminAccess } from "redux/actions/user";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    backgroundImage: "url('../assets/images/admin_bumper_bg.svg')",
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  block: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    height: "80vh",
    width: "85%",
    textAlign: "center",
    padding: "4%",
    position: "relative"
  },
  requestButton: {
    border: "none",
    backgroundColor: "#F5A95F",
    borderRadius: "2px",
    fontSize: "2.5vh",
    color: "#FFFFFF",
    padding: "10px",
    cursor: "pointer",
    margin: theme.spacing(4, 0),
    textTransform: "inherit",
    "&:hover": {
      backgroundColor: "#F5A95F"
    }
  },
  statusTag: {
    borderRadius: "0px 100px 100px 0px",
    color: "#FFFFFF",
    position: "absolute",
    left: 0,
    top: "6vh",
    padding: "1.5vh"
  },
  right: {
    backgroundImage: "url('../assets/images/admin_bumper-img.svg')",
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
  }
}));

interface Props {
  me: User;
  userState: UserState;
  reqeustAdminAccess: (data: any) => void;
}

function AdminBumper(props: Props) {
  const user = props.me;
  const classes = useStyles();
  const alert = useAlert();
  const bumperPageMessage = !user.requestData
    ? "It does not appear that you have been granted admin permissions to the FPD admin portal."
    : user.requestData.requestStatus === "denied"
    ? "Your request for admin permissions has been denied."
    : "Your request for admin permissions is pending approval";

  const handleRequestAdminAcess = () => {
    props.reqeustAdminAccess({
      id: user.id,
      successCB: () => {
        alert.success("Your request for admin access is successfully sent!", {
          timeout: 5000
        });
      }
    });
  };

  if (props.userState.error != null) {
    throw new Error(props.userState.error);
  }

  return (
    <div style={{ position: "relative", width: "100%", height: "100vh" }}>
      <Grid container className={classes.main} justify='center' alignItems='center'>
        <div className={classes.block}>
          <Grid container spacing={2} style={{ height: "100%" }}>
            <Grid item xs={12} md={6}>
              {user.requestData && (
                <p
                  className={classes.statusTag}
                  style={{
                    backgroundColor:
                      user.requestData.requestStatus === "denied" ? "#E17576" : "#FBC02D"
                  }}
                >
                  {user.requestData.requestStatus === "denied" ? "Denied" : "Pending"}
                </p>
              )}
              <img
                src='../assets/images/fpd_logo_1.svg'
                style={{ maxHeight: "9%" }}
                alt='FPD Logo'
              />
              <p style={{ fontSize: "4vh" }}>FPD ADMIN PORTAL</p>
              <p
                style={{ fontSize: "3vh" }}
                data-testid='welcome'
              >{`Welcome ${user.displayName}!`}</p>
              <p style={{ fontSize: "2vh" }} data-testid='bumperPageMessage'>
                {bumperPageMessage}
              </p>
              {!user.requestData && (
                <Button className={classes.requestButton} onClick={handleRequestAdminAcess}>
                  Request Admin Access
                </Button>
              )}
              <p style={{ fontSize: "1.5vh" }}>
                Contact{" "}
                <a
                  style={{ color: "#14C1C6", textDecoration: "none" }}
                  href='mailto:techsupport@fieldsprodirect.com'
                >
                  techsupport@fieldsprodirect.com
                </a>{" "}
                for more information
              </p>
            </Grid>
            <Grid item xs={12} md={6} className={classes.right} />
          </Grid>
        </div>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  me: state.user.me,
  userState: state.user
});

const mapDispatchToProps = {
  reqeustAdminAccess
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AdminBumper);
