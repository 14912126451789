import {
  Prop,
  AddressRecord,
  AddressRecordConstraints,
  ContactRecordConstraints,
  ContactRecord,
  DateField
} from "@fpd-cloud/schemas/core";

function isEmailValidated(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function isPhoneNumberValidated(phoneNumber: string) {
  const phoneNum = phoneNumber.replace(/[^\d]/g, "");
  if (phoneNum.length > 6 && phoneNum.length < 12) {
    return true;
  }
  return false;
}

export function isDateValidated(date: Date | string) {
  if (isNaN(new Date(date).getTime())) return false;
  return true;
}

function isAddressRecordValidated(data: AddressRecord) {
  const constraints: AddressRecordConstraints = data.constraints;

  if (constraints?.addressLine1.isRequired && !data.addressLine1)
    return "Address Line is Required!";
  if (
    constraints?.addressLine1.maxLength &&
    data.addressLine1.length < constraints?.addressLine1.maxLength
  ) {
    return `Address Line should be less than ${constraints?.addressLine1.maxLength}`;
  }

  if (constraints?.addressCity.isRequired && !data.addressCity) return "Address City is Required!";
  if (
    constraints?.addressCity.maxLength &&
    data.addressCity.length < constraints?.addressCity.maxLength
  ) {
    return `Address City should be less than ${constraints?.addressCity.maxLength}`;
  }

  if (constraints?.addressState.isRequired && !data.addressState)
    return "Address State is Required!";

  if (constraints?.addressZip.isRequired && !data.addressZip) return "Zipcode is Required!";
  if (
    constraints?.addressZip.maxLength &&
    data.addressZip.length < constraints?.addressZip.maxLength
  ) {
    return `Zipcode should be less than ${constraints?.addressLine1.maxLength}`;
  }

  return null;
}

function isContactRecordValidated(data: ContactRecord) {
  const constraints: ContactRecordConstraints = data.constraints;

  if (constraints?.contactFirstName.isRequired && !data.contactFirstName)
    return "First Name is Required!";
  if (
    constraints?.contactFirstName?.maxLength &&
    data.contactFirstName.length < constraints?.contactFirstName.maxLength
  ) {
    return `First Name should be less than ${constraints?.contactFirstName.maxLength}`;
  }

  if (constraints?.contactLastName?.isRequired && !data.contactLastName)
    return "Last Name is Required!";
  if (
    constraints?.contactLastName?.maxLength &&
    data.contactLastName.length < constraints?.contactLastName?.maxLength
  ) {
    return `Last Name should be less than ${constraints?.contactLastName?.maxLength}`;
  }

  if (constraints?.primaryPhone?.phoneNumber.isRequired && !data.primaryPhone.phoneNumber)
    return "Primary phone number is Required!";
  if (data.primaryPhone.phoneNumber) {
    if (!isPhoneNumberValidated(data.primaryPhone.phoneNumber))
      return "Primary phone number is not validated";
  }
  if (
    constraints?.primaryPhone?.phoneNumber.maxLength &&
    data.primaryPhone.phoneNumber.length < constraints?.primaryPhone?.phoneNumber.maxLength
  ) {
    return `Primary phone number should be less than ${constraints?.primaryPhone.phoneNumber.maxLength}`;
  }

  if (constraints?.primaryEmail?.emailAddress.isRequired && !data.primaryEmail.emailAddress)
    return "Primary email address is Required!";
  if (data.primaryEmail.emailAddress) {
    if (!isEmailValidated(data.primaryEmail.emailAddress))
      return "Primary email address is not validated";
  }
  return null;
}

function isDateFieldValidated(data: DateField) {
  const isRequired: boolean = data.constraints?.dateValue?.isRequired;

  if (isRequired && !data.dateValue) return "Date field is required";
  if (!isDateValidated(data.dateValue)) return "Invalid date!";

  return null;
}

export const validateProsList = (propsList: Prop[]) => {
  let error = null;
  propsList.forEach((propData) => {
    if (!error) {
      switch (propData.type) {
        case "AddressRecord":
          error = isAddressRecordValidated(propData.data as AddressRecord);
          break;
        case "ContactRecord":
          error = isContactRecordValidated(propData.data as ContactRecord);
          break;
        case "DateField":
          error = isDateFieldValidated(propData.data as DateField);
          break;
        default:
          break;
      }
      if (error) return error;
    }
  });
  return error;
};
