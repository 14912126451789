import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Button,
  makeStyles,
  Theme
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { connect } from "react-redux";
import { compose } from "redux";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import Layout from "components/layout";
import DisplayEditableProps from "components/common/display-editable-prop";
import { readAllAssignments, saveNewAssignment } from "redux/actions/assignment";
import { createAssignmentRest, getEnvelopeRest, resetBlankEnvelope } from "redux/actions/envelope";
import { readAllClients } from "redux/actions/client";
import { GlobalState, ClientWithBluePrint, ClientState, EnvelopeState } from "utils/types";
import { Prop as AssignmentProp, AssignmentEnvelope, DateField } from "@fpd-cloud/schemas/core";
import { validateProsList, isDateValidated } from "utils/validation";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "#E5E5E5",
    minHeight: "calc(100vh - 60px)",
    padding: theme.spacing(5),
    width: "100%"
  },
  left: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px 0px 0px 4px",
    padding: theme.spacing(3)
  },
  right: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px"
  },
  paperContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%"
  },
  centerPaper: {
    display: "flex",
    flexDirection: "column",
    width: "240px",
    alignItems: "center",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.54)"
  },
  propsContainer: {
    width: "100%",
    padding: theme.spacing(5)
  }
}));

interface Props {
  clientState: ClientState;
  envelopeState: EnvelopeState;
  readAllAssignments: () => void;
  readAllClients: () => void;
  saveNewAssignment: (data: any) => void;
  createAssignmentRest: (data: any) => void;
  getEnvelopeRest: (data: any) => void;
  resetBlankEnvelope: () => void;
}

function AssignmentCreate(props: Props) {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();

  const [localReceivedAt, setLocalReceivedAt] = useState<Date>(new Date());
  const [selectedEnvelope, setSelectedEnvelope] = useState<AssignmentEnvelope>(null);
  const [selectedClient, setSelectedClient] = useState<ClientWithBluePrint>(null);

  const { clientList } = props.clientState;
  const { blankEnvelope } = props.envelopeState;

  const loadResources = () => {
    props.readAllAssignments();
    props.readAllClients();
  };

  useEffect(() => {
    loadResources();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (blankEnvelope) {
      const newProps = blankEnvelope.assignmentProps.map((propData) => {
        if (propData.type === "DateField" && !(propData.data as DateField).dateValue) {
          return {
            ...propData,
            data: {
              ...propData.data,
              dateValue: new Date()
            }
          };
        }
        return propData;
      });
      setSelectedEnvelope({ ...blankEnvelope, assignmentProps: newProps });
    } else setSelectedEnvelope(null);
  }, [blankEnvelope]);

  const handleUpdateAssignmentProps = (updatedProp: AssignmentProp) => {
    const newAssignmentProps = selectedEnvelope.assignmentProps;
    const sIndex = newAssignmentProps.findIndex((np) => np.path === updatedProp.path);
    newAssignmentProps.splice(sIndex, 1, updatedProp);
    setSelectedEnvelope({
      ...selectedEnvelope,
      assignmentProps: newAssignmentProps
    });
  };

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const isReceivedAt = isDateValidated(localReceivedAt);
    let error = !isReceivedAt ? "Received at is Invalid Date" : null;
    if (!error) {
      error = validateProsList(selectedEnvelope.assignmentProps);
    }
    if (error) {
      alert.error(error, { timeout: 5000 });
      return;
    }

    selectedEnvelope.assignment.receivedAt = localReceivedAt;
    props.createAssignmentRest({
      body: selectedEnvelope,
      success: () => {
        alert.success("New Assignment is successfully created", {
          timeout: 5000
        });
        props.resetBlankEnvelope();
        history.push("/assignment-list");
      }
    });
  };

  const displayProps = (propList: AssignmentProp[]) => {
    if (!selectedEnvelope) return undefined;
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          {propList
            .sort((p1, p2) => p1.position - p2.position)
            .map((pr) => (
              <DisplayEditableProps
                propData={pr}
                key={pr.path}
                handleUpdateProps={handleUpdateAssignmentProps}
              />
            ))}
        </Grid>
      </React.Fragment>
    );
  };

  if (props.envelopeState.error != null) {
    throw new Error("Error on reading blank envelope");
  }

  if (props.clientState.error != null) {
    throw new Error(props.clientState.error);
  }

  return (
    <Layout page="assignments">
      <div>
        <form onSubmit={handleSubmit}>
          <Grid container className={classes.container}>
            <Grid item xs={3} className={classes.left}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant='h5' component='h1' gutterBottom>
                  <ArrowBack
                    style={{ verticalAlign: "middle", cursor: "pointer" }}
                    onClick={() => {
                      history.push("/assignment-list");
                    }}
                  />
                  {" Create Assignment"}
                </Typography>

                <FormControl variant='outlined' style={{ margin: "16px 0" }}>
                  <InputLabel id='client-label'>Select Client</InputLabel>
                  <Select
                    native
                    fullWidth
                    variant='outlined'
                    value={selectedClient ? selectedClient.id : ""}
                    onChange={(evt) => {
                      if (evt.target.value === "") {
                        setSelectedClient(null);
                        setSelectedEnvelope(null);
                      }
                      const index = clientList.findIndex((cl) => cl.id === evt.target.value);
                      setSelectedClient(clientList[index]);
                    }}
                    labelId='client-label'
                    label='Select Client'
                    inputProps={{
                      "aria-label": "Select Client"
                    }}
                  >
                    <option value='' />
                    {clientList
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((client) => (
                        <option key={client.id} value={client.id}>
                          {client.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                {selectedClient &&
                  selectedClient.assignmentBlueprints &&
                  selectedClient.assignmentBlueprints.length > 0 && (
                    <FormControl variant='outlined' style={{ margin: "16px 0" }}>
                      <InputLabel id='assignment-label'>Assignment Type</InputLabel>
                      <Select
                        native
                        fullWidth
                        value={
                          selectedEnvelope ? selectedEnvelope.assignment.assignmentBlueprintId : ""
                        }
                        onChange={(evt) => {
                          if (evt.target.value === "") setSelectedEnvelope(null);
                          props.getEnvelopeRest({
                            id: evt.target.value
                          });
                        }}
                        labelId='assignment-label'
                        label='Assignment Type'
                        inputProps={{
                          "aria-label": "Select Assignment"
                        }}
                      >
                        <option value='' />
                        {selectedClient.assignmentBlueprints
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((assignment) => (
                            <option
                              key={assignment.id}
                              value={assignment.id}
                              data-testid='Assignment Type ID'
                            >
                              {assignment.name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  )}

                {selectedEnvelope && selectedEnvelope.assignment && (
                  <TextField
                    label='Client Assignment ID'
                    value={selectedEnvelope.assignment.externalFriendlyId}
                    margin='normal'
                    variant='outlined'
                    name='externalFriendlyId'
                    onChange={(evt) => {
                      setSelectedEnvelope({
                        ...selectedEnvelope,
                        assignment: {
                          ...selectedEnvelope.assignment,
                          externalFriendlyId: evt.target.value
                        }
                      });
                    }}
                    fullWidth
                    required
                  />
                )}

                {selectedEnvelope && selectedEnvelope.assignment && (
                  <div style={{ position: "relative", marginTop: "20px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        name='received_at'
                        disableToolbar
                        variant='inline'
                        inputVariant='outlined'
                        format='MM/dd/yyyy'
                        margin='normal'
                        label='Received At'
                        value={localReceivedAt}
                        onChange={(date) => setLocalReceivedAt(date)}
                        required
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={9} className={classes.right}>
              {!selectedEnvelope || !selectedEnvelope.assignmentProps ? (
                <div className={classes.paperContainer}>
                  <Paper elevation={0} className={classes.centerPaper}>
                    <img src='assets/images/group.png' width='120px' alt='Assignment Blank' />
                    <Typography variant='subtitle1'>
                      Please select client & assignment type to proceed
                    </Typography>
                  </Paper>
                </div>
              ) : (
                <div className={classes.propsContainer}>
                  {displayProps(selectedEnvelope.assignmentProps)}
                  {selectedEnvelope.assignmentProps.length > 0 && (
                    <Grid container justify='center' style={{ marginTop: "60px" }}>
                      <Button type='submit' color='secondary' variant='contained'>
                        Create
                      </Button>
                    </Grid>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  clientState: state.client,
  envelopeState: state.envelope
});

const mapDispatchToProps = {
  readAllAssignments,
  readAllClients,
  saveNewAssignment,
  createAssignmentRest,
  getEnvelopeRest,
  resetBlankEnvelope
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AssignmentCreate);
