import { makeStyles, Theme } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import React, { useEffect, useState } from "react";

import { GlobalState, AssignmentDetail, User } from "utils/types";
import { Prop } from "@fpd-cloud/schemas/core";
import { updateAssignmentProp } from "redux/actions/assignment";
import DisplayEditableProps from "components/common/display-editable-prop";

const useStyles = makeStyles((theme: Theme) => ({
  mt24: {
    marginBottom: theme.spacing(3)
  },
  assignmentDetails: {
    padding: theme.spacing(3),
    display: "block",
    overflowY: "auto"
  },
  assignmentDetailsFieldTitleText: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "rgba(67, 70, 75, 0.87)"
  },
  assignmentDetailsPropDataText: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#2B323A"
  }
}));

interface Props {
  assignmentEnvelope: AssignmentDetail;
  me: User;
  updateAssignmentProp: (data: any) => void;
}

function LeftPanelInsuredDetails(props: Props) {
  const classes = useStyles();

  const [propsList, setPropsList] = useState<Prop[]>([]);
  const [editingProp, setEditingProp] = useState<Prop>(null);
  const assignmentEnvelope = props.assignmentEnvelope;

  useEffect(() => {
    if (assignmentEnvelope && assignmentEnvelope.assignmentProps) {
      setPropsList([...assignmentEnvelope.assignmentProps]);
    }
  }, [assignmentEnvelope]);
  if (!assignmentEnvelope) return null;

  const handleUpdateAssignmentProps = (updatedProp: Prop) => {
    const newProps = [...propsList];
    const sIndex = newProps.findIndex((np) => np.path === updatedProp.path);
    newProps.splice(sIndex, 1, updatedProp);
    setPropsList([...newProps]);
    setEditingProp(updatedProp);
  };

  const handleSaveAssignmentDetail = (updatedProp?: Prop) => {
    props.updateAssignmentProp({
      assignmentId: assignmentEnvelope.assignment.id,
      prop: updatedProp || editingProp
    });
  };

  return (
    <div className={classes.assignmentDetails}>
      {propsList.map((assignmentProp) => (
        <DisplayEditableProps
          key={assignmentProp.id}
          propData={assignmentProp}
          handleUpdateProps={handleUpdateAssignmentProps}
          onBlur={handleSaveAssignmentDetail}
          isSmallWdith={true}
        />
      ))}
    </div>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  assignmentEnvelope: state.assignment.assignmentEnvelope,
  me: state.user.me
});

const mapDispatchToProps = {
  updateAssignmentProp
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LeftPanelInsuredDetails);
