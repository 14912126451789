import { createAction } from "redux-actions";

export const SIGN_UP_USER = "SIGN_UP_USER";
export const GET_USER = "GET_USER";
export const REQUEST_ADMIN_ACCESS = "REQUEST_ADMIN_ACCESS";
export const SAVE_PAGE_ROUTE = "SAVE_PAGE_ROUTE";
export const READ_USERS_LIST = "READ_USERS_LIST";
export const READ_USERS_REPO = "READ_USERS_REPO";
export const CREATE_USER_IDENTITY = "CREATE_USER_IDENTITY";
export const SET_INITIAL_USER = "SET_INITIAL_USER";

export const savePageRoute = createAction(SAVE_PAGE_ROUTE);
export const signUpUser = createAction(SIGN_UP_USER);
export const getUser = createAction(GET_USER);
export const reqeustAdminAccess = createAction(REQUEST_ADMIN_ACCESS);
export const readUsersList = createAction(READ_USERS_LIST);
export const readAllUsersFromRepo = createAction(READ_USERS_REPO);
export const createUserIdentity = createAction(CREATE_USER_IDENTITY);
export const setInitialUser = createAction(SET_INITIAL_USER);
