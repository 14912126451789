import axios from "axios";
import { call, put } from "redux-saga/effects";
import { get } from "lodash";
import { apiError, apiRequest, apiSuccess } from "./makeRequest";
import firebase from "utils/firebase";

const createHeader = async () => {
  const token = await firebase.auth().currentUser.getIdToken(true);
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    Authorization: `Bearer ${token}`
  };

  axios.defaults.baseURL = process.env.REACT_APP_FUNCTIONS_ROOT_URL + "/";

  return headers;
};

export default ({
  type,
  method, // one of 'get', 'post', 'put', 'delete'
  path,
  success = null,
  fail = null,
  payloadOnSuccess = null,
  payloadOnFail = null
}) =>
  function* (action) {
    const rheaders = yield createHeader();
    const { body, params, success: successCallback, fail: failCallback } = action.payload || {};

    try {
      yield put({
        type: apiRequest(type)
      });

      const res = yield call(axios.request, {
        url: typeof path === "function" ? path(action) : path,
        method: method.toLowerCase(),
        headers: rheaders,
        data: body,
        params
      });

      if (successCallback) successCallback(res);
      if (success) success(res, action);

      yield put({
        type: apiSuccess(type),
        payload: payloadOnSuccess ? payloadOnSuccess(res, action) : res
      });
    } catch (err) {
      const errRes = get(err, "response", err);

      if (failCallback) failCallback(errRes);
      if (fail) fail(errRes);

      yield put({
        type: apiError(type),
        payload: payloadOnFail ? payloadOnFail(errRes, action) : errRes
      });
    }
  };
