import { handleActions } from "redux-actions";
import {
  READ_SUPERVISORS,
  READ_TEAM_LIST,
  READ_ALL_TEAM_LIST,
  READ_TEAM_MEMBERS
} from "../actions/team";
import { apiSuccess, apiError } from "../makeRequest";
import { TeamState } from "../../utils/types";

const initialState: TeamState = {
  teamListResponse: null,
  teamMemberList: [],
  supervisorList: [],
  memberList: [],
  status: "INIT",
  error: null
};

export default handleActions<TeamState, any>(
  {
    [apiSuccess(READ_SUPERVISORS)]: (state, { payload }) => {
      return {
        ...state,
        supervisorList: payload
      };
    },
    [apiSuccess(READ_TEAM_LIST)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(READ_TEAM_LIST),
        teamMemberList: payload
      };
    },
    [apiError(READ_TEAM_LIST)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(READ_TEAM_LIST),
        error: payload
      };
    },
    [apiSuccess(READ_ALL_TEAM_LIST)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(READ_ALL_TEAM_LIST),
        teamListResponse: payload
      };
    },
    [apiError(READ_ALL_TEAM_LIST)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(READ_ALL_TEAM_LIST),
        error: payload
      };
    },
    [apiSuccess(READ_TEAM_MEMBERS)]: (state, { payload }) => ({
      ...state,
      status: apiSuccess(READ_TEAM_MEMBERS),
      memberList: payload
    }),
    [apiError(READ_TEAM_MEMBERS)]: (state, { payload }) => ({
      ...state,
      status: apiError(READ_TEAM_MEMBERS),
      error: payload
    })
  },
  initialState
);
