import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import moment from "moment";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
  Grid,
  Typography,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  MenuList,
  MenuItem,
  makeStyles,
  Theme,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { connect } from "react-redux";
import { compose } from "redux";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

import Layout from "../../../components/layout";
import {
  readAllAssignments,
  updateAssignmentsStatus,
  readAllTasks
} from "../../../redux/actions/assignment";

import { readSupervisorList } from "../../../redux/actions/team";
import { readAllClients } from "../../../redux/actions/client";
import {
  GlobalState,
  Order,
  AssignmentState,
  ClientState,
  FilterType,
  TeamState,
  UserState
} from "utils/types";

import { AssignmentStatus } from "@fpd-cloud/schemas/core";
import TablePaginationComponent from "../../../components/common/table-pagination";
import { Request } from "domain/repos/AssignmentRepo/AssignmentList";
import FilterContainer from "./filter-container";
import FilterSelect from "./filter-select";
import SupervisorList from "./supervisor-list";
import { capitalizeFirstLetter, convertFirebaseDateToNormalDate, covertRouteToFilterType } from "utils/helper";
import { FILTER_TYPES } from "utils/costant";
// import theme from "theme";

const statusFilterValues: AssignmentStatus[] = ["open", "reopened", "closed", "cancelled"];

const useStyles = makeStyles((theme: Theme) => ({
  topBar: {
    minHeight: "110px",
    background: "#ffffff",
    padding: theme.spacing(3, 8)
  },
  tableWrapper: {
    backgroundColor: "#E5E5E5",
    padding: theme.spacing(2, 8, 4, 8)
  },
  tableContainer: {
    maxHeight: 600,
    "& th": {
      color: "#01788B",
      fontWeight: "bold"
    }
  },
  searchInput: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    height: "40px",
    backgroundImage: "url('../../../assets/images/search-icon.svg')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "15px 12px",
    padding: "10px 40px",
    borderRadius: "4px",
    marginRight: "20px",
    minWidth: 295
  },
  yellowBtn: {
    backgroundColor: theme.palette.info.main,
    padding: "10px 20px",
    color: "white",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.palette.info.light
    }
  },
  dateOrder: {
    color: "#01788B !important",
    "& svg": {
      color: "#01788B !important"
    }
  },
  capitalize: {
    textTransform: "capitalize"
  },
  statusMenu: {
    backgroundColor: "#FFF",
    position: "absolute",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
    zIndex: 500,
    borderRadius: "4px",
    width: "100%",
    "& div": {
      cursor: "pointer",
      padding: 10,
      borderBottom: "1px solid #E5E5E5"
    }
  },
  filterSelect: {
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    display: "flex",
    padding: "10px"
  },
  addFilter: {
    border: "1px dotted black",
    background: "white"
  },
  userViewButtons: {
    display: "flex",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 8)
  }
}));

interface Props {
  clientState: ClientState;
  assignmentState: AssignmentState;
  teamState: TeamState;
  userState: UserState;
  readAllAssignments: (request: Request) => void;
  readAllClients: () => void;
  readAllTasks: () => void;
  readSupervisorList: () => void;
  updateAssignmentsStatus: (data: any) => void;
}

function AssignmentListPage(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const { location } = history;
  const alert = useAlert();
  const { assignmentState, clientState, teamState, userState } = props;
  const clientStatus = clientState.status;
  const { supervisorList } = teamState;

  const [selectedIds, setSelectIds] = useState<{ id: string; assignmentBlueprintId: string }[]>([]);
  const [sortDateOrderBy, setSortDateOrderBy] = useState<Order>("");
  const [allBluePrints, setAllBluePrints] = useState<FilterType[]>([]);
  const [assignmentBlueprintFilter, setAssignmentBlueprintFilter] = useState<FilterType | null>(
    null
  );
  const [clientFilter, setClientFilter] = useState<FilterType | null>(null);
  const [statusFilter, setStatusFilter] = useState<FilterType | null>(null);
  const [supervisorFilter, setSupervisorFilter] = useState<FilterType | null>(null);
  // ---- TODO -----
  // TaskStatus will be implemetned after we have Repo Filter for it
  // const [taskStatuses, setTaskStatuses] = useState<FilterType | null>(null);
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [showFilterBar, setShowFilterBar] = useState(false);
  const [selectedFilterName, setSelectedFilterName] = useState<string | null>(null);
  const [showSupervisorMenu, setShowSupervisorMenu] = React.useState(false);
    // pulling out the role from the permissions array. This is temporary while other permissions still exist in the system
  const userRole = userState.me.permissions.find(permission => permission.includes("role"))

  const [request, setRequest] = useState<Request>({
      page: 1,
      limit: 10,
      role: userRole,
      withUserId: userState.me.id
    });

  useEffect(() => {
    if (clientStatus !== "READ_CLIENTS_SUCCESS") props.readAllClients();
    if (assignmentState.status !== "READ_ALL_TASKS_SUCCESS") props.readAllTasks();
    if (teamState.status !== "READ_SUPERVISORS_SUCCESS") props.readSupervisorList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userRole) {

      props.readAllAssignments(request);
    }
    // eslint-disable-next-line
  }, [request]);

  useEffect(() => {
    if (clientStatus === "READ_CLIENTS_SUCCESS" && supervisorList.length > 0) {
      const clientId = covertRouteToFilterType(location.search, "Client Name") as string;
      const assignmentBlueprintId = covertRouteToFilterType(
        location.search,
        "Assignment Type"
      ) as string;
      const status = covertRouteToFilterType(
        location.search,
        "Assignment Status"
      ) as AssignmentStatus;
      const supervisorId = covertRouteToFilterType(location.search, "Supervisor Name") as string;

      if (clientId) {
        const clientFromUrl = clientState.clientList.find((c) => c.id === clientId);
        setClientFilter({ id: clientId, name: clientFromUrl.name });
        setAllBluePrints(
          clientFromUrl.assignmentBlueprints.map((b) => ({ id: b.id, name: b.name }))
        );
        if (assignmentBlueprintId) {
          const blueprintFromUrl = clientFromUrl.assignmentBlueprints.find(
            (b) => b.id === assignmentBlueprintId
          );
          if (blueprintFromUrl)
            setAssignmentBlueprintFilter({
              id: assignmentBlueprintId,
              name: blueprintFromUrl.name
            });
        }
      }
      if (status) {
        setStatusFilter({ id: status, name: status });
      }
      if (supervisorId) {
        const supervisor = supervisorList.find((m) => m.id === supervisorId);
        if (supervisor) setSupervisorFilter({ id: supervisorId, name: supervisor.userName });
      }
      setRequest({
        ...request,
        withClientIdEq: clientId,
        withAssignmentBlueprintIdEq: assignmentBlueprintId,
        withAssignmentStatusEq: status
      });
    }
    // eslint-disable-next-line
  }, [location, clientStatus, supervisorList]);

  const getFilterNameList = () => {
    if (FILTER_TYPES[selectedFilterName] === "client")
      return clientState.clientList.map((client) => ({ id: client.id, name: client.name }));
    else if (FILTER_TYPES[selectedFilterName] === "type") return allBluePrints;
    else if (FILTER_TYPES[selectedFilterName] === "assignment_status")
      return statusFilterValues.map((status) => ({ id: status, name: status }));
    else if (FILTER_TYPES[selectedFilterName] === "supervisor") {
      return supervisorList.map((t) => ({ id: t.id, name: t.userName }));
    }
  };

  const getSelectedlist = () => {
    if (FILTER_TYPES[selectedFilterName] === "client") return clientFilter;
    else if (FILTER_TYPES[selectedFilterName] === "type") return assignmentBlueprintFilter;
    else if (FILTER_TYPES[selectedFilterName] === "assignment_status") return statusFilter;
    else if (FILTER_TYPES[selectedFilterName] === "supervisor") return supervisorFilter;
  };

  const handleUpdateUrl = (newFilter: FilterType | null, type: string) => {
    let newUrl = "/assignment-list";
    const nClientFilter = type === "Client Name" ? newFilter : clientFilter;
    const nBlueprintFilter =
      type === "Assignment Type" || (type === "Client Name" && newFilter === null)
        ? newFilter
        : assignmentBlueprintFilter;
    const nStatusFilter = type === "Assignment Status" ? newFilter : statusFilter;
    const nSupervisorFilter = type === "Supervisor Name" ? newFilter : supervisorFilter;

    if (nClientFilter) newUrl += `?client=${nClientFilter.id}`;
    if (nBlueprintFilter)
      newUrl += (newUrl.indexOf("?") > -1 ? "&" : "?") + `type=${nBlueprintFilter.id}`;
    if (nStatusFilter)
      newUrl += (newUrl.indexOf("?") > -1 ? "&" : "?") + `assignment_status=${nStatusFilter.id}`;
    if (nSupervisorFilter)
      newUrl += (newUrl.indexOf("?") > -1 ? "&" : "?") + `supervisor=${nSupervisorFilter.id}`;
    history.push(newUrl);
  };
  const handleUpdatedList = () => {
    if (FILTER_TYPES[selectedFilterName] === "client")
      return (sFilter: FilterType | null) => {
        setClientFilter(sFilter);
        if (sFilter) {
          const clientFromFilter = clientState.clientList.find(
            (client) => client.id === sFilter.id
          );
          setAllBluePrints([
            ...clientFromFilter.assignmentBlueprints.map((blueprint) => ({
              id: blueprint.id,
              name: blueprint.name
            }))
          ]);
        }
        setRequest({
          ...request,
          withClientIdEq: sFilter.id
        });
        handleUpdateUrl(sFilter, "Client Name");
      };
    else if (FILTER_TYPES[selectedFilterName] === "type")
      return (sFilter: FilterType | null) => {
        setAssignmentBlueprintFilter(sFilter);
        setRequest({
          ...request,
          withAssignmentBlueprintIdEq: sFilter.id
        });
        handleUpdateUrl(sFilter, "Assignment Type");
      };
    else if (FILTER_TYPES[selectedFilterName] === "assignment_status")
      return (sFilter: FilterType | null) => {
        setStatusFilter(sFilter);
        setRequest({
          ...request,
          withAssignmentStatusEq: sFilter.id as AssignmentStatus
        });
        handleUpdateUrl(sFilter, "Assignment Status");
      };
    else if (FILTER_TYPES[selectedFilterName] === "supervisor")
      return (sFilter: FilterType | null) => {
        setSupervisorFilter(sFilter);
        handleUpdateUrl(sFilter, "Supervisor Name");
        setRequest({
          ...request,
          withSupervisorIdEq: sFilter.id
        });
      };
  };

  const handleSelectCheckBox = (
    evt: React.MouseEvent<unknown>,
    data: { id: string; assignmentBlueprintId: string }
  ) => {
    evt.stopPropagation();
    const selectedIndex = selectedIds.findIndex((s) => s.id === data.id);
    let newSelected: { id: string; assignmentBlueprintId: string }[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedIds, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelected = newSelected.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }

    setSelectIds(newSelected);
  };

  const handleRowClick = (evt: React.MouseEvent<unknown>, id: string) => {
    history.push(`/assignment-detail/${id}`);
  };

  const handleUpdateStatus = (status: string) => {
    props.updateAssignmentsStatus({
      selectedIds: selectedIds.map((s) => s.id),
      status: status.toLowerCase(),
      successCB: () => {
        alert.success("Assignment Status is successfully updated", {
          timeout: 5000
        });
        setShowStatusMenu(false);
      }
    });
  };

  const isSelected = (id: string) => selectedIds.findIndex((s) => s.id === id) !== -1;
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = assignmentState.assignmentResponse.assignments.map((n) => ({
        id: n.id,
        assignmentBlueprintId: n.assignmentBlueprintId
      }));
      setSelectIds(newSelecteds);
      return;
    }
    setSelectIds([]);
  };

  if (props.assignmentState.error != null) {
    throw new Error(props.assignmentState.error);
  }
  if (props.clientState.error != null) {
    throw new Error(props.clientState.error);
  }
  if (props.teamState.error != null) {
    throw new Error(props.teamState.error);
  }

  const renderTable = () => {
    return (
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell padding='checkbox'>
              <Checkbox
                checked={
                  assignmentState &&
                  assignmentState.assignmentResponse &&
                  assignmentState.assignmentResponse.assignments.length > 0 &&
                  assignmentState.assignmentResponse.assignments.length === selectedIds.length
                }
                onChange={handleSelectAllClick}
                color='primary'
              />
            </TableCell>
            <TableCell>FPD Assignment Id</TableCell>
            <TableCell>Client Assignment ID</TableCell>
            <TableCell>Client Name</TableCell>
            <TableCell>Assignment Type</TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.dateOrder}
                direction={sortDateOrderBy || "asc"}
                active={sortDateOrderBy !== ""}
                onClick={() => {
                  if (sortDateOrderBy === "asc") setSortDateOrderBy("desc");
                  else setSortDateOrderBy("asc");
                  setRequest({
                    ...request,
                    orderBy: sortDateOrderBy === "asc" ? "-receivedAt" : "+receivedAt"
                  });
                }}
              >
                Received Date
              </TableSortLabel>
            </TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Supervisor Name</TableCell>
            <TableCell>Last Activity Completed</TableCell>
            <TableCell>Next Activity</TableCell>
          </TableRow>
        </TableHead>
        {!assignmentState || !assignmentState.assignmentResponse ?
          !userRole ?
          <TableBody style={{ backgroundColor: "white" }}>
          <TableRow>
            <TableCell colSpan={10} data-testid='no-assignment' style={{ textAlign: "center" }}>
              You do not have permission to view this content. If you feel this is in error, please contact an Administrator.
            </TableCell>
          </TableRow>
            </TableBody> :
            (
          <TableBody style={{ backgroundColor: "white" }}>
            <TableRow>
              <TableCell colSpan={10} style={{ textAlign: "center" }}>
                <CircularProgress color='primary' />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : assignmentState.assignmentResponse.assignments.length === 0 ? (
          <TableBody style={{ backgroundColor: "white" }}>
            <TableRow>
              <TableCell colSpan={10} data-testid='no-assignment' style={{ textAlign: "center" }}>
                No assignments found
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody style={{ backgroundColor: "white" }}>
                {assignmentState.assignmentResponse.assignments.map((assignment) => {
              const isItemSelected = isSelected(assignment.id);
              let receivedAt = assignment.receivedAt as any;
              if (receivedAt && receivedAt.seconds)
                receivedAt = (receivedAt as firebase.firestore.Timestamp).toDate();
              let activityCompletedAt = assignment.lastActivityCompleted ? assignment.lastActivityCompleted.completedAt as any : null;
              if (activityCompletedAt && activityCompletedAt.seconds)
                activityCompletedAt = (activityCompletedAt as firebase.firestore.Timestamp).toDate();
              const activityDueDate = assignment.nextActivity ? convertFirebaseDateToNormalDate(assignment.nextActivity.dueDate): null;
              return (
                <TableRow
                  hover
                  key={assignment.id}
                  onClick={(evt) => handleRowClick(evt, assignment.id)}
                  data-testid='assignment-row'
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      onClick={(evt) =>
                        handleSelectCheckBox(evt, {
                          id: assignment.id,
                          assignmentBlueprintId: assignment.assignmentBlueprintId
                        })
                      }
                      checked={isItemSelected}
                      color='primary'
                    />
                  </TableCell>
                  <TableCell>{assignment.internalFriendlyId}</TableCell>
                  <TableCell>{assignment.externalFriendlyId}</TableCell>
                  <TableCell>{assignment.clientName}</TableCell>
                  <TableCell>{assignment.assignmentBlueprintName}</TableCell>
                  <TableCell>{receivedAt ? moment.utc(receivedAt).format("ll") : ""}</TableCell>
                  <TableCell className={classes.capitalize}>
                    {assignment.assignmentStatus}
                  </TableCell>
                  <TableCell>{assignment.supervisorName}</TableCell>
                  <TableCell>
                    {assignment.lastActivityCompleted &&
                      <div key={assignment.lastActivityCompleted.activityId} style={{ marginBottom: "10px"}}>
                        {assignment.lastActivityCompleted.activityName}: <br /> {activityCompletedAt ? moment.utc(activityCompletedAt).format("ll") : ""}
                      </div>}
                  </TableCell>
                  <TableCell>
                    {assignment.nextActivity &&
                      (assignment.nextActivity.activityType === "Task" ?
                      <div key={assignment.nextActivity.activityId} style={{ marginBottom: "10px"}}>
                        {assignment.nextActivity.activityName}
                        <br /> {assignment.nextActivity.assignedToUserName ? assignment.nextActivity.assignedToUserName : "unassigned"}:
                        <br /> {activityDueDate ? moment.utc(activityDueDate).format("ll") : ""}
                      </div> :
                      <div key={assignment.nextActivity.activityId} style={{ marginBottom: "10px"}}>
                        {assignment.nextActivity.activityName}:
                        <br /> {assignment.nextActivity.assignedToUserName ? assignment.nextActivity.assignedToUserName : "unassigned"}:
                        <br /> {activityDueDate ? moment.utc(activityDueDate).format("ll") : ""}
                      </div>)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    );
  };

  const updateStatusContianer = () => {
    return (
      <Grid container justify='flex-end' style={{ marginBottom: 8 }}>
        <div style={{ position: "relative" }}>
          <Button
            size='small'
            variant='contained'
            className={classes.yellowBtn}
            style={{ borderRadius: "20px", padding: "8px 15px" }}
            onClick={() => {
              setShowStatusMenu(!showStatusMenu);
            }}
          >
            Change Status to
            <ArrowDropDown />
          </Button>
          {showStatusMenu && (
            <ClickAwayListener onClickAway={() => setShowStatusMenu(false)}>
              <MenuList className={classes.statusMenu}>
                {statusFilterValues.map(
                  (status) =>
                    status !== "open" && (
                      <MenuItem key={status} onClick={() => handleUpdateStatus(status)}>
                        {capitalizeFirstLetter(status)}
                      </MenuItem>
                    )
                )}
              </MenuList>
            </ClickAwayListener>
          )}
        </div>
        <div style={{ position: "relative", marginLeft: "20px" }}>
          <Button
            size='small'
            variant='contained'
            className={classes.yellowBtn}
            style={{ borderRadius: "20px", padding: "8px 15px" }}
            onClick={() => {
              if (new Set(selectedIds.map((s) => s.assignmentBlueprintId)).size > 1) {
                alert.info("Please select the same assignment type", {
                  timeout: 5000
                });
              } else {
                setShowSupervisorMenu(true);
              }
            }}
          >
            Assign Supervisor
            <ArrowDropDown />
          </Button>
          {showSupervisorMenu && (
            <SupervisorList
              assignmentIds={selectedIds.map((s) => s.id)}
              assignmentBlueprintId={selectedIds[0].assignmentBlueprintId}
              handleClose={() => setShowSupervisorMenu(false)}
              className={classes.statusMenu}
            />
          )}
        </div>
      </Grid>
    );
  };

  const handleCloseFilterSelect = () => {
    setShowFilterBar(!showFilterBar);
    setSelectedFilterName(null);
  };

  const handleClickAway = () => {
    setShowFilterBar(false);
    setSelectedFilterName(null);
  };

  const handleDeleteFilters = (filterName) => {
    if (filterName === "Client Name") {
      setClientFilter(null);
      setAllBluePrints([]);
      handleUpdateUrl(null, "Client Name");
      if (assignmentBlueprintFilter) {
        setAssignmentBlueprintFilter(null);
        setRequest({
          ...request,
          withAssignmentBlueprintIdEq: undefined,
          withClientIdEq: undefined
        });
      } else {
        setRequest({
          ...request,
          withClientIdEq: undefined
        });
      }
    } else if (filterName === "Assignment Type") {
      setAssignmentBlueprintFilter(null);
      handleUpdateUrl(null, "Assignment Type");
      setRequest({
        ...request,
        withAssignmentBlueprintIdEq: undefined
      });
    } else if (filterName === "Assignment Status") {
      setStatusFilter(null);
      handleUpdateUrl(null, "Assignment Status");
      setRequest({
        ...request,
        withAssignmentStatusEq: undefined
      });
    } else if (filterName === "Supervisor Name") {
      setSupervisorFilter(null);
      handleUpdateUrl(null, "Supervisor Name");
      setRequest({
        ...request,
        withSupervisorIdEq: undefined
      });
    }
  };

  const renderStatusChip = (filter: FilterType | null, label: string) => {
    if (filter) {
      return (
        <Chip
          label={`${label}: "${filter.name}"`}
          style={{ marginLeft: "5px", marginBottom: "5px" }}
          onDelete={() => handleDeleteFilters(label)}
          onClick={(e) => e.stopPropagation()}
        />
      );
    }
    return null;
  };

  if (props.assignmentState.error != null) {
    throw new Error(props.assignmentState.error);
  }

  return (
    <Layout page="assignments">
      <div className={classes.topBar}>
        <Grid
          container
          justify='space-between'
          direction='row'
          alignItems='center'
          style={{ marginBottom: "20px" }}
        >
          <Grid item>
            <Typography variant='h5'>Assignments</Typography>
          </Grid>
          <Grid item>
            <input
              placeholder='Search for assignments by Client Id'
              className={classes.searchInput}
              onChange={(evt) => {
                setRequest({
                  ...request,
                  withExternalFriendlyIdPrefix: `${evt.target.value as string}`
                });
              }}
            />
            <Button
              variant='contained'
              className={classes.yellowBtn}
              onClick={() => history.push("/create-assignment")}
            >
              Create Assignment
            </Button>
          </Grid>
        </Grid>
        <Grid container style={{ width: "100%", position: "relative", display: "flex"  }}>
          <div className={classes.filterSelect}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                {renderStatusChip(clientFilter, "Client Name")}
                {renderStatusChip(assignmentBlueprintFilter, "Assignment Type")}
                {renderStatusChip(statusFilter, "Assignment Status")}
                {renderStatusChip(supervisorFilter, "Supervisor Name")}
                <div style={{ position: "relative", flex: "1" }} onClick={handleCloseFilterSelect}>
                  <Chip
                    label='+ Add Filter'
                    className={classes.addFilter}
                    variant='outlined'
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowFilterBar(!showFilterBar);
                      setSelectedFilterName(null);
                    }}
                  />
                  {showFilterBar && !selectedFilterName && (
                    <FilterSelect
                      addFilterSelect={
                        !!clientFilter && !!assignmentBlueprintFilter && !!statusFilter
                      }
                      assignmentTypeSelectable={allBluePrints.length !== 0}
                      setSelectedFilterName={setSelectedFilterName}
                    />
                  )}
                  {showFilterBar && selectedFilterName && (
                    <FilterContainer
                      addFilterSelect={
                        !!clientFilter && !!assignmentBlueprintFilter && !!statusFilter
                      }
                      title={selectedFilterName}
                      allSelectableList={getFilterNameList()}
                      selectedFilter={getSelectedlist()}
                      setSelectList={handleUpdatedList()}
                      onClose={handleClickAway}
                    />
                  )}
                </div>
              </div>
            </ClickAwayListener>
          </div>
        </Grid>
      </div>
      <div className={classes.userViewButtons}>
        {userRole === 'admin-app:role-supervisor' ?
          <RadioGroup defaultValue={userRole} style={{ flexDirection: "row" }} onChange={(e) => {
            setRequest({ ...request, role: e.target.value })
          }}>
            <FormControlLabel
              value="admin-app:role-adjuster"
              control={<Radio color="primary" />}
              label="Assigned To Me"
              labelPlacement="bottom"
              />
            <FormControlLabel
              value="admin-app:role-supervisor"
              control={<Radio color="primary" />}
              label="Supervisor"
              labelPlacement="bottom"/>
            <FormControlLabel
              value="admin-app:role-admin"
              control={<Radio color="primary" />}
              label="View All"
              labelPlacement="bottom"/>

          </RadioGroup>
          : ''}
      </div>
      <div className={classes.tableWrapper}>
        {selectedIds.length > 0 && updateStatusContianer()}
        <TableContainer className={classes.tableContainer}>{renderTable()}</TableContainer>
          {assignmentState &&
          assignmentState.assignmentResponse &&
          assignmentState.assignmentResponse.assignments.length > 0 && (
            <TablePaginationComponent
              response={assignmentState.assignmentResponse}
              setRequest={setRequest}
            />
          )}
      </div>
    </Layout>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  clientState: state.client,
  assignmentState: state.assignment,
  teamState: state.team,
  userState: state.user
});

const mapDispatchToProps = {
  readAllAssignments,
  readAllClients,
  updateAssignmentsStatus,
  readAllTasks,
  readSupervisorList
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AssignmentListPage);
