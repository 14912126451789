import firebase from "firebase";
import moment from "moment";
import { FilterType } from "./types";
import { FILTER_TYPES } from "./costant";
import * as QueryString from "query-string";

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function camelize(str: string) {
  return str.replace(/\W+(.)/g, (match: string, chr: string) => {
    return chr.toUpperCase();
  });
}

export function convertFilterListToString(name: string, filters: FilterType[]) {
  let str = name;
  filters.forEach((filter, index) => {
    if (filter.status) str += `"${filter.name}: ${filter.status}"`;
    else str += `"${filter.name}"`;
    if (index !== filters.length - 1) str += ", ";
  });
  return str;
}

export function covertRouteToFilterType(location: string, filterType: string) {
  const params = QueryString.parse(location);
  return params[FILTER_TYPES[filterType]];
}

export function convertFirebaseDateToNormalDate(date: any) {
  if (!date) return moment();
  else if (date.seconds) return moment((date as firebase.firestore.Timestamp).toDate());
  return moment(date);
}

export function getEnvironmentValue() {
  return process.env.REACT_APP_RELEASE_STAGE || "local";
}
