import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#01B1C3",
      dark: "#1F373F"
    },
    secondary: {
      main: "#066F88"
    },
    info: {
      main: "#FFA022",
      light: "#ffc77c"
    },
    error: {
      main: "#FF4C4D"
    },
    background: {
      default: "#FFF"
    }
  }
});

export default theme;
