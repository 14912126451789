import { TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import React from "react";

import { TextField as TextFieldProp, Prop, TextFieldConstraints } from "@fpd-cloud/schemas/core";
import { userPermissionSelector } from "redux/selectors/userSelector";

interface Props {
  propData: Prop;
  handleAssignmentProp: (data: Prop) => void;
  onBlur?: () => void;
}
export default function TextPropComponent(props: Props) {
  const permissions = useSelector(userPermissionSelector);
  const data: TextFieldProp = props.propData.data as TextFieldProp;
  const constraints: TextFieldConstraints = data.constraints;
  const mutableIf = constraints?.textValue.mutableIf;
  let readOnly = false;
  if (mutableIf) {
    const pIndex = permissions.findIndex((permission) => {
      return permission === mutableIf;
    });
    readOnly = pIndex === -1;
  }

  return (
    <TextField
      label={data.textLabel}
      multiline={data.isMultiline}
      rows={data.isMultiline ? 2 : 1}
      variant='outlined'
      fullWidth
      margin='normal'
      required={constraints?.textValue.isRequired}
      value={data.textValue || ""}
      onChange={(evt) => {
        data.textValue = evt.target.value;
        props.handleAssignmentProp({
          ...props.propData,
          data
        });
      }}
      onBlur={() => {
        if (props.onBlur) props.onBlur();
      }}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        readOnly
      }}
      inputProps={{ "aria-label": "Text Prop", maxLength: `${constraints?.textValue.maxLength}` }}
    />
  );
}
