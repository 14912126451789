import React, { useState } from "react";
import { Theme, makeStyles, Tabs, Tab } from "@material-ui/core";

import Documents from "components/common/client-details/documents";
import Snapshot from "components/common/client-details/snapshot/index";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    backgroundColor: "white",
    padding: theme.spacing(3, 6)
  },
  tabPanel: {
    paddingTop: theme.spacing(2)
  }
}));

export default function RightPanel() {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState("Snapshot");
  const renderTabContent = (value: string) => {
    if (value === "Documents") return <Documents />;
    else if (value === "Snapshot") return <Snapshot />;
  };
  return (
    <div className={classes.container}>
      <Tabs value={tabValue} onChange={(e, value) => setTabValue(value)} textColor='secondary'>
        <Tab label='Snapshot' value='Snapshot' />
        <Tab label='Documents' value='Documents' />
      </Tabs>

      <div className={classes.tabPanel}>{renderTabContent(tabValue)}</div>
    </div>
  );
}
