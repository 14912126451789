import { put, takeLatest, select } from "redux-saga/effects";
import { AnyAction } from "redux";
import Bugsnag from "@bugsnag/js";

import {
  READ_CLIENTS_REPO,
  READ_CLIENTS,
  CREATE_CLIENT,
  GET_CLIENT,
  GET_CLIENT_FILES,
  UPDATE_CLIENT_FILE_STATUS,
  SAVE_CLIENT_FILE,
  UPDATE_CLIENT,
  SAVE_CLIENT_SNAPSHOT,
  FETCH_CLIENT_SNAPSHOT
} from "../actions/client";
import ClientRepo from "domain/repos/ClientRepo";
import ClientFileRepo from "domain/repos/ClientFileRepo";

import { FirestoreClientRepo } from "data-access/firestore/repos/FirestoreClientRepo";
import { FirestoreClientFileRepo } from "data-access/firestore/repos/FirestoreClientFileRepo";
import firebase, { db } from "utils/firebase";
import { apiSuccess, apiError } from "../makeRequest";
import { GlobalState } from "utils/types";
import ClientSnapshotRepo from "domain/repos/ClientSnapshotRepo";
import { FirestoreClientSnapshotRepo } from "data-access/firestore/repos/FirestoreClientSnapshot";

let clientRepo: ClientRepo;
clientRepo = new FirestoreClientRepo(firebase.firestore());

let clientFileRepo: ClientFileRepo;
clientFileRepo = new FirestoreClientFileRepo(firebase.firestore());
const { serverTimestamp } = firebase.firestore.FieldValue;
let snapRepo: ClientSnapshotRepo;
snapRepo = new FirestoreClientSnapshotRepo(firebase.firestore());

async function getAllPropSubCollections(
  doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
) {
  const subProps = await doc.ref.collection("props").limit(100).orderBy("position").get();
  const propList: any[] = [];
  subProps.forEach((subPropSnapshots) => {
    propList.push(subPropSnapshots.data());
  });
  return propList;
}

function* doRepoGetAllClients(action: AnyAction) {
  try {
    const response = yield clientRepo.fetchClients(action.payload);
    if (response) {
      const { clients, assignmentBlueprintsByClientId } = response;
      yield clients.map((c) => {
        return (c.assignmentBlueprints = assignmentBlueprintsByClientId[c.id]);
      });
    }
    if (response === null) {
      yield response;
    }
    yield put({
      type: apiSuccess(action.type),
      payload: response
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doGetAllClients(action: AnyAction) {
  // NOTE: I don't think this try/catch will ever really do anything.
  try {
    const recordData = yield db
      .collection("clients")
      .limit(100)
      .get()
      .then(
        (clientSnapshots) => {
          const promises: any[] = [];

          clientSnapshots.forEach((clientSnapshot) => {
            const client = db
              .collection("assignment-blueprints")
              .where("clientId", "==", clientSnapshot.id)
              .orderBy("name")
              .limit(100)
              .get()
              .then(
                (assignmentBlueprintSnapshots) => {
                  const assignmentBlueprints: any[] = [];
                  assignmentBlueprintSnapshots.forEach(async (assignmentBlueprintSnapshot) => {
                    const props = await getAllPropSubCollections(assignmentBlueprintSnapshot);
                    assignmentBlueprints.push({
                      ...assignmentBlueprintSnapshot.data(),
                      props
                    });
                  });
                  return {
                    ...clientSnapshot.data(),
                    assignmentBlueprints
                  };
                },
                (err) => {
                  console.log(err);
                }
              );

            promises.push(client);
          });

          return Promise.all(promises);
        },
        (err) => {
          console.log(err);
        }
      )
      .then(
        (values) => {
          return values;
        },
        (err) => {
          console.log(err);
        }
      );
    yield put({
      type: apiSuccess(action.type),
      payload: recordData
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doAddNewClient(action: AnyAction) {
  try {
    const { successCB, ...data } = action.payload;
    yield db
      .collection("clients")
      .doc(data.id)
      .set({
        ...data,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
      });
    yield put({
      type: apiSuccess(action.type),
      payload: action.payload
    });
    if (successCB) successCB();
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doGetClient(action: AnyAction) {
  try {
    const response = yield clientRepo.fetchClient({ id: action.payload });
    yield put({
      type: apiSuccess(action.type),
      payload: response.clientDetail
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doUpdateClient(action: AnyAction) {
  try {
    const state: GlobalState = yield select();
    const { data, successCB } = action.payload;
    yield db
      .collection("clients")
      .doc(data.id)
      .update({
        ...data,
        updatedAt: serverTimestamp(),
        updatedBy: state.user.me.id
      });
    yield put({
      type: apiSuccess(action.type),
      payload: data
    });
    if (successCB) successCB();
  } catch (e) {
    Bugsnag.notify(e);
    return {};
  }
}

function* doSaveClientSnapshot(action: AnyAction) {
  try {
    const { successCB, data } = action.payload;
    yield db.collection("snapshots").doc(data.clientId).set(data);
    if (successCB) successCB();
  } catch (e) {
    Bugsnag.notify(e);
    return {};
  }
}

function* doFetchClientSnapshot(action: AnyAction) {
  try {
    const { clientId } = action.payload;
    const snapshotDetail = yield snapRepo.fetchClientSnapshot({ clientId });
    yield put({
      type: apiSuccess(action.type),
      payload: snapshotDetail && snapshotDetail.clientSnapshot
    });
  } catch (e) {
    Bugsnag.notify(e);
    return {};
  }
}

function* doGetClientFilesByClientId(action: AnyAction) {
  try {
    const response = yield clientFileRepo.fetchClientFilesByClientId({
      clientId: action.payload.id
    });
    yield put({
      type: apiSuccess(action.type),
      payload: response ? response.clientFiles : []
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doUpdateClientFileStatus(action: AnyAction) {
  try {
    const response = yield clientFileRepo.updateClientFileStatus(
      action.payload.id,
      action.payhload.status
    );
    yield put({
      type: apiSuccess(action.type),
      payload: response
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doSaveClientFile(action: AnyAction) {
  try {
    const { successCB, clientFileDetail } = action.payload;
    yield clientFileRepo.saveClientFile(clientFileDetail);
    if (successCB) {
      successCB();
    }
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

export default function* clientSaga() {
  yield takeLatest(READ_CLIENTS_REPO, doRepoGetAllClients);
  yield takeLatest(READ_CLIENTS, doGetAllClients);
  yield takeLatest(CREATE_CLIENT, doAddNewClient);
  yield takeLatest(GET_CLIENT, doGetClient);
  yield takeLatest(GET_CLIENT_FILES, doGetClientFilesByClientId);
  yield takeLatest(UPDATE_CLIENT_FILE_STATUS, doUpdateClientFileStatus);
  yield takeLatest(SAVE_CLIENT_FILE, doSaveClientFile);
  yield takeLatest(UPDATE_CLIENT, doUpdateClient);
  yield takeLatest(SAVE_CLIENT_SNAPSHOT, doSaveClientSnapshot);
  yield takeLatest(FETCH_CLIENT_SNAPSHOT, doFetchClientSnapshot);
}
