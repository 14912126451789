import {
  Grid,
  Typography,
  makeStyles,
  Theme,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ArrowBack } from "@material-ui/icons";
import { connect } from "react-redux";
import { compose } from "redux";
import { useAlert } from "react-alert";
import React, { useState, useEffect } from "react";

import { Task, TeamMember } from "@fpd-cloud/schemas/core";
import { GlobalState, TeamDetail, AssignmentDetail } from "utils/types";
import { assignTask } from "redux/actions/assignment";
import { getTeamList } from "redux/actions/team";

const useStyles = makeStyles((theme: Theme) => ({
  assignBtn: {
    backgroundColor: theme.palette.info.main,
    padding: "8px 12px",
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.info.light
    }
  }
}));

interface Props {
  task: Task;
  assignmentEnvelope: AssignmentDetail;
  teamMemberList: TeamDetail[];
  getTeamList: (data: any) => void;
  assignTask: (data: any) => void;
  handleBack: () => void;
}

function AssignTask(props: Props) {
  const classes = useStyles();
  const alert = useAlert();
  const { teamMemberList, task, assignmentEnvelope } = props;
  const [assignedUser, setAssignedUser] = useState<any>(
    props.task.assignedToUserId
      ? {
          id: props.task.assignedToUserId,
          name: props.task.assignedToUserName
        }
      : null
  );

  useEffect(() => {
    if (props.getTeamList) {
      props.getTeamList({
        taskBlueprintIdEq: task.taskBlueprintId,
        assignmentBlueprintIdEq: assignmentEnvelope.assignment.assignmentBlueprintId
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (member) => {
    setAssignedUser(member);
  };

  const handleAssignTask = () => {
    if (!assignedUser) {
      alert.error("Please select user to assign the task", {
        timeout: 5000
      });
      props.handleBack();
    }

    props.assignTask({
      taskId: task.id,
      userId: assignedUser.id,
      userName: assignedUser.userName,
      successCB: () => {
        alert.success(`Assign Task to ${assignedUser.userName} successfully!`, {
          timeout: 5000
        });
        props.handleBack();
      }
    });
  };

  const renderTeam = (team: TeamDetail) => {
    if (!team.teamMembers) return null;
    return (
      <FormControl
        key={team.team.id}
        component='fieldset'
        style={{ margin: "30px 0", width: "100%" }}
        data-testid={`team-${team.team.id}`}
      >
        <FormLabel component='legend' style={{ margin: "20px 0" }}>
          {team.team.name}
        </FormLabel>
        {team.teamMembers.length > 15 ? (
          <Autocomplete
            options={team.teamMembers}
            getOptionLabel={(option: TeamMember) => option.userName}
            onChange={(evt, value) => handleChange(value)}
            renderInput={(params) => (
              <TextField
                required={true}
                {...params}
                label='Select Team Member'
                variant='outlined'
              />
            )}
          />
        ) : (
          <RadioGroup name='assignedUser' value={assignedUser ? assignedUser.id : ""} row>
            {team.teamMembers.map((member) => (
              <FormControlLabel
                key={member.id}
                value={member.id}
                control={
                  <Radio aria-label={`member-${member.id}`} onChange={() => handleChange(member)} />
                }
                label={
                  <div>
                    <div>{member.userName}</div>
                    <div>{member.userEmail}</div>
                  </div>
                }
                style={{ width: "30%", marginBottom: "20px" }}
              />
            ))}
          </RadioGroup>
        )}
      </FormControl>
    );
  };

  return (
    <form>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant='h5'
            component='h1'
            style={{ fontWeight: "bold", marginBottom: "1em" }}
          >
            <ArrowBack
              style={{ verticalAlign: "middle" }}
              onClick={() => {
                props.handleBack();
              }}
            />
            {" Assign Task"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label='Assign Task to'
            defaultValue={task.name}
            variant='outlined'
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>
        {teamMemberList &&
          teamMemberList.length > 0 &&
          teamMemberList.map((team) => renderTeam(team))}
        <Grid container justify='center'>
          <Button
            variant='contained'
            className={classes.assignBtn}
            onClick={handleAssignTask}
            disabled={!assignedUser}
            aria-label='Assign Task'
          >
            Assign Task
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  teamMemberList: state.team.teamMemberList,
  assignmentEnvelope: state.assignment.assignmentEnvelope
});

const mapDispatchToProps = {
  getTeamList,
  assignTask
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AssignTask);
