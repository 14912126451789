import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Select, DialogActions, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { adHocBlueprintListSelector } from "redux/selectors/taskSelector";
import { getAdhocTaskBlueprintList } from "redux/actions/task";

interface Props {
  assignmentBlueprintId: string;
  onClose: () => void;
  onSave: (data: any) => void;
}

export default function AddhocTaskModal({ assignmentBlueprintId, onClose, onSave }: Props) {
  const dispatch = useDispatch();
  const adHocList = useSelector(adHocBlueprintListSelector);
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    dispatch(getAdhocTaskBlueprintList({ assignmentBlueprintId }));
  // eslint-disable-next-line
  }, [assignmentBlueprintId]);

  useEffect(() => {
    if (adHocList && adHocList.length > 0) {
      setSelectedId(adHocList[0].id);
    }
  // eslint-disable-next-line
  }, [adHocList]);

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm">
      <DialogTitle style={{ width: '600px' }}>Add a Task</DialogTitle>
      <DialogContent dividers>
        <Select
          native
          style={{ width: '100%' }}
          value={selectedId}
          onChange={(evt) => {
            setSelectedId(evt.target.value as string);
          }}
        >
          {adHocList.map(adHocTask => (
            <option key={adHocTask.id} value={adHocTask.id}>{adHocTask.taskName}</option>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          variant='contained'
          disableElevation
          onClick={() => {
            const selectedAdhoc = adHocList.find(blueprint => blueprint.id === selectedId);
            onSave(selectedAdhoc);
          }}
        >
          {'Add'}
        </Button>
        <Button color='secondary' variant='text' disableElevation onClick={onClose}>
          {'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
