import React, { useState } from "react";
import {
  Typography,
  Grid,
  ClickAwayListener,
  makeStyles,
  Theme,
  Chip,
  Button,
  IconButton
} from "@material-ui/core";
import { PlayCircleOutline, ArrowDropDownCircleOutlined } from "@material-ui/icons";
import moment from 'moment';

import { Task } from "@fpd-cloud/schemas/core";
import { capitalizeFirstLetter, convertFirebaseDateToNormalDate } from "utils/helper";
import { statusFilterValues } from "utils/costant";
import { ExntendedTaskEnvelope } from "utils/types";
import SubtaskList from "./SubtaskList";

interface Props {
  taskEnvelope: ExntendedTaskEnvelope;
  handleUpdateTaskStatus: (id: string, status: string) => void;
  setAssignTask: (task: Task) => void;
  setDetailedTask: (task: ExntendedTaskEnvelope) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  statusMenu: {
    backgroundColor: "#FFF",
    position: "absolute",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
    zIndex: 1,
    width: "100%",
    "& div": {
      cursor: "pointer",
      padding: 10,
      borderBottom: "1px solid #E5E5E5"
    }
  },
  taskTitleContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  taskAssignedUserName: {
    marginLeft: "10px",
    backgroundColor: theme.palette.info.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.info.light
    }
  },
  reassignBtn: {
    color: theme.palette.info.main,
    marginLeft: "15px"
  }
}));

export default function TaskDetail(props: Props) {
  const classes = useStyles();
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [showSubTasks, setShowSubTasks] = useState(false);

  const taskEnvelope = props.taskEnvelope;

  const updateTaskStatus = (id: string, status: string) => {
    props.handleUpdateTaskStatus(id, status);
    setShowStatusMenu(false);
  };

  const dueDate = convertFirebaseDateToNormalDate(taskEnvelope.task.dueDate);

  const getDueDateColor = () => {
    if(dueDate.isBefore(moment()) && (taskEnvelope.task.taskStatus !== 'completed' && taskEnvelope.task.taskStatus !== 'cancelled' )) {
      return 'red';
    }
    return 'black';
  }

  return (
    <Grid container style={{ margin: "1em 0", paddingLeft: "4em" }} alignItems="center">
      <Grid item xs={8}>
        <div className={classes.taskTitleContainer}>
          <Typography
            variant='h6'
            style={{ display: "flex", alignItems: "center" }}
            color={showSubTasks ? "primary" : "secondary"}
            aria-label='Task Name'
          >
            <IconButton
              style={{ marginRight: "20px" }}
              color={showSubTasks ? "primary" : "secondary"}
              onClick={(e) => setShowSubTasks(!showSubTasks)}
              disabled={!taskEnvelope.subtaskEnvelopes}
            >
              {showSubTasks ? <ArrowDropDownCircleOutlined /> : <PlayCircleOutline />}
            </IconButton>
            <span onClick={() => props.setDetailedTask(taskEnvelope)}>
              {taskEnvelope.task.name || " "}
            </span>
          </Typography>

          {taskEnvelope.task.assignedToUserName ? (
            <>
              <Typography
                variant='subtitle2'
                style={{ color: "rgba(0,0,0,0.5)", marginLeft: "1.5em" }}
              >
                Assigned to
              </Typography>
              <Chip
                className={classes.taskAssignedUserName}
                label={taskEnvelope.task.assignedToUserName}
                aria-label='Assigned User Name'
              />
              <Button
                className={classes.reassignBtn}
                onClick={() => {
                  props.setAssignTask(taskEnvelope.task);
                }}
              >
                Reassign
              </Button>
            </>
          ) : (
            <Chip
              className={classes.taskAssignedUserName}
              onClick={() => props.setAssignTask(taskEnvelope.task)}
              label='Assign Task'
              style={{ marginLeft: "1.5em" }}
            />
          )}
        </div>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{ color: getDueDateColor() }}>
          {taskEnvelope.task.dueDate ? dueDate.format("ll") : "No Date Available"}
        </Typography>
      </Grid>
      <Grid item xs={2} style={{ position: "relative" }}>
        <Typography
          onClick={() => setShowStatusMenu(true)}
          style={{ cursor: "pointer" }}
          aria-label='Task Status'
        >
          {capitalizeFirstLetter(taskEnvelope.task.taskStatus)}
        </Typography>
        {showStatusMenu && (
          <ClickAwayListener onClickAway={() => setShowStatusMenu(false)}>
            <div className={classes.statusMenu}>
              {statusFilterValues.map((status) => (
                <div key={status} onClick={() => updateTaskStatus(taskEnvelope.task.id, status)}>
                  {capitalizeFirstLetter(status)}
                </div>
              ))}
            </div>
          </ClickAwayListener>
        )}
      </Grid>
      {showSubTasks && <SubtaskList subTasks={taskEnvelope.subtaskEnvelopes} />}
    </Grid>
  );
}
