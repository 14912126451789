import { createAction } from "redux-actions";

export const GET_TASKBLUEPRINT_PROP = "GET_TASKBLUEPRINT_PROP";
export const SAVE_TASK_PROPS = "SAVE_TASK_PROPS";
export const UPDATE_SUBTASK_STATUS = "UPDATE_SUBTASK_STATUS";
export const GET_ADHOCTASK_BLUEPRINT_LIST = "GET_ADHOCTASK_BLUEPRINT_LIST";
export const UPDATE_SUBTASK_PROPS = "UPDATE_SUBTASK_PROPS";

export const getTaskBlueprintProps = createAction(GET_TASKBLUEPRINT_PROP);
export const saveTaskProps = createAction(SAVE_TASK_PROPS);
export const updateSubtaskStatus = createAction(UPDATE_SUBTASK_STATUS);
export const updateSubTaskProps = createAction(UPDATE_SUBTASK_PROPS);
export const getAdhocTaskBlueprintList = createAction(GET_ADHOCTASK_BLUEPRINT_LIST);
