import React from "react";
import { Typography, Grid, makeStyles, Theme } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { clientDetailSelector } from "redux/selectors/clientSelector";

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    padding: theme.spacing(3),
    color: "white",
    backgroundColor: "#019EB8",
    display: "block"
  },
  detailContainer: {
    padding: theme.spacing(3)
  },
  label: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "rgba(67, 70, 75, 0.87)"
  },
  title: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#2B323A"
  },
  count: {
    fontFamily: "Raleway",
    fontWeight: "bold",
    fontSize: "144px",
    textAlign: "center",
    marginTop: theme.spacing(2),
    color: "#066F88",
    opacity: 0.5
  },
  total: {
    color: "#066F88",
    opacity: 0.65,
    fontSize: "18px",
    fontFamily: "Raleway",
    marginTop: "-30px",
    textAlign: "center"
  }
}));

export default function LeftPanel() {
  const classes = useStyles();
  const history = useHistory();
  const clientDetail = useSelector(clientDetailSelector);

  // let createdAt = ((clientDetail.createdAt as any) as firebase.firestore.Timestamp).toDate();
  const openAssignmentsCount = clientDetail.assignments.filter(
    (assignment) =>
      assignment.assignmentStatus === "open" || assignment.assignmentStatus === "reopened"
  ).length;
  const totalAssignmentCount = clientDetail.assignments.length;

  return (
    <Grid container direction='column'>
      <div className={classes.headerContainer}>
        <Typography
          variant='h6'
          component='h1'
          style={{ fontWeight: "bold", marginBottom: "1em", display: "flex", alignItems: "center" }}
        >
          <ArrowBack
            style={{ verticalAlign: "middle" }}
            onClick={() => {
              history.push("/client-list");
            }}
          />
          {"Client Details"}
        </Typography>

        <Typography style={{ fontSize: 18, marginTop: 20 }}>{clientDetail.name}</Typography>
        <Typography>{`Open Assignments - ${openAssignmentsCount}`}</Typography>
      </div>
      <Grid container className={classes.detailContainer} spacing={2}>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: "bold" }} color='secondary'>
            Client Details
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.label}>{"Date registered"}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.title}>
            {new Date(clientDetail.createdAt).toDateString()}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.label}>{"Address"}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.title}>
            {`${clientDetail.primaryAddress.addressLine1}`}
            <br />
            {`${clientDetail.primaryAddress.addressCity}, ${clientDetail.primaryAddress.addressState} - ${clientDetail.primaryAddress.addressZip}`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.label}>{"Phone"}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.title}>{clientDetail.primaryPhone}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.label}>{"Email"}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.title} style={{ color: "#019EB8" }}>
            {clientDetail.primaryEmail}
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.count}>{totalAssignmentCount}</div>
      <div className={classes.total}>Total Assignments</div>
    </Grid>
  );
}
