import { put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

import firebase, { db } from "utils/firebase";
import {
  REQUEST_ADMIN_ACCESS,
  SIGN_UP_USER,
  GET_USER,
  READ_USERS_LIST,
  READ_USERS_REPO,
  CREATE_USER_IDENTITY
} from "../actions/user";
import UserRepo from "domain/repos/UserRepo";
import { FirestoreUserRepo } from "data-access/firestore/repos/";
import { apiSuccess, apiError } from "redux/makeRequest";
import { User } from "@fpd-cloud/schemas/core";

// tslint:disable-next-line
const md5 = require("md5");

const { serverTimestamp } = firebase.firestore.FieldValue;
let repo: UserRepo;
repo = new FirestoreUserRepo(firebase.firestore());

function* doRequestAdminAccess(action: AnyAction) {
  try {
    const { id, successCB } = action.payload;

    const ref = db.doc(`admin-access-requests/${id}`);
    let snap = yield ref.get();
    if (!snap.exists) {
      yield ref.set({
        createdAt: serverTimestamp(),
        createdBy: id,
        id,
        lastRequestedAt: serverTimestamp(),
        requestStatus: "pending",
        updatedAt: serverTimestamp(),
        updatedBy: id
      });
    } else {
      yield ref.update({
        lastRequestedAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        updatedBy: id
      });
    }

    snap = yield ref.get();

    yield put({
      payload: snap.data(),
      type: apiSuccess(action.type)
    });

    if (successCB) {
      successCB();
    }
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doUserSignUp(action: AnyAction) {
  try {
    const { successCB, ...userData } = action.payload;

    // XXX: This doesn't seem like a good fix overall, but okay for now?
    //      Functions might provide a better solution to this overall. Same for "createdBy", "updatedAt"?
    userData.createdAt = serverTimestamp();
    userData.updatedAt = serverTimestamp();
    // XXX: ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    const userRef = db.doc(`users/${userData.id}`);
    yield userRef.set(userData, { merge: true });

    yield put({
      type: apiSuccess(action.type),
      payload: userData
    });
    if (successCB) successCB();
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doCreateUserIdentity(action: AnyAction) {
  try {
    const { successCB, ...userIdentityData } = action.payload;

    const fpdCloudRealm = userIdentityData.realmId;
    const hashId = md5(fpdCloudRealm.concat(userIdentityData.externalUserId));
    const userIdentity = {
      ...userIdentityData,
      id: hashId
    };
    const userIdentityRef = db.doc(`user-identities/${hashId}`);
    yield userIdentityRef.set(userIdentity, { merge: true });

    yield put({
      type: apiSuccess(action.type),
      payload: userIdentityData
    });
    if (successCB) successCB();
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doGetUser(action: AnyAction) {
  try {
    const { successCB, uid } = action.payload;
    const userRef = yield db.collection("users").doc(`${uid}`).get();
    const userData = userRef.data();
    const accessRef = yield db.doc(`admin-access-requests/${userData.id}`).get();
    yield put({
      type: apiSuccess(action.type),
      payload: {
        ...userData,
        requestData: accessRef.exists && accessRef.data()
      }
    });
    if (successCB) successCB();
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doRepoGetAllUsers(action: AnyAction) {
  try {
    const response = yield repo.fetchUsers(action.payload || {});

    if (response === null) {
      yield response;
    }
    yield put({
      type: apiSuccess(action.type),
      payload: response
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doReadUsersList(action: AnyAction) {
  try {
    // TODO (Daniel): Need to use Repo
    const userSnapshots = yield db
      .collection("users")
      .where("email", ">=", action.payload.name)
      .where("email", "<", action.payload.name + "\uf8ff")
      .orderBy("email", "asc")
      .limit(100)
      .get();
    const userList: User[] = [];
    userSnapshots.forEach((snapShot) => {
      userList.push(snapShot.data());
    });
    yield put({
      type: apiSuccess(action.type),
      payload: userList
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

export default function* userSaga() {
  yield takeLatest(GET_USER, doGetUser);
  yield takeLatest(SIGN_UP_USER, doUserSignUp);
  yield takeLatest(REQUEST_ADMIN_ACCESS, doRequestAdminAccess);
  yield takeLatest(READ_USERS_LIST, doReadUsersList);
  yield takeLatest(READ_USERS_REPO, doRepoGetAllUsers);
  yield takeLatest(CREATE_USER_IDENTITY, doCreateUserIdentity);
}
