import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  Theme,
  IconButton,
  Paper,
  Divider,
  Menu,
  MenuItem
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { connect } from "react-redux";
import { compose } from "redux";
import { useAlert } from "react-alert";

import { Prop } from "@fpd-cloud/schemas/core";
import { GlobalState, ExntendedTaskEnvelope, AssignmentDetail } from "utils/types";
import { saveTaskProps } from "redux/actions/task";
import { updateTaskStatus } from "redux/actions/assignment";
import DisplayEditableProps from "components/common/display-editable-prop";
import { statusFilterValues } from "utils/costant";
import { capitalizeFirstLetter } from "utils/helper";

const useStyles = makeStyles((theme: Theme) => ({
  component: {
    width: "50vw",
    padding: "10px",
    height: "100vh"
  },
  taskStatus: {
    border: "1px solid rgba(0,0,0,0.87)",
    borderRadius: "4px",
    padding: theme.spacing(0.5),
    cursor: "pointer"
  },
  taskDetailContainer: {
    overflowY: "auto",
    backgroundColor: "white",
    maxHeight: "700px",
    padding: "16px"
  },
  label: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "rgba(67, 70, 75, 0.87)"
  },
  value: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#2B323A"
  },
  mb24: {
    marginBottom: theme.spacing(3)
  },
  saveBtn: {
    backgroundColor: theme.palette.info.main,
    padding: "6px 20px",
    color: "white",
    width: "120px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.palette.info.light
    }
  },
  header: {
    padding: "16px"
  }
}));

interface Props {
  taskEnvelope: ExntendedTaskEnvelope;
  assignmentEnvelope: AssignmentDetail;
  userId: string;
  handleClose: () => void;
  saveTaskProps: (data: any) => void;
  updateTaskStatus: (data: any) => void;
}

function TaskDetailSidebar(props: Props) {
  const classes = useStyles();
  const alert = useAlert();
  const taskEnvelope = props.taskEnvelope;

  const [propsList, setPropsList] = useState<Prop[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLSpanElement) | null>(null);

  useEffect(() => {
    if (taskEnvelope.taskProps) {
      setPropsList([...taskEnvelope.taskProps]);
    }
    // eslint-disable-next-line
  }, [taskEnvelope]);

  const handleUpdateTaskProps = (updatedProp: Prop) => {
    // if (taskEnvelope.task.assignedToUserId === props.userId) {
    const newProps = [...propsList];
    const sIndex = newProps.findIndex((np) => np.path === updatedProp.path);
    newProps.splice(sIndex, 1, updatedProp);
    setPropsList([...newProps]);
    // }
  };

  const handleSaveTaskProps = () => {
    if (taskEnvelope.task.assignedToUserId === props.userId) {
      props.saveTaskProps({
        props: [...propsList],
        taskId: taskEnvelope.task.id,
        assignmentId: taskEnvelope.task.assignmentId
      });
    }
  };

  const renderTaskProps = () => {
    if (propsList && propsList.length === 0) return null;

    return (
      <React.Fragment>
        <Grid container spacing={2} className={classes.taskDetailContainer}>
          {propsList.map((pr) => (
            <DisplayEditableProps
              key={pr.path}
              propData={pr}
              handleUpdateProps={handleUpdateTaskProps}
              onBlur={handleSaveTaskProps}
            />
          ))}
        </Grid>
      </React.Fragment>
    );
  };

  const updateAssigmentStatus = (status: string) => {
    props.updateTaskStatus({
      id: taskEnvelope.task.id,
      status,
      successCB: () => {
        alert.success("Task Status is successfully updated", { timeout: 5000 });
        setAnchorEl(null);
        props.handleClose();
      }
    });
  };

  return (
    <Paper className={classes.component}>
      <Grid container justify='space-between' alignItems='center'>
        <Typography className={classes.taskStatus} onClick={(e) => setAnchorEl(e.currentTarget)}>
          {taskEnvelope.task.taskStatus}
        </Typography>
        <IconButton onClick={props.handleClose}>
          <Close />
        </IconButton>
      </Grid>
      <Divider />

      <div style={{ position: "relative" }}>
        <Grid container className={classes.header}>
          <Grid item sm={12}>
            <Typography
              variant='h5'
              color='primary'
              style={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              {taskEnvelope.task.name}
            </Typography>
          </Grid>
          <Grid item sm={12} className={classes.mb24}>
            <Typography style={{ fontSize: "18px", marginBottom: "8px" }}>
              {taskEnvelope.client.name}
            </Typography>
          </Grid>
          <Grid item sm={4} className={classes.label}>
            Client Id
          </Grid>
          <Grid item sm={8} className={classes.value}>
            <Typography style={{ marginBottom: "10px" }}>
              {props.assignmentEnvelope.assignment.externalFriendlyId}
            </Typography>
          </Grid>
          <Grid item sm={4} className={classes.label}>
            Assignee
          </Grid>
          <Grid item sm={8} className={classes.value} style={{ marginBottom: "24px" }}>
            {taskEnvelope.task.assignedToUserName}
          </Grid>
        </Grid>
        <div>{renderTaskProps()}</div>
      </div>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        {statusFilterValues.map((status) => (
          <MenuItem
            key={status}
            onClick={() => {
              updateAssigmentStatus(status);
            }}
          >
            {capitalizeFirstLetter(status)}
          </MenuItem>
        ))}
      </Menu>
    </Paper>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  assignmentEnvelope: state.assignment.assignmentEnvelope,
  userId: state.user.me.id
});

const mapDispatchToProps = {
  saveTaskProps,
  updateTaskStatus
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TaskDetailSidebar);
