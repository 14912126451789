import { useHistory } from "react-router-dom";
import { Grid, Typography, makeStyles, Theme, ClickAwayListener } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { connect } from "react-redux";
import { compose } from "redux";
import React, { useState } from "react";

import { GlobalState, AssignmentDetail } from "utils/types";
import { capitalizeFirstLetter } from "utils/helper";
import { assignmentStatus } from "utils/costant";
import { updateAssignmentStatus } from "redux/actions/assignment";

const useStyles = makeStyles((theme: Theme) => ({
  assignmentHeader: {
    padding: theme.spacing(3),
    color: "white",
    backgroundColor: "#019EB8",
    display: "block"
  },
  assignmentTitleDataText: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF"
  },
  assignmentDetailsTitleText: {
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#2B323A"
  },
  assignmentStatusAndClientText: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#FFFFFF"
  },
  statusMenu: {
    backgroundColor: "#FFF",
    position: "absolute",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
    zIndex: 1,
    width: "200px",
    "& div": {
      cursor: "pointer",
      padding: 10,
      borderBottom: "1px solid #E5E5E5",
      color: "black"
    }
  }
}));

interface Props {
  assignmentEnvelope: AssignmentDetail;
  updateAssignmentStatus: (data: { assignmentId: string; status: string }) => void;
}

function LeftPanel(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const [showStatusMenu, setShowStatusMenu] = useState(false);

  const assignmentEnvelope = props.assignmentEnvelope;
  if (!assignmentEnvelope) return null;

  const updateAssigmentStatus = (status: string) => {
    props.updateAssignmentStatus({
      assignmentId: assignmentEnvelope.assignment.id,
      status
    });
    setShowStatusMenu(false);
  };

  return (
    <div>
      {/* LeftPanelHeader */}
      <Grid container className={classes.assignmentHeader}>
        <Typography
          variant='h5'
          component='h1'
          style={{ fontWeight: "bold", marginBottom: "1em" }}
          aria-label='Internal Friendly Id'
        >
          <ArrowBack
            style={{ verticalAlign: "middle" }}
            onClick={() => {
              history.push("/assignment-list");
            }}
          />
          {assignmentEnvelope.assignment.internalFriendlyId}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography className={classes.assignmentStatusAndClientText}>
              {"Client Name"}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography className={classes.assignmentTitleDataText} aria-label='Client Name'>
              {assignmentEnvelope.assignment.clientName}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.assignmentStatusAndClientText}>{"Client ID"}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              className={classes.assignmentTitleDataText}
              aria-label='External Friendly Id'
            >
              {assignmentEnvelope.assignment.externalFriendlyId}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography className={classes.assignmentStatusAndClientText}>{"Status"}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              className={classes.assignmentTitleDataText}
              aria-label='Assignment Status'
              onClick={() => setShowStatusMenu(true)}
              style={{ cursor: "pointer" }}
            >
              {capitalizeFirstLetter(assignmentEnvelope.assignment.assignmentStatus)}
            </Typography>
            {showStatusMenu && (
              <ClickAwayListener onClickAway={() => setShowStatusMenu(false)}>
                <div className={classes.statusMenu}>
                  {assignmentStatus.map((status) => (
                    <div key={status} onClick={() => updateAssigmentStatus(status)}>
                      {capitalizeFirstLetter(status)}
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  assignmentEnvelope: state.assignment.assignmentEnvelope
});

const mapDispatchToProps = {
  updateAssignmentStatus
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LeftPanel);
