import { handleActions } from "redux-actions";

import {
  SIGN_UP_USER,
  GET_USER,
  REQUEST_ADMIN_ACCESS,
  SAVE_PAGE_ROUTE,
  READ_USERS_LIST,
  READ_USERS_REPO,
  CREATE_USER_IDENTITY
} from "../actions/user";
import { apiSuccess, apiError } from "../makeRequest";
import { UserState } from "utils/types";

const getInitialState = (): UserState => {
  const user = localStorage.getItem("persist:user");
  return {
    me: user ? JSON.parse(JSON.parse(user).user).me : null,
    users: [],
    page: "assignment-list",
    status: "INIT",
    error: null
  };
};

export default handleActions<UserState, any>(
  {
    [apiSuccess(GET_USER)]: (state, { payload }) => {
      return {
        ...state,
        me: payload
      };
    },
    [apiSuccess(SIGN_UP_USER)]: (state, { payload }) => {
      return {
        ...state,
        me: payload
      };
    },
    [apiError(SIGN_UP_USER)]: (state, { payload }) => {
      return {
        ...state,
        me: null,
        error: payload
      };
    },
    [apiSuccess(CREATE_USER_IDENTITY)]: (state, { payload }) => {
      return {
        ...state,
        me: payload
      };
    },
    [apiError(CREATE_USER_IDENTITY)]: (state, { payload }) => {
      return {
        ...state,
        me: null,
        error: payload
      };
    },
    [apiSuccess(REQUEST_ADMIN_ACCESS)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(REQUEST_ADMIN_ACCESS),
        me: {
          ...state.me,
          requestData: payload
        }
      };
    },
    [apiError(REQUEST_ADMIN_ACCESS)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(REQUEST_ADMIN_ACCESS),
        error: payload
      };
    },
    [apiSuccess(READ_USERS_LIST)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(READ_USERS_LIST),
        users: payload
      };
    },
    [apiError(READ_USERS_LIST)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(READ_USERS_LIST),
        error: payload
      };
    },
    [apiSuccess(READ_USERS_REPO)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(READ_USERS_REPO),
        users: payload
      };
    },
    [apiError(READ_USERS_REPO)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(READ_USERS_REPO),
        error: payload
      };
    },
    [SAVE_PAGE_ROUTE]: (state, { payload }) => ({
      ...state,
      page: payload
    })
  },
  getInitialState()
);
