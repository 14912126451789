import { createAction } from "redux-actions";

export const READ_ASSIGNMENTS = "READ_ASSIGNMENTS";
export const SAVE_NEW_ASSIGNMENT = "SAVE_NEW_ASSIGNMENT";
export const UPDATE_ASSIGNMENTS_STATUS = "UPDATE_ASSIGNMENTS_STATUS";
export const UPDATE_ASSIGNMENT_STATUS = "UPDATE_ASSIGNMENT_STATUS";
export const READ_ASSIGNMENT = "READ_ASSIGNMENT";
export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";
export const UPDATE_ASSIGNMENT_PROP = "UPDATE_ASSIGNMENT_PROP";
export const ASSIGN_TASK_TO_USER = "ASSIGN_TASK_TO_USER";
export const READ_ALL_TASKS = "READ_ALL_TASKS";
export const READ_ASSINGABLE_SUPERVISORS = "READ_ASSINGABLE_SUPERVISORS";
export const ASSIGN_SUPERVISOR = "ASSIGN_SUPERVISOR";
export const READ_SUBTASK_LIST = "READ_SUBTASK_LIST";

export const readAllAssignments = createAction(READ_ASSIGNMENTS);
export const saveNewAssignment = createAction(SAVE_NEW_ASSIGNMENT);
export const updateAssignmentsStatus = createAction(UPDATE_ASSIGNMENTS_STATUS);
export const updateAssignmentStatus = createAction(UPDATE_ASSIGNMENT_STATUS);
export const readAssignment = createAction(READ_ASSIGNMENT);
export const updateTaskStatus = createAction(UPDATE_TASK_STATUS);
export const updateAssignmentProp = createAction(UPDATE_ASSIGNMENT_PROP);
export const assignTask = createAction(ASSIGN_TASK_TO_USER);
export const readAllTasks = createAction(READ_ALL_TASKS);
export const readAssignableSupervisors = createAction(READ_ASSINGABLE_SUPERVISORS);
export const assignSupervisor = createAction(ASSIGN_SUPERVISOR);
export const readSubtaskList = createAction(READ_SUBTASK_LIST);
