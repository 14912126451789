import firebase from "firebase";
import { AssignmentBlueprint } from "@fpd-cloud/schemas/core";

const { fromDate } = firebase.firestore.Timestamp;

export const assignmentBlueprintConverter = {
  toFirestore(ab: AssignmentBlueprint): firebase.firestore.DocumentData {
    const data: firebase.firestore.DocumentData = { ...ab };
    if (ab.createdAt) data.createdAt = fromDate(new Date(ab.createdAt));
    if (ab.updatedAt) data.updatedAt = fromDate(new Date(ab.updatedAt));
    return data;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): AssignmentBlueprint {
    const data = snapshot.data(options) as AssignmentBlueprint;
    return { ...data }; // TODO(levi): this probably doesn't handle Timestamp to Date conversions well -- write tests
  }
};
