import firebase from "firebase";

import * as FieldProList from "domain/repos/FieldProRepo/FieldProList";
import FieldProRepo from "domain/repos/FieldProRepo";
import { fieldProConverter } from "../converters";
// import { FieldPro } from "@fpd-cloud/schemas/core";

export class FirestoreFieldProRepo implements FieldProRepo {
  constructor(private fdb: firebase.firestore.Firestore) {}

  async fetchFieldPros(request: FieldProList.Request): Promise<FieldProList.Response> {
    const fieldProSnapshots = await this.fdb
      .collection("field-pros")
      .limit(500)
      .withConverter(fieldProConverter)
      .get();
    // fetch FieldPros
    if (fieldProSnapshots.empty) {
      return null;
    }
    const fieldPros = fieldProSnapshots.docs.map((doc) => doc.data());
    return {
      fieldPros
    };
  }
}
