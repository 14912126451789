import React from "react";
import firebase from "firebase/app";
import moment from "moment";
import { GlobalState, AssignmentDetail } from "utils/types";
import { Grid, Typography, makeStyles, Theme } from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
  mt24: {
    marginBottom: theme.spacing(3)
  },
  assignmentDetails: {
    padding: theme.spacing(3),
    display: "block"
  },
  assignmentDetailsFieldTitleText: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "rgba(67, 70, 75, 0.87)"
  },
  assignmentDetailsPropDataText: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#2B323A"
  }
}));

interface Props {
  assignmentEnvelope: AssignmentDetail;
}

function LeftPanelAssignmentDetails(props: Props) {
  const classes = useStyles();
  const assignmentEnvelope = props.assignmentEnvelope;
  if (!assignmentEnvelope) return null;

  let receivedAt = assignmentEnvelope.assignment.receivedAt as any;
  if (receivedAt && receivedAt.seconds)
    receivedAt = (receivedAt as firebase.firestore.Timestamp).toDate();

  return (
    <div>
      <Grid container className={classes.assignmentDetails}>
        <Typography color='secondary' style={{ fontWeight: "bold" }} className={classes.mt24}>
          Assignment Details
        </Typography>
        <Grid container className={classes.mt24}>
          <Grid item xs={4}>
            <Typography className={classes.assignmentDetailsFieldTitleText}>{"Type"}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              className={classes.assignmentDetailsPropDataText}
              aria-label='Assignment Blueprint Name'
            >
              {assignmentEnvelope.assignment.assignmentBlueprintName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.mt24}>
          <Grid item xs={4}>
            <Typography className={classes.assignmentDetailsFieldTitleText}>
              {"Date Received"}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              className={classes.assignmentDetailsPropDataText}
              aria-label='Received Date'
            >
              {
                receivedAt ? moment.utc(receivedAt).format("ll") : ""
                // moment.utc(new Date(receivedAt.seconds * 1000)).format("ll")
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  assignmentEnvelope: state.assignment.assignmentEnvelope
});

export default compose(connect(mapStateToProps, null))(LeftPanelAssignmentDetails);
