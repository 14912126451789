import { combineReducers } from "redux";

import user from "./user";
import assignment from "./assignment";
import client from "./client";
import team from "./team";
import task from "./task";
import envelope from "./envelope";
import fieldPro from "./fieldPro";

export default combineReducers({
  user,
  assignment,
  client,
  team,
  task,
  envelope,
  fieldPro
});
