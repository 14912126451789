import { Typography, Grid } from "@material-ui/core";
import React from "react";

import { Prop, Header } from "@fpd-cloud/schemas/core";

interface Props {
  propData: Prop;
}
export default function HeadingPropComponent({ propData }: Props) {
  const data: Header = propData.data as Header;
  return (
    <Grid item xs={12}>
      <Typography color='secondary' variant='h6' style={{ paddingTop: 10 }}>
        {data.headerLabel}
      </Typography>
    </Grid>
  );
}
