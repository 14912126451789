import { put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

import { READ_FIELD_PRO_REPO } from "../actions/fieldPro";
import FieldProRepo from "domain/repos/FieldProRepo";
import { FirestoreFieldProRepo } from "data-access/firestore/repos/FirestoreFieldProRepo";
import firebase from "utils/firebase";
import { apiSuccess, apiError } from "../makeRequest";

let repo: FieldProRepo;
repo = new FirestoreFieldProRepo(firebase.firestore());

function* doRepoGetAllFieldPros(action: AnyAction) {
  try {
    const response = yield repo.fetchFieldPros(action.payload || {});

    if (response === null) {
      yield response;
    }
    yield put({
      type: apiSuccess(action.type),
      payload: response
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

export default function* fieldProSaga() {
  yield takeLatest(READ_FIELD_PRO_REPO, doRepoGetAllFieldPros);
}
