import firebase from "firebase";

import * as TaskBlueprintList from "../../../domain/repos/TaskBlueprintRepo/TaskBlueprintList";
import TaskBlueprintRepo from "../../../domain/repos/TaskBlueprintRepo";
import { Prop } from "@fpd-cloud/schemas/core";

export class FirestoreTaskBlueprintRepo implements TaskBlueprintRepo {
  constructor(private db: firebase.firestore.Firestore) {}
  async fetchTaskBlueprintProps(
    request: TaskBlueprintList.Request
  ): Promise<TaskBlueprintList.Response> {
    const query = this.db
      .collection("task-blueprints")
      .doc(request.taskBlueprintIdEq)
      .collection("props")
      .orderBy("position")
      .limit(100);

    const propSnapshots = await query.get();
    const taskBlueprintProps = propSnapshots.docs.map((doc) => {
      return doc.data() as Prop;
    });

    return {
      taskBlueprintProps
    };
  }

  // TODO(Levi): taskBlueprintExists is in TaskBlueprintRepo but not in FirestoreTaskBlueprintRepo
  taskBlueprintExists = async (id: string): Promise<boolean> => {
    return null;
  };
}
