import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Button, makeStyles, IconButton, Divider } from "@material-ui/core";

import FileUpload from "./file-upload";
import { getClientFilesByClientId } from "redux/actions/client";
import { clientFilesSelector } from "redux/selectors/clientSelector";
import { convertFirebaseDateToNormalDate } from "utils/helper";
import { GetApp } from "@material-ui/icons";
import { ClientFile } from "@fpd-cloud/schemas/core";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: "none"
  },
  fileWrapper: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Raleway",
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#F9F9F9"
    },
    "&:hover $buttonWrapper": {
      display: "flex"
    }
  },
  fileName: {
    fontWeight: 500,
    color: "#2B323A",
    lineHeight: 2
  },
  fileInform: {
    color: "rgba(43, 50, 58, 0.54)",
    fontSize: 16
  },
  category: {
    marginRight: theme.spacing(2),
    color: "#1E3C3F",
    fontSize: 18
  }
}));

export default function Documents() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isNewDocument, setNewDocument] = useState(false);
  const clientFiles = useSelector(clientFilesSelector);

  const fetchUploadedDocuments = () => {
    dispatch(
      getClientFilesByClientId({
        id
      })
    );
  };

  useEffect(() => {
    if (id && !isNewDocument) {
      fetchUploadedDocuments();
    }
    // eslint-disable-next-line
  }, [id, isNewDocument]);

  const downLoadDocument = (file: ClientFile) => {
    window.open(file.filePath, "_blank");
  };

  const filesByCategory = useMemo(() => {
    const tempFiles: { [category: string]: ClientFile[] } = {};
    clientFiles.forEach((file) => {
      if (!tempFiles[file.documentCategory]) tempFiles[file.documentCategory] = [];
      tempFiles[file.documentCategory].push(file);
    });
    return tempFiles;
  }, [clientFiles]);

  if (clientFiles.length === 0 || isNewDocument) {
    return (
      <div style={{ padding: "70px 50px 150px 50px" }}>
        <FileUpload
          isFirstDocument={clientFiles.length === 0}
          fetchUploadedDocuments={fetchUploadedDocuments}
          finishUploadingDocument={() => setNewDocument(false)}
        />
      </div>
    );
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Grid container justify='space-between' alignItems='center'>
        <Typography>
          <Typography color='textSecondary' component='span'>
            Total Documents
          </Typography>
          <Typography
            color='primary'
            style={{ fontSize: 21, marginLeft: 8, fontWeight: "bold" }}
            component='span'
          >
            {clientFiles.length}
          </Typography>
        </Typography>
        <Button color='primary' variant='text' onClick={() => setNewDocument(true)}>
          + Upload Documents
        </Button>
      </Grid>
      <div style={{ marginTop: 20 }}>
        {Object.keys(filesByCategory).map((category) => (
          <div key={category} style={{ marginTop: 24 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.category}>{category}</Typography>
              <Divider style={{ flex: 1 }} />
            </div>
            <Grid container spacing={2}>
              {filesByCategory[category].map((clientFile) => (
                <Grid item xs={6} key={clientFile.filePath}>
                  <div className={classes.fileWrapper}>
                    {/* <Checkbox
                      checked={false}
                      name={clientFile.documentName}
                    /> */}
                    <div style={{ marginLeft: 20, width: "80%" }}>
                      <Typography className={classes.fileName}>{clientFile.fileName}</Typography>
                      <Typography className={classes.fileInform}>
                        {convertFirebaseDateToNormalDate(clientFile.createdAt).format("llll")}
                      </Typography>
                    </div>
                    <div className={classes.buttonWrapper}>
                      <IconButton
                        size='small'
                        color='secondary'
                        onClick={() => downLoadDocument(clientFile)}
                      >
                        <GetApp />
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
}
