import firebase from "firebase";

import * as TeamMemberList from "../../../domain/repos/TeamMemberRepo/TeamMemberList";
import TeamMemberRepo from "../../../domain/repos/TeamMemberRepo";
import {
  assignmentBlueprintConverter,
  teamMemberConverter,
  taskBlueprintConverter,
  teamConverter
} from "../converters";
import { TeamDetail } from "utils/types";
import { AssignmentBlueprint } from "@fpd-cloud/schemas/core";

export class FirestoreTeamMemberRepo implements TeamMemberRepo {
  constructor(private db: firebase.firestore.Firestore) {}

  async fetchSupervisorList(request: TeamMemberList.Request): Promise<TeamMemberList.Response> {
    let supervisorsTeamIds = await this.fetchSupervisorTeamIds();
    const teamMembers = [];
    let query;
    let teamMemberSnapshots;

    while (supervisorsTeamIds.length > 0) {
      const partOfTeamIds = supervisorsTeamIds.slice(0, Math.min(10, supervisorsTeamIds.length));
      supervisorsTeamIds =
        supervisorsTeamIds.length > 10
          ? supervisorsTeamIds.slice(10, supervisorsTeamIds.length)
          : [];
      query = this.db
        .collectionGroup("team-members")
        .where("teamId", "in", partOfTeamIds)
        .limit(100);

      teamMemberSnapshots = await query.withConverter(teamMemberConverter).get();
      if (!teamMemberSnapshots.empty) {
        teamMemberSnapshots.docs.forEach((doc) => {
          const tIndex = teamMembers.findIndex((tm) => tm.id === doc.data().id);
          if (tIndex === -1) {
            teamMembers.push({ ...doc.data() });
          }
        });
      }
    }

    return {
      supervisorList: teamMembers
    };
  }

  async fetchTeamList(request: TeamMemberList.Request): Promise<TeamMemberList.Response> {
    let teamId = "";
    const teamList: TeamDetail[] = [];

    // Fetch Team
    if (request.taskBlueprintIdEq)
      teamId = await this.fetchTaskBlueprint(request.taskBlueprintIdEq);

    if (teamId) {
      const teamInform = await this.fetchTeam(teamId);
      if (teamInform.teamMembers && teamInform.teamMembers.length > 0) teamList.push(teamInform);
    }

    // Fetch Supervisor Team
    if (request.assignmentBlueprintIdEq) {
      const assignmentBlueprint = await this.fetchAssignmentBlueprint(
        request.assignmentBlueprintIdEq
      );
      if (assignmentBlueprint) {
        const teamInform = await this.fetchTeam(assignmentBlueprint.supervisorsTeamId);
        if (teamInform.teamMembers && teamInform.teamMembers.length > 0) teamList.push(teamInform);
      }
    }
    return {
      teamList
    };
  }

  private async fetchTaskBlueprint(taskBlueprintId: string): Promise<string> {
    const snaps = await this.db
      .collection("task-blueprints")
      .doc(taskBlueprintId)
      .withConverter(taskBlueprintConverter)
      .get();

    return snaps.data().teamId;
  }

  private async fetchTeam(teamId: string): Promise<TeamDetail> {
    const team = await this.db.collection("teams").doc(teamId).withConverter(teamConverter).get();
    const membersRef = await team.ref
      .collection("team-members")
      .limit(500)
      .withConverter(teamMemberConverter)
      .get();
    const teamMembers = membersRef.docs.map((doc) => doc.data());
    return {
      team: team.data(),
      teamMembers
    };
  }

  private async fetchAssignmentBlueprint(
    assignmentBlueprintId: string
  ): Promise<AssignmentBlueprint> {
    const blueprint = await this.db
      .collection("assignment-blueprints")
      .doc(assignmentBlueprintId)
      .withConverter(assignmentBlueprintConverter)
      .get();

    if (!blueprint.exists) return null;
    return blueprint.data();
  }

  private async fetchSupervisorTeamIds(): Promise<string[]> {
    const snaps = await this.db
      .collection("assignment-blueprints")
      .limit(100)
      .withConverter(assignmentBlueprintConverter)
      .orderBy("name")
      .get();

    const teamIds = new Set<string>();
    snaps.docs.forEach((doc) => teamIds.add(doc.data().supervisorsTeamId));
    return Array.from(teamIds) as string[];
  }
}
