import { createAction } from "redux-actions";

export const READ_SUPERVISORS = "READ_SUPERVISORS";
export const READ_TEAM_LIST = "READ_TEAM_LIST";
export const READ_ALL_TEAM_LIST = "READ_ALL_TEAM_LIST";
export const ADD_TEAM_MEMBERS = "ADD_TEAM_MEMBERS";
export const READ_TEAM_MEMBERS = "READ_TEAM_MEMBERS";
export const REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER";

export const readSupervisorList = createAction(READ_SUPERVISORS);
export const getTeamList = createAction(READ_TEAM_LIST);
export const readAllTeamList = createAction(READ_ALL_TEAM_LIST);
export const addTeamMembers = createAction(ADD_TEAM_MEMBERS);
export const readTeamMembers = createAction(READ_TEAM_MEMBERS);
export const removeTeamMember = createAction(REMOVE_TEAM_MEMBER);
