import { handleActions } from "redux-actions";

import {
  READ_ASSIGNMENTS,
  READ_ASSIGNMENT,
  UPDATE_TASK_STATUS,
  ASSIGN_TASK_TO_USER,
  READ_ALL_TASKS,
  READ_ASSINGABLE_SUPERVISORS,
  READ_SUBTASK_LIST
} from "../actions/assignment";
import { apiSuccess, apiError } from "../makeRequest";
import { AssignmentState, ExntendedTaskEnvelope } from "utils/types";

const initialState: AssignmentState = {
  assignmentResponse: null,
  assignmentEnvelope: null,
  tasks: [],
  subTasks: [],
  supervisors: [],
  status: "INIT",
  blankEnvelope: null,
  error: null
};

export default handleActions<AssignmentState, any>(
  {
    [apiSuccess(READ_ASSIGNMENTS)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(READ_ASSIGNMENTS),
        assignmentResponse: payload
      };
    },
    [apiError(READ_ASSIGNMENTS)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(READ_ASSIGNMENTS),
        error: payload
      };
    },
    [apiSuccess(READ_ASSIGNMENT)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(READ_ASSIGNMENT),
        assignmentEnvelope: payload
      };
    },
    [apiError(READ_ASSIGNMENT)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(READ_ASSIGNMENT),
        error: payload
      };
    },
    [apiSuccess(UPDATE_TASK_STATUS)]: (state, { payload }) => {
      const taskEnvelopes: ExntendedTaskEnvelope[] = [];
      state.assignmentEnvelope.taskEnvelopes.forEach((taskEnvelope) => {
        if (taskEnvelope.task.id === payload.id) {
          taskEnvelope.task.taskStatus = payload.status;
        }
        taskEnvelopes.push({ ...taskEnvelope });
      });
      return {
        ...state,
        status: apiSuccess(UPDATE_TASK_STATUS),
        assignmentEnvelope: {
          ...state.assignmentEnvelope,
          taskEnvelopes
        }
      };
    },
    [apiError(UPDATE_TASK_STATUS)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(UPDATE_TASK_STATUS),
        error: payload
      };
    },
    [apiSuccess(ASSIGN_TASK_TO_USER)]: (state, { payload }) => {
      const taskEnvelopes = state.assignmentEnvelope.taskEnvelopes;
      const tIndex = taskEnvelopes.findIndex(
        (taskEnvelope) => taskEnvelope.task.id === payload.taskId
      );
      taskEnvelopes[tIndex].task.assignedToUserId = payload.userId;
      taskEnvelopes[tIndex].task.assignedToUserName = payload.userName;
      taskEnvelopes[tIndex].task.taskStatus = "assigned";
      return {
        ...state,
        assignmentEnvelope: {
          ...state.assignmentEnvelope,
          taskEnvelopes
        }
      };
    },
    [apiSuccess(READ_ALL_TASKS)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(READ_ALL_TASKS),
        tasks: payload
      };
    },
    [apiError(READ_ALL_TASKS)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(READ_ALL_TASKS),
        error: payload
      };
    },
    [apiSuccess(READ_ASSINGABLE_SUPERVISORS)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(READ_ASSINGABLE_SUPERVISORS),
        supervisors: payload
      };
    },
    [apiError(READ_ASSINGABLE_SUPERVISORS)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(READ_ASSINGABLE_SUPERVISORS),
        error: payload
      };
    },
    [apiSuccess(READ_SUBTASK_LIST)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(READ_SUBTASK_LIST),
        subTasks: payload
      };
    }
  },
  initialState
);
