import firebase from "firebase";
import { Client } from "@fpd-cloud/schemas/core";
const { fromDate } = firebase.firestore.Timestamp;

export const clientConverter = {
  toFirestore(c: Client): firebase.firestore.DocumentData {
    const data: firebase.firestore.DocumentData = { ...c };
    if (c.createdAt) data.createdAt = fromDate(new Date(c.createdAt));
    if (c.updatedAt) data.updatedAt = fromDate(new Date(c.updatedAt));
    return data;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Client {
    const d = snapshot.data(options);
    return {
      id: d.id,
      createdAt: d.createdAt?.toDate(),
      updatedAt: d.updatedAt?.toDate(),
      name: d.name,
      primaryAddress: d.primaryAddress,
      primaryEmail: d.primaryEmail,
      primaryPhone: d.primaryPhone
    };
  }
};
