import { handleActions } from "redux-actions";
import { READ_FIELD_PRO_REPO } from "../actions/fieldPro";
import { apiSuccess, apiError } from "../makeRequest";
import { FieldProState } from "../../utils/types";

const initialState: FieldProState = {
  fieldPros: [],
  status: "INIT",
  error: null
};

export default handleActions<FieldProState, any>(
  {
    [apiSuccess(READ_FIELD_PRO_REPO)]: (state: FieldProState, { payload }) => {
      return {
        ...state,
        status: apiSuccess(READ_FIELD_PRO_REPO),
        fieldPros: payload
      };
    },
    [apiError(READ_FIELD_PRO_REPO)]: (state: FieldProState, { payload }) => {
      return {
        ...state,
        status: apiError(READ_FIELD_PRO_REPO),
        error: payload
      };
    }
  },
  initialState
);
