import { createAction } from "redux-actions";

export const CRAETE_ASSIGNMENT_REST = "CRAETE_ASSIGNMENT_REST";
export const CREATE_ADHOC_TASK_REST = "CREATE_ADHOC_TASK_REST";
export const GET_ENVELOPE_REST = "GET_ENVELOPE_REST";
export const RESET_BLANK_ENVELOPE = "RESET_BLANK_ENVELOPE";

export const getEnvelopeRest = createAction(GET_ENVELOPE_REST);
export const createAssignmentRest = createAction(CRAETE_ASSIGNMENT_REST);
export const resetBlankEnvelope = createAction(RESET_BLANK_ENVELOPE);
export const createAdhocTaskRest = createAction(CREATE_ADHOC_TASK_REST);
