import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";

const locationHelper = locationHelperBuilder({});

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  authenticatedSelector: (state) => state.user.me !== null && state.user.me.id,
  redirectPath: "/login",
  wrapperDisplayName: "UserIsAuthenticated"
});

export const userIsAdminRedir = connectedRouterRedirect({
  redirectPath: "/admin-bumper",
  allowRedirectBack: false,
  authenticatedSelector: (state) =>
    state.user.me !== null && state.user.me.permissions.includes("admin-app:access"),
  predicate: (user) => user.permissions.includes("admin-app:access"),
  wrapperDisplayName: "UserIsAdmin"
});

export const userIsNotAdminRedir = connectedRouterRedirect({
  redirectPath: "/assignment-list",
  allowRedirectBack: false,
  authenticatedSelector: (state) =>
    state.user.me !== null && !state.user.me.permissions.includes("admin-app:access"),
  predicate: (user) => !user.permissions.includes("admin-app:access"),
  wrapperDisplayName: "UserIsNotAdmin"
});

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  authenticatedSelector: (state) => state.user.me === null || !state.user.me.id,
  redirectPath: (state: any, ownProps: any) =>
    locationHelper.getRedirectQueryParam(ownProps) || "/assignment-list",
  allowRedirectBack: false,
  wrapperDisplayName: "UserIsNotAuthenticated"
});
