import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Grid,
  Typography,
  Button,
  IconButton,
  makeStyles,
  CircularProgress,
  Theme
} from "@material-ui/core";
import { SaveAlt, CloudUpload } from "@material-ui/icons";
import { connect } from "react-redux";
import { compose } from "redux";
import { useHistory } from "react-router-dom";
import Layout from "components/layout";
import AssignmentTypesModal from "components/modals/AssignmentTypes";
import { ClientWithBluePrint, GlobalState, ClientState } from "utils/types";
import { readAllClientsFromRepo } from "redux/actions/client";
import * as ClientList from "domain/repos/ClientRepo/ClientList";

interface Props {
  clientState: ClientState;
  readAllClientsFromRepo: (request: ClientList.Request) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  topBar: {
    minHeight: "110px",
    marginBottom: theme.spacing(2),
    background: "#ffffff",
    padding: theme.spacing(3, 8)
  },
  tableContainer: {
    backgroundColor: "#E5E5E5",
    padding: theme.spacing(2, 8, 4, 8),
    "& th": {
      color: "#01788B",
      fontWeight: "bold"
    }
  },
  searchInput: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    height: "40px",
    backgroundImage: "url('../../../assets/images/search-icon.svg')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "15px 12px",
    padding: "10px 40px",
    borderRadius: "4px",
    marginRight: "20px"
  },
  filterItem: {
    marginRight: theme.spacing(3),
    cursor: "pointer"
  },
  claimBtn: {
    backgroundColor: theme.palette.info.main,
    padding: "10px 20px",
    color: "white",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.palette.info.light
    }
  },
  footer: {
    background: theme.palette.secondary.main,
    borderRadius: "0px 0px 10px 10px",
    color: "#FFFFFF"
  },
  capitalize: {
    textTransform: "capitalize"
  }
}));

function ClientListPage(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const [request, setRequest] = useState<ClientList.Request>({
    includeAssignmentBlueprints: true
  });
  const [selectedIds, setSelectIds] = useState<string[]>([]);
  const [hoveredClient, setHoveredClient] = useState<ClientWithBluePrint | null>(null);

  useEffect(() => {
    props.readAllClientsFromRepo(request);
    // eslint-disable-next-line
  }, [request]);

  const filteredClientList =
    props.clientState && props.clientState.clientResponse
      ? props.clientState.clientResponse.clients
      : [];

  const handleSelectRow = (evt: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelected = newSelected.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }

    setSelectIds(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = filteredClientList.map((n) => n.id);
      setSelectIds(newSelecteds);
      return;
    }
    setSelectIds([]);
  };

  const isSelected = (id: string) => selectedIds.indexOf(id) !== -1;

  const downLoadClient = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    client: ClientWithBluePrint
  ) => {
    evt.stopPropagation();
  };

  if (props.clientState.error != null) {
    throw new Error(props.clientState.error);
  }

  const renderTable = (clients) => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox'>
            <Checkbox
              checked={clients.length > 0 && clients.length === selectedIds.length}
              onChange={handleSelectAllClick}
              color='primary'
            />
          </TableCell>
          <TableCell>Client name</TableCell>
          <TableCell>Address</TableCell>
          <TableCell>City</TableCell>
          <TableCell>State</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      {props.clientState.status === "INIT" ? (
        <TableBody style={{ backgroundColor: "white" }}>
          <TableRow>
            <TableCell colSpan={8} style={{ textAlign: "center" }}>
              <CircularProgress color='primary' />
            </TableCell>
          </TableRow>
        </TableBody>
      ) : clients.length === 0 ? (
        <TableBody style={{ backgroundColor: "white" }}>
          <TableRow>
            <TableCell colSpan={8} style={{ textAlign: "center" }}>
              No clients found
            </TableCell>
          </TableRow>
        </TableBody>
      ) : (
        <TableBody style={{ backgroundColor: "white" }}>
          {clients.map((client: ClientWithBluePrint) => {
            const isItemSelected = isSelected(client.id);
            const downloadDisabled =
              client.assignmentBlueprints && client.assignmentBlueprints.length !== 0;
            return (
              <TableRow
                hover
                key={client.id}
                // onClick={() => setHoveredClient(client)}
                onClick={() => {
                  history.push(`/client-detail/${client.id}`);
                }}
                data-testid='client-row'
              >
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={isItemSelected}
                    onClick={(evt) => handleSelectRow(evt, client.id)}
                    color='primary'
                  />
                </TableCell>
                <TableCell>{client.name}</TableCell>
                <TableCell>
                  {!client.primaryAddress.addressLine2 ? (
                    client.primaryAddress.addressLine1
                  ) : (
                    <>
                      <span>{client.primaryAddress.addressLine1}</span>
                      <br />
                      <span>{client.primaryAddress.addressLine2}</span>
                    </>
                  )}
                </TableCell>
                <TableCell>{client.primaryAddress.addressCity}</TableCell>
                <TableCell>{client.primaryAddress.addressState}</TableCell>
                <TableCell>{client.primaryPhone}</TableCell>
                <TableCell>{client.primaryEmail}</TableCell>
                <TableCell>
                  <IconButton
                    color='primary'
                    disabled={!downloadDisabled}
                    onClick={(evt) => downLoadClient(evt, client)}
                  >
                    <SaveAlt />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      )}
    </Table>
  );

  return (
    <Layout page="clients">
      <div className={classes.topBar}>
        <Grid
          container
          justify='space-between'
          direction='row'
          alignItems='center'
          style={{ marginBottom: "20px" }}
        >
          <Grid item>
            <Typography variant='h5'>Clients</Typography>
          </Grid>
          <Grid item>
            <input
              placeholder='Search for clients here...'
              className={classes.searchInput}
              onChange={(evt) => {
                setRequest({
                  ...request,
                  withNamePrefix: `${evt.target.value as string}`
                });
              }}
            />
            <Button
              variant='contained'
              className={classes.claimBtn}
              onClick={() => history.push("/create-client")}
            >
              Add Client
            </Button>
            <Button
              variant='contained'
              color='default'
              className={classes.claimBtn}
              style={{ marginLeft: 8 }}
              startIcon={<CloudUpload />}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </div>

      <div className={classes.tableContainer}>{renderTable(filteredClientList)}</div>

      {hoveredClient && (
        <AssignmentTypesModal
          blueprints={hoveredClient.assignmentBlueprints}
          onClose={() => setHoveredClient(null)}
        />
      )}
    </Layout>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  clientState: state.client
});

const mapDispatchToProps = {
  readAllClientsFromRepo
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ClientListPage);
