import React, { useState, useEffect } from "react";
import { Typography, Grid, IconButton, Button, makeStyles, Theme } from "@material-ui/core";
import { PlayCircleOutline, ArrowDropDownCircleOutlined } from "@material-ui/icons";
import { updateSubTaskProps } from "redux/actions/task";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import moment from 'moment';

import { SubtaskEnvelope, Prop } from "@fpd-cloud/schemas/core";
import { capitalizeFirstLetter, convertFirebaseDateToNormalDate } from "utils/helper";
import DisplayEditableProps from "components/common/display-editable-prop";
import ConfirmDialog from "components/modals/ConfirmDialog";

interface Props {
  subTask: SubtaskEnvelope;
  handleUpdateTaskStatus: (status: string) => void;
  setAnchorEl: (anchor: HTMLElement | null) => void;
  setSelectedId: (id: string | null) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.info.main,
    padding: "10px 20px",
    margin: "0 10px",
    color: "white",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.palette.info.light
    }
  }
}));

export default function SubtaskDetail(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [showProps, setShowProps] = useState(false);
  const [propList, setPropList] = useState<Prop[]>([]);
  const [showDialog, setShowDialog] = useState(false);
  const { subTask } = props;

  useEffect(() => {
    if (subTask && subTask.subtaskProps && subTask.subtaskProps.length > 0) {
      setPropList([...subTask.subtaskProps]);
    }
  }, [subTask]);

  const handleUpdateProps = (updatedProp: Prop) => {
    const newPropList = [...propList];
    const pIndex = newPropList.findIndex((np) => np.id === updatedProp.id);
    newPropList.splice(pIndex, 1, updatedProp);
    setPropList([...newPropList]);
  };

  const handleSaveProps = (updateStatusFlag) => {
    dispatch(
      updateSubTaskProps({
        subtaskId: subTask.subtask.id,
        props: propList,
        updateStatusFlag,
        successCB: () => {
          alert.success("Sub task props are successfully saved!", {
            timeout: 5000
          });
        }
      })
    );
  };

  const dueDate = convertFirebaseDateToNormalDate(subTask.subtask.dueDate);

  const getDueDateColor = () => {
    if(dueDate.isBefore(moment()) && (subTask.subtask.subtaskStatus !== 'completed' && subTask.subtask.subtaskStatus !== 'cancelled' )) {
      return 'red';
    }
    return 'black';
  }

  return (
    <React.Fragment>
      <Grid container alignItems='center' style={{ margin: "4px 0", width: "100%" }}>
        {showDialog && (
          <ConfirmDialog
            content='Would you also like to update the Subtask Status to Complete?'
            confirmText='Yes'
            cancelText='No'
            onCancel={() => {
              handleSaveProps(false);
              setShowDialog(false);
            }}
            onSuccess={() => {
              handleSaveProps(true);
              setShowDialog(false);
            }}
          />
        )}
        <Grid item xs={8}>
          <Grid container alignItems='center'>
            <Typography>
              <IconButton
                style={{ marginRight: "1em" }}
                color={showProps ? "primary" : "secondary"}
                onClick={() => setShowProps(!showProps)}
                data-testid='show-props'
              >
                {showProps ? <ArrowDropDownCircleOutlined /> : <PlayCircleOutline />}
              </IconButton>
            </Typography>
            <Typography color='secondary'>{props.subTask.subtask.name}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Typography style={{ color: getDueDateColor() }}>
            {subTask.subtask.dueDate ? dueDate.format('ll') : "No Date Available"}
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ position: "relative" }}>
          <Typography
            onClick={(e) => {
              props.setAnchorEl(e.currentTarget);
              props.setSelectedId(props.subTask.subtask.id);
            }}
            style={{ cursor: "pointer" }}
            aria-label='Subtask Status'
          >
            {capitalizeFirstLetter(props.subTask.subtask.subtaskStatus || "new")}
          </Typography>
        </Grid>
      </Grid>
      <div>
        {showProps && props.subTask && props.subTask.subtaskProps.length > 0 && (
          <div data-testid='propcontainer' style={{ width: "80%", margin: "0 auto" }}>
            {propList.map((prop) => (
              <div data-testid='propwrapper' key={prop.id}>
                <DisplayEditableProps
                  propData={prop}
                  handleUpdateProps={handleUpdateProps}
                  isSmallWdith={true}
                />
              </div>
            ))}
            <div style={{ textAlign: "center" }}>
              <Button
                variant='contained'
                className={classes.button}
                onClick={() => setShowDialog(true)}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
