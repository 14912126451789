import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  card: {
    transform: "translateY(-50%)",
    textAlign: "center"
  },
  button: {
    color: "white",
    marginTop: 20
  },
  text: {
    position: 'absolute',
    top: '50%',
    width: '150%',
    background: 'white',
    padding: '25px 0',
    fontSize: '20px',
    transform: 'translate(-12%, -50%)',
    color: 'black',
    fontWeight: 'bold',
    letterSpacing: '5px'
  }
}));

export default function NotesTab() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <img src='/assets/images/notes.svg' alt='Notes' />
        <Typography className={classes.text}>
          Coming Soon
        </Typography>
        {/* <Button variant='contained' color='primary' className={classes.button}>
          Add Notes
        </Button> */}
      </div>
    </div>
  );
}
