import React from "react";
import { Divider, Grid, Typography, NativeSelect } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { Props } from "./FPDContactInformation";

export default function CliamIntake({ clientSnapshot, handleUpdateSnapshotData }: Props) {
  return (
    <>
      <Typography variant='h5' style={{ marginTop: "20px" }}>
        Claim Intake
      </Typography>
      <Divider style={{ width: "100%", margin: "0 0 30px 0" }} />
      <Typography style={{ fontWeight: "bold", width: "100%", marginLeft: 8 }}>
        FNOL Intake
      </Typography>
      <Grid item xs={6}>
        <NativeSelect
          style={{ minWidth: 200 }}
          fullWidth
          value={clientSnapshot?.fnolIntake}
          onChange={(e) => {
            handleUpdateSnapshotData("fnolIntake", e.target.value);
          }}
        >
          <option value='FPD take loss' defaultChecked>
            FPD take loss
          </option>
          <option value='Client forward loss/polic information'>
            Client forward loss/polic information
          </option>
        </NativeSelect>
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant='inline'
            inputVariant='outlined'
            format='MM/dd/yyyy'
            margin='normal'
            label='Date to Start Receiving Claims'
            value={
              clientSnapshot &&
              clientSnapshot.startReceivingClaimsDate &&
              new Date(clientSnapshot.startReceivingClaimsDate.dateValue)
            }
            onChange={(date: Date) => {
              const newDate = clientSnapshot?.startReceivingClaimsDate || {};
              handleUpdateSnapshotData("startReceivingClaimsDate", {
                ...newDate,
                dateValue: date.toString()
              });
            }}
            fullWidth
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6} />
    </>
  );
}
