import { put, takeLatest, select } from "redux-saga/effects";
import { AnyAction } from "redux";

import firebase, { db } from "../../utils/firebase";
import {
  GET_TASKBLUEPRINT_PROP,
  SAVE_TASK_PROPS,
  UPDATE_SUBTASK_STATUS,
  UPDATE_SUBTASK_PROPS,
  GET_ADHOCTASK_BLUEPRINT_LIST
} from "../actions/task";
import { readAssignment } from "../actions/assignment";
import { FirestoreTaskBlueprintRepo } from "../../data-access/firestore/repos/FirestoreTaskBlueprintRepo";
import { FirestoreAdhocTaskBlueprintRepo } from "../../data-access/firestore/repos/FirestoreAdhocTaskBlueprintRepo";
import TaskBlueprintRepo from "../../domain/repos/TaskBlueprintRepo";
import AdhocTaskBlueprintRepo from "domain/repos/AdhocTaskBlueprintRepo";
import { apiSuccess, apiError } from "redux/makeRequest";
import { GlobalState } from "utils/types";

let repo: TaskBlueprintRepo;
let adHocRepo: AdhocTaskBlueprintRepo;

repo = new FirestoreTaskBlueprintRepo(db);
adHocRepo = new FirestoreAdhocTaskBlueprintRepo(db);
const { serverTimestamp } = firebase.firestore.FieldValue;

function* doReadTaskBlueprintListSaga(action: AnyAction) {
  try {
    const response = yield repo.fetchTaskBlueprintProps({
      taskBlueprintIdEq: action.payload
    });
    yield put({
      type: apiSuccess(action.type),
      payload: response?.taskBlueprintProps || []
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

/**
 *
 * @param action payload
 * @subparam PropList props
 * @subparam taskId string
 * @subparam assignmentId string
 */
function* doSaveTaskPropsSaga(action: AnyAction) {
  try {
    const { successCB, ...data } = action.payload;
    data.props.map(async (prop) => {
      await db.collection("tasks").doc(data.taskId).collection("props").doc(prop.id).set(prop);
    });
    if (successCB) successCB();
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doUpdateSubtaskStatus(action: AnyAction) {
  try {
    const { assignmentId, subTaskId, status, successCB } = action.payload;
    const state: GlobalState = yield select();

    yield db
      .collection("subtasks")
      .doc(subTaskId)
      .update({
        subtaskStatus: status,
        updatedAt: serverTimestamp(),
        updatedBy: state.user.me.id,
        ...(status === "cancelled" && { cancelledAt: serverTimestamp() }),
        ...(status === "completed" && { completedAt: serverTimestamp() })
      });
    if (successCB) successCB();
    yield put(readAssignment(assignmentId));
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doUpdateSubtaskProps(action: AnyAction) {
  try {
    const { successCB, ...data } = action.payload;
    const state: GlobalState = yield select();

    data.props.map(async (prop) => {
      await db
        .collection("subtasks")
        .doc(data.subtaskId)
        .collection("props")
        .doc(prop.id)
        .set({
          ...prop,
          updatedAt: serverTimestamp(),
          updatedBy: state.user.me.id
        });
    });

    if (data.updateStatusFlag) {
      yield db.collection("subtasks").doc(data.subtaskId).update({
        subtaskStatus: "completed",
        completedAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        updatedBy: state.user.me.id
      });
    } else {
      yield db.collection("subtasks").doc(data.subtaskId).update({
        subtaskStatus: "started",
        updatedAt: serverTimestamp(),
        updatedBy: state.user.me.id
      });
    }
    if (state.assignment && state.assignment.assignmentEnvelope) {
      yield put(readAssignment(state.assignment.assignmentEnvelope.assignment.id));
    }

    if (successCB) successCB();
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doGetAdhocTaskBlueprint(action: AnyAction) {
  try {
    const { assignmentBlueprintId } = action.payload;
    const response = yield adHocRepo.fetchTaskBlueprintList({ assignmentBlueprintId });
    yield put({
      type: apiSuccess(action.type),
      payload: response.adhocTaskBlueprintsList
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

export default function* taskSaga() {
  yield takeLatest(GET_TASKBLUEPRINT_PROP, doReadTaskBlueprintListSaga);
  yield takeLatest(SAVE_TASK_PROPS, doSaveTaskPropsSaga);
  yield takeLatest(UPDATE_SUBTASK_STATUS, doUpdateSubtaskStatus);
  yield takeLatest(UPDATE_SUBTASK_PROPS, doUpdateSubtaskProps);
  yield takeLatest(GET_ADHOCTASK_BLUEPRINT_LIST, doGetAdhocTaskBlueprint);
}
