import { handleActions } from "redux-actions";
import { GET_TASKBLUEPRINT_PROP, GET_ADHOCTASK_BLUEPRINT_LIST } from "../actions/task";
import { apiSuccess, apiError } from "../makeRequest";
import { TaskState } from "../../utils/types";

const initialState: TaskState = {
  taskBlueprintProps: [],
  adHocLists: [],
  status: "INIT",
  error: null
};

export default handleActions<TaskState, any>(
  {
    [apiSuccess(GET_TASKBLUEPRINT_PROP)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(GET_TASKBLUEPRINT_PROP),
        taskBlueprintProps: payload
      };
    },
    [apiError(GET_TASKBLUEPRINT_PROP)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(GET_TASKBLUEPRINT_PROP),
        error: payload
      };
    },
    [apiSuccess(GET_ADHOCTASK_BLUEPRINT_LIST)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(GET_ADHOCTASK_BLUEPRINT_LIST),
        adHocLists: payload
      };
    },
    [apiError(GET_ADHOCTASK_BLUEPRINT_LIST)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(GET_ADHOCTASK_BLUEPRINT_LIST),
        error: payload
      };
    }
  },
  initialState
);
