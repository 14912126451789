import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  makeStyles,
  Theme
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { connect } from "react-redux";
import { compose } from "redux";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { v4 as uuidv4 } from "uuid";
import MyPhoneNumber from "material-ui-phone-number";
import React, { useState } from "react";

import Layout from "components/layout";
import { STATE_LIST } from "utils/costant";
import { createClient } from "redux/actions/client";

const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    maxHeight: 300
  },
  container: {
    padding: theme.spacing(3, 8)
  },
  createActionArea: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

interface Props {
  createClient: (data: any) => void;
}

function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function CreateClient(props: Props) {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();

  const [name, setName] = useState("");
  const [primaryAddress, setPrimaryAddress] = useState({
    addressZip: "",
    addressState: "",
    addressLine1: "",
    addressLine2: "",
    addressCity: ""
  });
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [primaryFax, setPrimaryFax] = useState("");

  const handlePrimaryAddressChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setPrimaryAddress({
      ...primaryAddress,
      [evt.target.name]: evt.target.value
    });
  };

  const handlePostalCodeChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let res = evt.target.value.replace(/\D/g, "");
    res = res.slice(0, 5);
    setPrimaryAddress({
      ...primaryAddress,
      [evt.target.name]: res
    });
  };

  const successCB = () => {
    alert.success("New Client is created successfully!", {
      timeout: 5000
    });
    history.push("/client-list");
  };

  const successNewCB = () => {
    alert.success("New Client is created successfully!", {
      timeout: 5000
    });
    setName("");
    setPrimaryAddress({
      addressZip: "",
      addressState: "",
      addressLine1: "",
      addressLine2: "",
      addressCity: ""
    });
    setPrimaryPhone("");
    setPrimaryEmail("");
    setPrimaryFax("");
  };

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (!validateEmail(primaryEmail)) {
      alert.error("Please input validate company email", {
        timeout: 3000
      });
      return;
    }
    if (primaryFax) {
      props.createClient({
        id: uuidv4(),
        name,
        primaryAddress,
        primaryPhone,
        primaryEmail,
        primaryFax,
        successCB
      });
    } else {
      props.createClient({
        id: uuidv4(),
        name,
        primaryAddress,
        primaryPhone,
        primaryEmail,
        successCB
      });
    }
  };

  const handleCreateAndNew = () => {
    if (
      name === "" ||
      primaryAddress.addressLine1 === "" ||
      primaryAddress.addressCity === "" ||
      primaryAddress.addressState === "" ||
      primaryAddress.addressZip === "" ||
      primaryEmail === "" ||
      !validateEmail(primaryEmail) ||
      primaryPhone === ""
    ) {
      alert.error("Please check if you enter all fields", {
        timeout: 3000
      });
      return;
    }
    if (!validateEmail(primaryEmail)) {
      alert.error("Please input validate company email", {
        timeout: 3000
      });
      return;
    }
    if (primaryFax) {
      props.createClient({
        id: uuidv4(),
        name,
        primaryAddress,
        primaryPhone,
        primaryEmail,
        primaryFax,
        successCB: successNewCB
      });
    } else {
      props.createClient({
        id: uuidv4(),
        name,
        primaryAddress,
        primaryPhone,
        primaryEmail,
        successCB: successNewCB
      });
    }
  };

  return (
    <Layout page="clients">
      <form className={classes.container} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5' component='h1' gutterBottom>
              <ArrowBack
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={() => {
                  history.push("/client-list");
                }}
              />
              {"Create New Client"}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              label='Client Name'
              variant='outlined'
              value={name}
              required
              fullWidth
              onChange={(evt) => setName(evt.target.value)}
              inputProps={{
                "aria-label": "Client Name"
              }}
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={8}>
            <TextField
              label='Street Address'
              variant='outlined'
              name='addressLine1'
              value={primaryAddress.addressLine1}
              required
              fullWidth
              onChange={handlePrimaryAddressChange}
              inputProps={{
                "aria-label": "Street Address1"
              }}
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={8}>
            <TextField
              label='Street Address(cont)'
              variant='outlined'
              name='addressLine2'
              value={primaryAddress.addressLine2}
              fullWidth
              onChange={handlePrimaryAddressChange}
              inputProps={{
                "aria-label": "Street Address2"
              }}
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <TextField
              label='City'
              variant='outlined'
              name='addressCity'
              value={primaryAddress.addressCity}
              required
              fullWidth
              onChange={handlePrimaryAddressChange}
              inputProps={{
                "aria-label": "City"
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl required fullWidth>
              <InputLabel id='state-select-label' style={{ marginLeft: 14, marginTop: -4 }}>
                State
              </InputLabel>
              <Select
                variant='outlined'
                value={primaryAddress.addressState}
                labelId='state-select-label'
                onChange={(evt) => {
                  setPrimaryAddress({
                    ...primaryAddress,
                    addressState: evt.target.value as string
                  });
                }}
                fullWidth
                label='Select State'
                inputProps={{
                  "data-testid": "Select State"
                }}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                <MenuItem value=''>{""}</MenuItem>
                {STATE_LIST.map((usState) => (
                  <MenuItem key={usState} value={usState}>
                    {usState}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label='Postal Code'
              variant='outlined'
              name='addressZip'
              value={primaryAddress.addressZip}
              onChange={handlePostalCodeChange}
              fullWidth
              required
              inputProps={{
                "aria-label": "Postal Code",
                maxLength: 5
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <MyPhoneNumber
              label='Company Phone Number'
              onlyCountries={["us"]}
              defaultCountry='us'
              disableCountryCode
              disableDropdown
              value={primaryPhone}
              onChange={setPrimaryPhone}
              fullWidth
              required
              variant='outlined'
              inputProps={{
                "aria-label": "Company Phone"
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label='Company Email'
              variant='outlined'
              value={primaryEmail}
              type='email'
              onChange={(evt) => setPrimaryEmail(evt.target.value)}
              fullWidth
              required
              inputProps={{
                "aria-label": "Company Email"
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label='Company Fax'
              variant='outlined'
              value={primaryFax}
              onChange={(evt) => setPrimaryFax(evt.target.value)}
              fullWidth
              inputProps={{
                "aria-label": "Company Fax"
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.createActionArea}>
              <Button
                type='submit'
                variant='contained'
                color='secondary'
                size='large'
                style={{ marginRight: 20 }}
                aria-label='Create Client'
              >
                Create Client
              </Button>
              <Button
                size='large'
                variant='contained'
                color='secondary'
                onClick={handleCreateAndNew}
                aria-label='Create New'
              >
                Create + New
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </Layout>
  );
}

const mapDispatchToProps = {
  createClient
};
export default compose(connect(null, mapDispatchToProps))(CreateClient);
