import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  FormGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FilterType } from "../../../utils/types";
import { capitalizeFirstLetter } from "utils/helper";

interface Props {
  title: string;
  addFilterSelect: boolean;
  allSelectableList: FilterType[];
  selectedFilter: FilterType | null;
  setSelectList: (data: FilterType | null) => void;
  onClose: () => void;
}

export default function FilterContainer(props: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tIndex = props.allSelectableList.findIndex((item) => item.id === event.target.name);
    props.setSelectList(props.allSelectableList[tIndex]);
  };

  const ifChecked = (id: string) => {
    return id === props.selectedFilter?.id;
  };

  return (
    <Card
      style={{
        position: "absolute",
        zIndex: 999,
        marginTop: props.addFilterSelect ? "10px" : "10px",
        width: "350px",
        marginLeft: "-20px"
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <CardHeader
        style={{ backgroundColor: "#066F88", height: "50px", color: "white" }}
        title={props.title}
        actions={
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent style={{ overflowY: "scroll", maxHeight: "300px" }}>
        <FormGroup>
          {props.allSelectableList.map((filter) => (
            <FormControlLabel
              key={filter.id}
              label={capitalizeFirstLetter(filter.name)}
              control={
                <Radio checked={ifChecked(filter.id)} onChange={handleChange} name={filter.id} />
              }
            />
          ))}
        </FormGroup>
      </CardContent>
    </Card>
  );
}
