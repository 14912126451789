import { TextField, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import React from "react";

import EmailRecordComponent from "./email-record";
import PhoneRecordComponent from "./phone-record";
import {
  Prop,
  ContactRecord,
  ContactRecordConstraints,
  PhoneRecord,
  EmailRecord
} from "@fpd-cloud/schemas/core";
import { userPermissionSelector } from "redux/selectors/userSelector";

interface Props {
  propData: Prop;
  handleAssignmentProp: (data: Prop) => void;
  onBlur?: () => void;
  isSmallWdith?: boolean;
}

export default function ContactRecordComponent(props: Props) {
  const { isSmallWdith } = props;
  const permissions = useSelector(userPermissionSelector);
  const getReadOnly = (mutableIf) => {
    if (!mutableIf) return false;
    let readOnly = false;
    const pIndex = permissions.findIndex((permission) => {
      return permission === firstNameMutableIf;
    });
    readOnly = pIndex === -1;
    return readOnly;
  };
  const data: ContactRecord = props.propData.data as ContactRecord;
  const constraints: ContactRecordConstraints = data.constraints;
  const firstNameMutableIf = constraints?.contactFirstName?.mutableIf;
  const lastNameMutableIf = constraints?.contactLastName?.mutableIf;
  const primaryEmailMutableIf = constraints?.primaryEmail?.mutableIf;
  const primaryPhoneMutableIf = constraints?.primaryPhone?.mutableIf;
  const secondaryEmailsMutableIf = constraints?.secondaryEmails?.mutableIf;
  const secondaryPhonesMutableIf = constraints?.secondaryPhones?.mutableIf;
  const firstNameReadOnly = getReadOnly(firstNameMutableIf);
  const lastNameReadOnly = getReadOnly(lastNameMutableIf);
  const primaryEmailReadOnly = getReadOnly(primaryEmailMutableIf);
  const primaryPhoneReadOnly = getReadOnly(primaryPhoneMutableIf);
  const secondaryEmailsReadOnly = getReadOnly(secondaryEmailsMutableIf);
  const secondaryPhonesReadOnly = getReadOnly(secondaryPhonesMutableIf);

  const handleContactProp = (cData: ContactRecord) => {
    props.handleAssignmentProp({
      ...props.propData,
      data: cData
    });
  };
  const handlePrimaryPhoneProp = (pData: PhoneRecord) => {
    if (primaryEmailReadOnly) return;
    handleContactProp({
      ...data,
      primaryPhone: pData
    });
  };
  const handlePrimaryEmailProp = (eData: EmailRecord) => {
    if (primaryPhoneReadOnly) return;
    handleContactProp({
      ...data,
      primaryEmail: eData
    });
  };
  const handleSecondaryPhoneProp = (index: number, pData: PhoneRecord) => {
    if (secondaryPhonesReadOnly) return;

    const secondaryPhones = [...data.secondaryPhones];
    secondaryPhones.splice(index, 1, pData);
    handleContactProp({
      ...data,
      secondaryPhones
    });
  };
  const handleSecondaryEmailProp = (index: number, eData: EmailRecord) => {
    if (secondaryEmailsReadOnly) return;
    const secondaryEmails = [...data.secondaryEmails];
    secondaryEmails.splice(index, 1, eData);
    handleContactProp({
      ...data,
      secondaryEmails
    });
  };

  return (
    <Grid container spacing={2} style={{ margin: 0 }}>
      <Grid item xs={12}>
        <Typography color='secondary' variant='h6'>
          {data.contactLabel}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={data.contactFirstName || ""}
          label='First Name'
          variant='outlined'
          fullWidth
          required={constraints?.contactFirstName.isRequired}
          onChange={(evt) => {
            if (firstNameReadOnly) return;
            data.contactFirstName = evt.target.value;
            handleContactProp(data);
          }}
          onBlur={() => {
            if (props.onBlur && !firstNameReadOnly) props.onBlur();
          }}
          inputProps={{
            "aria-label": "First Name",
            maxLength: `${constraints?.contactFirstName.maxLength}`
          }}
          InputProps={{
            readOnly: firstNameReadOnly
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={data.contactLastName || ""}
          label='Last Name'
          variant='outlined'
          fullWidth
          required={constraints?.contactLastName.isRequired}
          onChange={(evt) => {
            if (lastNameReadOnly) return;
            data.contactLastName = evt.target.value;
            handleContactProp(data);
          }}
          onBlur={() => {
            if (props.onBlur && !lastNameReadOnly) props.onBlur();
          }}
          inputProps={{
            "aria-label": "Last Name",
            maxLength: `${constraints?.contactLastName}`
          }}
          InputProps={{
            readOnly: lastNameReadOnly
          }}
        />
      </Grid>

      <PhoneRecordComponent
        phoneData={data.primaryPhone}
        handlePhoneProp={handlePrimaryPhoneProp}
        onBlur={props.onBlur}
        isSmallWdith={isSmallWdith}
      />
      {data.secondaryPhones.map((sp, index) => (
        <PhoneRecordComponent
          key={`phone-${index}`}
          phoneData={sp}
          handlePhoneProp={(pData: PhoneRecord) => handleSecondaryPhoneProp(index, pData)}
          onBlur={props.onBlur}
          isSmallWdith={isSmallWdith}
        />
      ))}
      <EmailRecordComponent
        emailData={data.primaryEmail}
        handleEmailProp={handlePrimaryEmailProp}
        onBlur={props.onBlur}
        isSmallWdith={isSmallWdith}
      />
      {data.secondaryEmails.map((se, index) => (
        <EmailRecordComponent
          key={`email-${index}`}
          emailData={se}
          handleEmailProp={(eData: EmailRecord) => handleSecondaryEmailProp(index, eData)}
          onBlur={props.onBlur}
          isSmallWdith={isSmallWdith}
        />
      ))}
    </Grid>
  );
}
