import React from "react";
import {
  Divider,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Props } from "./FPDContactInformation";

export default function SoftwareSystems({ clientSnapshot, handleUpdateSnapshotData }: Props) {
  const isReportTypeChecked = (type) => {
    if (
      !clientSnapshot ||
      !clientSnapshot.reportingType ||
      clientSnapshot.reportingType.length === 0
    ) {
      return false;
    }

    return clientSnapshot.reportingType.findIndex((c) => c === type) > -1;
  };

  return (
    <>
      <Typography variant='h5' style={{ marginTop: "20px" }}>
        Reporting
      </Typography>
      <Divider style={{ width: "100%", margin: "0 0 30px 0" }} />
      <FormControl component='fieldset' fullWidth style={{ marginTop: 20 }}>
        <FormLabel component='legend'>Report Type</FormLabel>
        <FormGroup row>
          {["Stewardship Report", "Bordereaux Report"].map((item) => (
            <FormControlLabel
              key={item}
              control={
                <Checkbox
                  checked={isReportTypeChecked(item)}
                  onChange={(e) => {
                    const typeList = clientSnapshot?.reportingType || [];
                    if (e.target.checked) {
                      typeList.push(item);
                    } else {
                      const index = typeList.findIndex((c) => c === item);
                      typeList.splice(index, 1);
                    }
                    handleUpdateSnapshotData("reportingType", [...typeList]);
                  }}
                />
              }
              label={item}
              style={{ minWidth: "45%" }}
            />
          ))}
        </FormGroup>
      </FormControl>
      <Typography style={{ fontWeight: "bold", width: "100%", marginLeft: 8 }}>
        Send reports to:
      </Typography>
      <TextField
        value={clientSnapshot?.sendReportsTo || ""}
        variant='outlined'
        multiline
        rows={3}
        fullWidth
        onChange={(e) => {
          handleUpdateSnapshotData("sendReportsTo", e.target.value);
        }}
      />
    </>
  );
}
