import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useAlert } from "react-alert";

import { MenuList, MenuItem, ClickAwayListener } from "@material-ui/core";
import { TeamMember } from "@fpd-cloud/schemas/core";
import { GlobalState } from "../../../utils/types";
import { readAssignableSupervisors, assignSupervisor } from "../../../redux/actions/assignment";

interface Props {
  className: string;
  assignmentBlueprintId: string;
  assignmentIds: string[];
  supervisors: TeamMember[];
  readAssignableSupervisors: (data: any) => void;
  assignSupervisor: (data: any) => void;
  handleClose: () => void;
}

function SupervisorList(props: Props) {
  const alert = useAlert();
  const { assignmentBlueprintId } = props;
  useEffect(() => {
    props.readAssignableSupervisors({ assignmentBlueprintId });
    // eslint-disable-next-line
  }, [assignmentBlueprintId]);

  if (!props.supervisors || props.supervisors.length === 0) return null;

  return (
    <ClickAwayListener onClickAway={props.handleClose}>
      <MenuList className={props.className}>
        {props.supervisors.map((supervisor) => (
          <MenuItem
            key={supervisor.id}
            onClick={() => {
              props.assignSupervisor({
                assignmentIds: props.assignmentIds,
                userId: supervisor.id,
                userName: supervisor.userName,
                successCB: () => {
                  alert.success("Assign Supervisor to assignment successfully", {
                    timeout: 5000
                  });
                  props.handleClose();
                }
              });
            }}
          >
            {supervisor.userName}
          </MenuItem>
        ))}
      </MenuList>
    </ClickAwayListener>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  supervisors: state.assignment.supervisors
});

const mapDispatchToProps = {
  readAssignableSupervisors,
  assignSupervisor
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SupervisorList);
