import React from "react";
import {
  Divider,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import { Props } from "./FPDContactInformation";
import { ProgramTypes } from "@fpd-cloud/schemas/core";

const PROGRAM_TYPES: ProgramTypes[] = [
  "Virtual Adjuster",
  "Desk Adjuster",
  "Liability Task Assignment",
  "Desk Estimate",
  "Field Adjuster",
  "Liability Full Investigation"
];

export default function AssignmentInformation({ clientSnapshot, handleUpdateSnapshotData }: Props) {
  const isProgramTypeChecked = (type) => {
    if (!clientSnapshot || !clientSnapshot.programType || clientSnapshot.programType.length === 0) {
      return false;
    }

    return clientSnapshot.programType.findIndex((c) => c === type) > -1;
  };

  return (
    <>
      <Typography variant='h5' style={{ marginTop: "20px" }}>
        Assignment Information
      </Typography>
      <Divider style={{ width: "100%", margin: "0 0 30px 0" }} />
      <FormControl component='fieldset' fullWidth style={{ marginTop: 20 }}>
        <FormLabel component='legend'>Program Type</FormLabel>
        <FormGroup row>
          {PROGRAM_TYPES.map((item) => (
            <FormControlLabel
              key={item}
              control={
                <Checkbox
                  checked={isProgramTypeChecked(item)}
                  onChange={(e) => {
                    const typeList = clientSnapshot?.programType || [];
                    if (e.target.checked) {
                      typeList.push(item);
                    } else {
                      const index = typeList.findIndex((c) => c === item);
                      typeList.splice(index, 1);
                    }
                    handleUpdateSnapshotData("programType", [...typeList]);
                  }}
                />
              }
              label={item}
              style={{ minWidth: "45%" }}
            />
          ))}
        </FormGroup>
      </FormControl>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id='adjustment-type-label'>Adjustment Type</InputLabel>
          <Select
            value={clientSnapshot?.adjustmentType || ""}
            onChange={(e) => {
              handleUpdateSnapshotData("adjustmentType", e.target.value);
            }}
            labelId='adjustment-type-label'
            inputProps={{ "aria-label": "AdjustmentType Type" }}
            style={{ marginRight: "20px" }}
            fullWidth
          >
            {PROGRAM_TYPES.map((pType) => (
              <MenuItem value={pType} key={pType}>
                {pType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id='managed-repair-label'>Managed Repair</InputLabel>
          <Select
            value={clientSnapshot?.managedRepair || ""}
            onChange={(e) => {
              handleUpdateSnapshotData("managedRepair", e.target.value);
            }}
            labelId='managed-repair-label'
            inputProps={{ "aria-label": "ManagedRepair" }}
            style={{ marginRight: "20px" }}
            fullWidth
          >
            {["Yes", "No", "N/A"].map((item) => (
              <MenuItem value={item} key={`managed-${item}`}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id='emergency-solutions-label'>Emergency Solutions</InputLabel>
          <Select
            value={clientSnapshot?.emergencySolutions || ""}
            onChange={(e) => {
              handleUpdateSnapshotData("emergencySolutions", e.target.value);
            }}
            labelId='emergency-solutions-label'
            inputProps={{ "aria-label": "Emergency Solutions" }}
            style={{ marginRight: "20px" }}
            fullWidth
          >
            {["Yes", "No", "N/A"].map((item) => (
              <MenuItem value={item} key={`emergency-${item}`}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} />
    </>
  );
}
