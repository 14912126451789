import React from "react";
import { Grid, Divider } from "@material-ui/core";

import LeftPanelHeader from "./LeftPanelHeader/LeftPanelHeader";
import LeftPanelAssignmentDetails from "./LeftPanelAssignmentDetails/LeftPanelAssignmentDetails";
import LeftPanelInsuredDetails from "./LeftPanelInsuredDetails/LeftPanelInsuredDetails";

export default function LeftPanel() {
  return (
    <Grid container direction='column'>
      <LeftPanelHeader />
      <LeftPanelAssignmentDetails />
      <Divider />
      <LeftPanelInsuredDetails />
    </Grid>
  );
}
