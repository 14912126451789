import firebase from "firebase";
import { ClientFile } from "@fpd-cloud/schemas/core";
const { fromDate } = firebase.firestore.Timestamp;

export const clientFileConverter = {
  toFirestore(c: ClientFile): firebase.firestore.DocumentData {
    const data: firebase.firestore.DocumentData = { ...c };
    if (c.createdAt) data.createdAt = fromDate(new Date(c.createdAt));
    if (c.updatedAt) data.updatedAt = fromDate(new Date(c.updatedAt));
    return data;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): ClientFile {
    const d = snapshot.data(options) as ClientFile;
    return {
      ...d,
      createdAt: d.createdAt || new Date(),
      updatedAt: d.updatedAt || new Date()
    };
  }
};
