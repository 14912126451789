import React from "react";
import {
  Divider,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { ClientSnapshot } from "@fpd-cloud/schemas/core";
import { capitalizeFirstLetter } from "utils/helper";

export interface Props {
  clientSnapshot: ClientSnapshot | null;
  handleUpdateSnapshotData: (fieldKey: string, data: any) => void;
}

const EMAIL_TYPES = ["home", "work", "other", "unknown"];

export default function FPDContactInformation({ clientSnapshot, handleUpdateSnapshotData }: Props) {
  return (
    <>
      <Typography variant='h5' style={{ marginTop: "20px" }}>
        FPD Contact Information
      </Typography>
      <Divider style={{ width: "100%", margin: "20px 0 30px 0" }} />
      <Typography style={{ fontWeight: "bold", width: "100%", marginLeft: 8 }}>
        Account Manager
      </Typography>
      <Grid item xs={12}>
        <TextField
          variant='outlined'
          label='Name'
          placeholder='Enter Account Manager Name'
          fullWidth
          value={clientSnapshot?.accountManagerName || ""}
          onChange={(e) => {
            handleUpdateSnapshotData("accountManagerName", e.target.value);
          }}
        />
      </Grid>
      <Typography style={{ fontWeight: "bold", width: "100%", marginLeft: 8 }}>
        Account Manager Email
      </Typography>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id='email-type-label'>Email Type</InputLabel>
          <Select
            value={clientSnapshot?.accountManagerEmail?.emailType || ""}
            onChange={(e) => {
              const email = clientSnapshot?.accountManagerEmail || {};
              handleUpdateSnapshotData("accountManagerEmail", {
                ...email,
                emailType: e.target.value
              });
            }}
            labelId='email-type-label'
            inputProps={{ "aria-label": "Email Type" }}
            style={{ marginRight: "20px" }}
            fullWidth
          >
            {EMAIL_TYPES.map((et) => (
              <MenuItem value={et} key={et}>
                {capitalizeFirstLetter(et)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <TextField
          value={clientSnapshot?.accountManagerEmail?.emailAddress || ""}
          label='Email Address'
          type='email'
          variant='outlined'
          required={clientSnapshot?.accountManagerEmail?.constraints?.emailAddress.isRequired}
          fullWidth
          onChange={(e) => {
            const email = clientSnapshot?.accountManagerEmail || {};
            handleUpdateSnapshotData("accountManagerEmail", {
              ...email,
              emailAddress: e.target.value
            });
          }}
          inputProps={{
            "aria-label": "Email Address"
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Typography style={{ fontWeight: "bold", width: "100%", marginLeft: 8 }}>Director</Typography>
      <Grid item xs={12}>
        <TextField
          variant='outlined'
          label='Name'
          placeholder='Enter Director Name'
          fullWidth
          value={clientSnapshot?.directorName || ""}
          onChange={(e) => {
            handleUpdateSnapshotData("directorName", e.target.value);
          }}
        />
      </Grid>
      <Typography style={{ fontWeight: "bold", width: "100%", marginLeft: 8 }}>
        Director Email
      </Typography>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id='email-type-label'>Email Type</InputLabel>
          <Select
            value={clientSnapshot?.directorEmail?.emailType || ""}
            onChange={(e) => {
              const email = clientSnapshot?.directorEmail || {};
              handleUpdateSnapshotData("directorEmail", {
                ...email,
                emailType: e.target.value
              });
            }}
            labelId='email-type-label'
            inputProps={{ "aria-label": "Email Type" }}
            style={{ marginRight: "20px" }}
            fullWidth
          >
            {EMAIL_TYPES.map((et) => (
              <MenuItem value={et} key={et}>
                {capitalizeFirstLetter(et)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <TextField
          value={clientSnapshot?.directorEmail?.emailAddress || ""}
          label='Email Address'
          type='email'
          variant='outlined'
          required={clientSnapshot?.directorEmail?.constraints?.emailAddress.isRequired}
          fullWidth
          onChange={(e) => {
            const email = clientSnapshot?.directorEmail || {};
            handleUpdateSnapshotData("directorEmail", {
              ...email,
              emailAddress: e.target.value
            });
          }}
          inputProps={{
            "aria-label": "Email Address"
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    </>
  );
}
