import firebase from "firebase";
import { SubtaskBlueprint } from "@fpd-cloud/schemas/core";
const { fromDate } = firebase.firestore.Timestamp;

export const subTaskBlueprintConverter = {
  toFirestore(ab: SubtaskBlueprint): firebase.firestore.DocumentData {
    const data: firebase.firestore.DocumentData = { ...ab };
    if (ab.createdAt) data.createdAt = fromDate(new Date(ab.createdAt));
    if (ab.updatedAt) data.updatedAt = fromDate(new Date(ab.updatedAt));
    return data;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): SubtaskBlueprint {
    const data = snapshot.data(options) as SubtaskBlueprint;
    return {
      ...data,
      createdAt: data.createdAt || new Date(),
      updatedAt: data.updatedAt || new Date()
    };
  }
};
