import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import { Grid, makeStyles, Theme, CircularProgress } from "@material-ui/core";

import Layout from "components/layout";
import { readAssignment, READ_ASSIGNMENT } from "../../../redux/actions/assignment";
import { GlobalState, AssignmentState } from "utils/types";
import LeftPanel from "../../../components/common/detail-components/LeftPanel";
import RightPanel from "../../../components/common/detail-components/RightPanel";
import { apiRequest } from "redux/makeRequest";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexDirection: "row",
    width: "100%"
  },
  right: {
    minHeight: "calc(100vh - 60px)",
    padding: theme.spacing(2),
    width: "100%",
    flexDirection: "row"
  },
  left: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px 0px 0px 4px",
    flexDirection: "column",
    display: "flex",
    width: "100%",
    position: "relative"
  }
}));

interface Props {
  readAssignment: (data: any) => void;
  assignmentState: AssignmentState;
}

function AssignmentDetail(props: Props) {
  const classes = useStyles();
  const { id } = useParams();

  const loadResources = () => {
    props.readAssignment(id);
  };
  useEffect(() => {
    loadResources();
    // eslint-disable-next-line
  }, [id]);

  if (props.assignmentState.status === apiRequest(READ_ASSIGNMENT)) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress size={60} />
      </div>
    );
  }

  if (props.assignmentState.error != null) {
    throw new Error(props.assignmentState.error);
  }

  return (
    <Layout page="assignments">
      <div>
        <Grid container className={classes.container}>
          <Grid item xs={3} className={classes.left}>
            <LeftPanel />
          </Grid>
          <Grid item xs={9} className={classes.right}>
            <RightPanel />
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  assignmentState: state.assignment
});

const mapDispatchToProps = {
  readAssignment
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AssignmentDetail);
