import firebase from "firebase";
import { FieldPro } from "@fpd-cloud/schemas/core";
const { fromDate } = firebase.firestore.Timestamp;

export const fieldProConverter = {
  toFirestore(u: FieldPro): firebase.firestore.DocumentData {
    const d: firebase.firestore.DocumentData = { ...u };
    if (u.createdAt) d.createdAt = fromDate(new Date(u.createdAt));
    if (u.updatedAt) d.updatedAt = fromDate(new Date(u.updatedAt));
    return d;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): FieldPro {
    const data = snapshot.data(options) as FieldPro;
    return {
      ...data,
      createdAt: data.createdAt || new Date(),
      updatedAt: data.updatedAt || new Date()
    };
  }
};
