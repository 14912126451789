import React from "react";
import firebase from "firebase/app";
import moment from "moment";
import { useSelector } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import { Prop, DateField } from "@fpd-cloud/schemas/core";
import { userPermissionSelector } from "redux/selectors/userSelector";
global.moment = moment;

interface Props {
  propData: Prop;
  handleAssignmentProp: (data: Prop) => void;
  onBlur?: (updatedProp?: any) => void;
}

export default function DatePropComponent(props: Props) {
  const permissions = useSelector(userPermissionSelector);
  const dateField: DateField = props.propData.data as DateField;
  let dateValue: Date = null;
  if (dateField && dateField.dateValue) {
    if ((dateField.dateValue as any).seconds)
      dateValue = moment(
        ((dateField.dateValue as any) as firebase.firestore.Timestamp).toDate()
      ).toDate();
    else dateValue = moment(dateField.dateValue).toDate();
  }
  const isRequired: boolean = dateField.constraints?.dateValue?.isRequired;
  const mutableIf = dateField.constraints?.dateValue.mutableIf;
  let readOnly = false;
  if (mutableIf) {
    const pIndex = permissions.findIndex((permission) => {
      return permission === mutableIf;
    });
    readOnly = pIndex === -1;
  }

  const handleChange = (date: Date) => {
    if (readOnly) return;

    dateField.dateValue = date;
    props.propData.data = dateField;
    props.handleAssignmentProp(props.propData);
    if (props.onBlur) props.onBlur(props.propData);
  };

  return (
    <div style={{ position: "relative", margin: "8px" }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          name={dateField.dateLabel}
          variant='inline'
          format='MM/dd/yyyy'
          margin='normal'
          label={dateField.dateLabel}
          value={dateValue}
          onChange={(date) => handleChange(date)}
          required={isRequired}
          fullWidth
          inputProps={{
            "aria-label": `date-${dateField.dateLabel}`
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
