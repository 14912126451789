import { TextField, Grid, Select, MenuItem } from "@material-ui/core";
import React from "react";

import { capitalizeFirstLetter } from "../../utils/helper";
import { EmailRecord, EmailRecordConstraints, EmailType } from "@fpd-cloud/schemas/core";

interface Props {
  emailData: EmailRecord;
  handleEmailProp: (data: EmailRecord) => void;
  onBlur?: () => void;
  isSmallWdith?: boolean;
}
const emailTypes = ["home", "work", "other", "unknown"];

export default function EmailRecordComponent(props: Props) {
  const { isSmallWdith } = props;
  const constraints: EmailRecordConstraints = props.emailData.constraints;
  return (
    <Grid item xs={isSmallWdith ? 12 : 6} style={{ display: "flex" }}>
      <Select
        value={props.emailData.emailType}
        onChange={(evt) => {
          props.handleEmailProp({ ...props.emailData, emailType: evt.target.value as EmailType });
        }}
        inputProps={{ "aria-label": "Email Type" }}
        style={{ width: "40%", marginRight: "20px" }}
        onBlur={() => {
          if (props.onBlur) props.onBlur();
        }}
      >
        {emailTypes.map((et) => (
          <MenuItem value={et} key={et}>
            {capitalizeFirstLetter(et)}
          </MenuItem>
        ))}
      </Select>
      <TextField
        value={props.emailData.emailAddress || ""}
        label={`${capitalizeFirstLetter(props.emailData.emailType)} Email Address`}
        type='email'
        variant='outlined'
        required={constraints?.emailAddress.isRequired}
        onChange={(evt) => {
          props.handleEmailProp({ ...props.emailData, emailAddress: evt.target.value });
        }}
        onBlur={() => {
          if (props.onBlur) props.onBlur();
        }}
        style={{ width: "60%" }}
        inputProps={{
          "aria-label": "Email Address",
          maxLength: `${constraints?.emailAddress.maxLength}`
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
    </Grid>
  );
}
