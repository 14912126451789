import firebase from "utils/firebase";

import * as ClientFileList from "domain/repos/ClientFileRepo/ClientFileList";
import ClientFileRepo from "domain/repos/ClientFileRepo";
import { clientFileConverter } from "../converters";
import { ClientFile } from "@fpd-cloud/schemas/core";
import { minmax } from "../../../domain/minmax";

export class FirestoreClientFileRepo implements ClientFileRepo {
  constructor(private fdb: firebase.firestore.Firestore) {}

  async fetchClientFilesByClientId(
    request: ClientFileList.Request
  ): Promise<ClientFileList.Response> {
    const curr = {
      ...request,
      limit: minmax(1, 100, request.limit),
      orderBy: request.orderBy || "+createdAt"
    };
    // set limit
    let query = this.fdb
      .collection("clientFiles")
      .limit(curr.limit)
      .where("clientId", "==", request.clientId);

    // set order
    if (curr.orderBy === "+createdAt") query = query.orderBy("createdAt", "asc");
    if (curr.orderBy === "-createdAt") query = query.orderBy("createdAt", "desc");
    if (curr.orderBy === "+documentName") query = query.orderBy("name", "asc");
    if (curr.orderBy === "-documentName") query = query.orderBy("name", "desc");

    // fetch

    const clientFileSnapshots = await query.withConverter(clientFileConverter).get();
    if (clientFileSnapshots.empty) {
      return null;
    }

    // map client files and store their data as an array
    const clientFiles = clientFileSnapshots.docs.map((doc) => doc.data());

    return {
      clientFiles
    };
  }

  async saveClientFile(clientFile: ClientFile): Promise<void> {
    await this.fdb.collection("clientFiles").doc(clientFile.id).set(clientFile, { merge: true });
  }

  async updateClientFileStatus(id: string, updatedStatus: string): Promise<void> {
    await this.fdb
      .collection("clientFiles")
      .withConverter(clientFileConverter)
      .doc(id)
      .update({ status: updatedStatus });
  }
}
