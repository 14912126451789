import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";

import { SubtaskEnvelope } from "@fpd-cloud/schemas/core";
import { capitalizeFirstLetter } from "utils/helper";
import { subTaskStatusValues } from "utils/costant";
import { updateSubtaskStatus } from "redux/actions/task";
import { GlobalState, AssignmentDetail } from "utils/types";
import SubtaskDetail from "./SubtaskDetail";

interface Props {
  assignmentEnvelope: AssignmentDetail;
  subTasks: SubtaskEnvelope[];
  updateSubtaskStatus: (data: any) => void;
}

function SubtaskList(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedId, setSelectedId] = React.useState<string>(null);

  const handleUpdateTaskStatus = (status: string) => {
    props.updateSubtaskStatus({
      assignmentId: props.assignmentEnvelope.assignment.id,
      subTaskId: selectedId,
      status,
      successCB: () => {
        setAnchorEl(null);
        setSelectedId(null);
      }
    });
  };

  return (
    <div style={{ marginLeft: "2em", width: "100%" }}>
      {props.subTasks.map((subTask) => (
        <SubtaskDetail
          key={subTask.subtask.id}
          subTask={subTask}
          handleUpdateTaskStatus={handleUpdateTaskStatus}
          setSelectedId={setSelectedId}
          setAnchorEl={setAnchorEl}
        />
      ))}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: 130,
            padding: 0
          }
        }}
      >
        {subTaskStatusValues.map((status) => (
          <MenuItem
            key={status}
            style={{
              borderBottom: "1px solid #E5E5E5"
            }}
            onClick={(e) => handleUpdateTaskStatus(status)}
          >
            {capitalizeFirstLetter(status)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  assignmentEnvelope: state.assignment.assignmentEnvelope
});

const mapDispatchToProps = {
  updateSubtaskStatus
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SubtaskList);
