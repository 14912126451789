import { handleActions } from "redux-actions";
import {
  READ_CLIENTS,
  READ_CLIENTS_REPO,
  GET_CLIENT,
  SAVE_CLIENT_FILE,
  GET_CLIENT_FILES,
  UPDATE_CLIENT_FILE_STATUS,
  FETCH_CLIENT_SNAPSHOT,
  UPDATE_CLIENT
} from "../actions/client";
import { apiSuccess, apiError } from "../makeRequest";
import { ClientState } from "../../utils/types";

const initialState: ClientState = {
  clientList: [],
  clientFileList: [],
  clientDetail: null,
  clientResponse: null,
  snapshot: null,
  status: "INIT",
  error: null
};

export default handleActions<ClientState, any>(
  {
    [apiSuccess(READ_CLIENTS)]: (state: ClientState, { payload }) => {
      return {
        ...state,
        status: apiSuccess(READ_CLIENTS),
        clientList: payload
      };
    },
    [apiError(READ_CLIENTS)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(READ_CLIENTS),
        error: payload
      };
    },
    [apiSuccess(READ_CLIENTS_REPO)]: (state: ClientState, { payload }) => {
      return {
        ...state,
        status: apiSuccess(READ_CLIENTS_REPO),
        clientResponse: payload
      };
    },
    [apiError(READ_CLIENTS_REPO)]: (state: ClientState, { payload }) => {
      return {
        ...state,
        status: apiError(READ_CLIENTS_REPO),
        error: payload
      };
    },
    [apiSuccess(GET_CLIENT)]: (state: ClientState, { payload }) => {
      return {
        ...state,
        status: apiSuccess(GET_CLIENT),
        clientDetail: payload
      };
    },
    [apiError(GET_CLIENT)]: (state: ClientState, { payload }) => {
      return {
        ...state,
        status: apiError(GET_CLIENT),
        error: payload
      };
    },
    [apiSuccess(GET_CLIENT_FILES)]: (state: ClientState, { payload }) => {
      return {
        ...state,
        status: apiSuccess(GET_CLIENT_FILES),
        clientFileList: payload
      };
    },
    [apiError(GET_CLIENT_FILES)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(GET_CLIENT_FILES),
        error: payload
      };
    },
    [apiSuccess(SAVE_CLIENT_FILE)]: (state: ClientState, { payload }) => {
      return {
        ...state,
        status: apiSuccess(SAVE_CLIENT_FILE)
      };
    },
    [apiError(SAVE_CLIENT_FILE)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(SAVE_CLIENT_FILE),
        error: payload
      };
    },
    [apiSuccess(FETCH_CLIENT_SNAPSHOT)]: (state: ClientState, { payload }) => {
      return {
        ...state,
        status: apiSuccess(FETCH_CLIENT_SNAPSHOT),
        snapshot: payload
      };
    },
    [apiSuccess(UPDATE_CLIENT_FILE_STATUS)]: (state: ClientState, { payload }) => {
      return {
        ...state,
        status: apiSuccess(UPDATE_CLIENT_FILE_STATUS)
      };
    },
    [apiError(UPDATE_CLIENT_FILE_STATUS)]: (state, { payload }) => {
      return {
        ...state,
        status: apiError(UPDATE_CLIENT_FILE_STATUS),
        error: payload
      };
    },
    [apiSuccess(UPDATE_CLIENT)]: (state, { payload }) => {
      return {
        ...state,
        clientDetail: {
          ...state.clientDetail,
          ...payload
        }
      };
    }
  },
  initialState
);
