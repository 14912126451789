import { takeLatest } from "redux-saga/effects";

import {
  GET_ENVELOPE_REST,
  CRAETE_ASSIGNMENT_REST,
  CREATE_ADHOC_TASK_REST
} from "../actions/envelope";
import apiCall from "redux/apiCall";

// Using RESTful API Services
const doCreateAssignmentRest = apiCall({
  type: CRAETE_ASSIGNMENT_REST,
  method: "post",
  path: () => `assignments`
});

const doCreateAdhocTaskRest = apiCall({
  type: CREATE_ADHOC_TASK_REST,
  method: "post",
  path: () => `assignments/adhoc-tasks`
});

const doGetEnvelopeRest = apiCall({
  type: GET_ENVELOPE_REST,
  method: "get",
  path: ({ payload }) => `assignments/blank-slate/${payload.id}`
});

export default function* envelopeSaga() {
  yield takeLatest(GET_ENVELOPE_REST, doGetEnvelopeRest);
  yield takeLatest(CRAETE_ASSIGNMENT_REST, doCreateAssignmentRest);
  yield takeLatest(CREATE_ADHOC_TASK_REST, doCreateAdhocTaskRest);
}
