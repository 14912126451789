import React from "react";
import {
  Divider,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select
} from "@material-ui/core";
import { STATE_LIST } from "utils/costant";
import { ClaimTypes } from "@fpd-cloud/schemas/core";
import { Props } from "./FPDContactInformation";

const CLAIM_TYPES: ClaimTypes[] = [
  "Residential",
  "Commercial",
  "Flood",
  "Auto",
  "Earthquake",
  "General Liability",
  "other"
];

export default function ClaimVolume({ clientSnapshot, handleUpdateSnapshotData }: Props) {
  const isCliamTypeChecked = (claimType) => {
    if (!clientSnapshot || !clientSnapshot.claimTypes || clientSnapshot.claimTypes.length === 0) {
      return false;
    }

    return clientSnapshot.claimTypes.findIndex((c) => c === claimType) > -1;
  };

  const isExplosureStateChecked = (stateItem) => {
    if (
      !clientSnapshot ||
      !clientSnapshot.claimExposureStates ||
      clientSnapshot.claimExposureStates.length === 0
    ) {
      return false;
    }

    return clientSnapshot.claimExposureStates.findIndex((c) => c === stateItem) > -1;
  };

  return (
    <>
      <Typography variant='h5' style={{ marginTop: "20px" }}>
        Claim Volume
      </Typography>
      <Divider style={{ width: "100%", margin: "20px 0 30px 0" }} />
      <FormControl component='fieldset' required style={{ marginLeft: 8 }}>
        <FormLabel component='legend'>Claim Type(s)</FormLabel>
        <FormGroup row>
          {CLAIM_TYPES.map((claimType) => (
            <FormControlLabel
              key={claimType}
              control={
                <Checkbox
                  checked={isCliamTypeChecked(claimType)}
                  onChange={(e) => {
                    const claimTypes = clientSnapshot?.claimTypes || [];
                    if (e.target.checked) {
                      claimTypes.push(claimType);
                    } else {
                      const index = claimTypes.findIndex((c) => c === claimType);
                      claimTypes.splice(index, 1);
                    }
                    handleUpdateSnapshotData("claimTypes", [...claimTypes]);
                  }}
                />
              }
              label={claimType}
              style={{ minWidth: "40%" }}
            />
          ))}
        </FormGroup>
      </FormControl>

      <Typography style={{ fontWeight: "bold", marginTop: 30, width: "100%", marginLeft: 8 }}>
        Is volume known?
      </Typography>
      <Grid item xs={6}>
        <Select
          style={{ minWidth: 200 }}
          native
          fullWidth
          value={clientSnapshot?.isVolumeKnown ? "Yes" : "No"}
          onChange={(e) => {
            handleUpdateSnapshotData("isVolumeKnown", e.target.value === "Yes" ? true : false);
          }}
        >
          <option value={"Yes"}>Yes</option>
          <option value={"No"}>No</option>
        </Select>
      </Grid>
      <Grid item xs={6} />

      <FormControl component='fieldset' fullWidth style={{ marginTop: 20 }}>
        <FormLabel component='legend'>Claim Explosure</FormLabel>
        <FormGroup row>
          {STATE_LIST.map((item) => (
            <FormControlLabel
              key={item}
              control={
                <Checkbox
                  checked={isExplosureStateChecked(item)}
                  onChange={(e) => {
                    const stateList = clientSnapshot?.claimExposureStates || [];
                    if (e.target.checked) {
                      stateList.push(item);
                    } else {
                      const index = stateList.findIndex((c) => c === item);
                      stateList.splice(index, 1);
                    }
                    handleUpdateSnapshotData("claimExposureStates", [...stateList]);
                  }}
                />
              }
              label={item}
              style={{ minWidth: "30%" }}
            />
          ))}
        </FormGroup>
      </FormControl>
    </>
  );
}
