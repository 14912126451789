import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  card: {
    transform: "translateY(-50%)",
    textAlign: "center",
    position: "relative"
  },
  text: {
    position: 'absolute',
    top: '50%',
    width: '150%',
    background: 'white',
    padding: '30px 0',
    fontSize: '20px',
    transform: 'translate(-15%, -50%)',
    color: 'black',
    fontWeight: 'bold',
    letterSpacing: '4px'
  }
}));

export default function EscalationTab() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <img src='/assets/images/escalations.svg' alt='Escalations' />
        <Typography className={classes.text}>
          Coming Soon
        </Typography>
      </div>
    </div>
  );
}
