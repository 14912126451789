import React from "react";
import { IconButton, makeStyles, Theme, Typography, Select, MenuItem } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";

interface Props {
  response: any;
  setRequest?: (newRequest: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    color: "#FFFFFF",
    background: "#066F88",
    borderRadius: "0px 0px 10px 10px",
    alignItems: "center"
  },
  paginationBtn: {
    color: "white",
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)"
    }
  }
}));

export default function TablePagination(props: Props) {
  const classes = useStyles();
  const { response, setRequest } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRequest({
      ...response.pages.curr,
      page: 1,
      startAfter: null,
      endBefore: null,
      limit: event.target.value as number
    });
  };

  const limit = response?.pages?.curr?.limit || 10;
  const pageNumber = response?.pages?.curr?.page;

  return (
    <div className={classes.container}>
      <div style={{ flex: "1 1 100%" }} />
      <Typography style={{ width: "210px" }}>Rows per page:</Typography>
      <Select value={limit} onChange={handleChange} style={{ color: "white" }}>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
      </Select>
      <div style={{ width: "250px" }}>
        <IconButton
          disabled={pageNumber === 1}
          onClick={() => {
            setRequest({
              ...response.pages.curr,
              page: 1,
              startAfter: null,
              endBefore: null
            });
          }}
        >
          <SkipPreviousIcon />
        </IconButton>
        {`Page ${pageNumber} of many`}
      </div>
      <div style={{ marginLeft: 20, display: "flex" }}>
        <IconButton
          disabled={!response.pages?.prev}
          onClick={() => setRequest(response.pages.prev)}
          className={classes.paginationBtn}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton
          disabled={!response.pages?.next}
          onClick={() => setRequest(response.pages.next)}
          className={classes.paginationBtn}
        >
          <NavigateNextIcon />
        </IconButton>
      </div>
    </div>
  );
}
