import React from "react";
import { Dialog, DialogTitle, DialogContent, Typography } from "@material-ui/core";

import { AssignmentBlueprint } from "@fpd-cloud/schemas/core";

interface Props {
  blueprints: AssignmentBlueprint[];
  onClose: () => void;
}

export default function AssignmentTypes(props: Props) {
  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>Assignment Types</DialogTitle>
      <DialogContent dividers>
        {props.blueprints.map((bluprint) => (
          <Typography key={bluprint.id}>{bluprint.name}</Typography>
        ))}
      </DialogContent>
    </Dialog>
  );
}
