import { handleActions } from "redux-actions";

import { GET_ENVELOPE_REST, RESET_BLANK_ENVELOPE } from "../actions/envelope";
import { apiSuccess, apiError } from "../makeRequest";
import { EnvelopeState } from "utils/types";

const initialState: EnvelopeState = {
  blankEnvelope: null,
  status: "INIT",
  error: null
};

export default handleActions<EnvelopeState, any>(
  {
    [apiSuccess(GET_ENVELOPE_REST)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(GET_ENVELOPE_REST),
        blankEnvelope: payload.data
      };
    },
    [apiError(GET_ENVELOPE_REST)]: (state, { payload }) => {
      return {
        ...state,
        status: apiSuccess(GET_ENVELOPE_REST),
        error: payload
      };
    },
    [RESET_BLANK_ENVELOPE]: (state) => ({
      ...state,
      blankEnvelope: null
    })
  },
  initialState
);
