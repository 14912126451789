import firebase from "firebase";

import * as ClientList from "domain/repos/ClientRepo/ClientList";
import * as ClientDetail from "domain/repos/ClientRepo/ClientDetail";
import ClientRepo from "domain/repos/ClientRepo";
import { assignmentBlueprintConverter, clientConverter, assignmentConverter } from "../converters";
import { minmax } from "../../../domain/minmax";
import { AssignmentBlueprint, Client, Assignment } from "@fpd-cloud/schemas/core";

export class FirestoreClientRepo implements ClientRepo {
  constructor(private fdb: firebase.firestore.Firestore) {}

  async fetchClients(request: ClientList.Request): Promise<ClientList.Response> {
    // 1. "normalize" the request to ensure page, limit and orderBy have a value:
    // -----------------------------------------------------------
    const curr = {
      ...request,
      page: request.page || 1,
      limit: minmax(1, 20, request.limit),
      orderBy: request.orderBy || "+name"
    };

    // 2. build a query, based on the normalized request aka: curr
    // -----------------------------------------------------------
    let query = this.fdb.collection("clients").limit(curr.limit);

    // set orderBy first
    if (curr.orderBy === "+createdAt") query = query.orderBy("createdAt", "asc");
    if (curr.orderBy === "-createdAt") query = query.orderBy("createdAt", "desc");
    if (curr.orderBy === "+name") query = query.orderBy("name", "asc");
    if (curr.orderBy === "-name") query = query.orderBy("name", "desc");

    // then tell it where we want to start
    if (curr.startAfter) query = query.startAfter(curr.startAfter);
    if (curr.endBefore) query = query.endBefore(curr.endBefore);

    // add filters to our query
    if (curr.withNamePrefix?.length > 0) {
      query = query
        .where("name", ">=", curr.withNamePrefix)
        .where("name", "<=", curr.withNamePrefix + "\uf8ff");
    }

    // 3. run the query / start fetching data
    // -----------------------------------------------------------

    // fetch clients
    const clientSnapshots = await query.withConverter(clientConverter).get();
    if (clientSnapshots.empty) {
      return null;
    }

    const clients = clientSnapshots.docs.map((doc) => doc.data());

    const assignmentBlueprintsByClientId = {};
    if (curr.includeAssignmentBlueprints) {
      const assignmentBlueprints = await this.fetchAssignmentBlueprints();
      assignmentBlueprints.forEach((assignmentBlueprint) => {
        const clientId = assignmentBlueprint.clientId;
        if (!assignmentBlueprintsByClientId[clientId])
          assignmentBlueprintsByClientId[clientId] = [];
        assignmentBlueprintsByClientId[clientId].push(assignmentBlueprint);
      });
    }

    return {
      clients,
      assignmentBlueprintsByClientId,
      pages: {
        curr,
        next: this.buildNext(curr, clients),
        prev: this.buildPrev(curr, clients)
      }
    };
  }

  private async fetchAssignmentBlueprints(): Promise<AssignmentBlueprint[]> {
    const snaps = await this.fdb
      .collection("assignment-blueprints")
      .limit(100)
      .withConverter(assignmentBlueprintConverter)
      .orderBy("name")
      .get();

    return snaps.docs.map((doc) => doc.data());
  }

  private async fetchAssignments(clientId: string): Promise<Assignment[]> {
    const snaps = await this.fdb
      .collection("assignments")
      .where("clientId", "==", clientId)
      .limit(100)
      .withConverter(assignmentConverter)
      .orderBy("clientName")
      .get();

    return snaps.docs.map((doc) => doc.data());
  }

  private buildNext(curr: ClientList.Request, clients: Client[]): ClientList.Request | null {
    if (clients.length === 0 || clients.length < curr.limit) {
      return null;
    }

    const last = clients[clients.length - 1];
    let startAfter: string | Date;
    if (curr.orderBy === "+createdAt") startAfter = last.createdAt;
    if (curr.orderBy === "-createdAt") startAfter = last.createdAt;
    if (curr.orderBy === "+name") startAfter = last.name;
    if (curr.orderBy === "-name") startAfter = last.name;

    return {
      ...curr,
      page: curr.page + 1,
      startAfter,
      endBefore: null
    };
  }

  private buildPrev(curr: ClientList.Request, clients: Client[]): ClientList.Request | null {
    if (clients.length === 0 || curr.page === 1) {
      return null;
    }

    const first = clients[0];
    let endBefore: string | Date;
    if (curr.orderBy === "+createdAt") endBefore = first.createdAt;
    if (curr.orderBy === "-createdAt") endBefore = first.createdAt;
    if (curr.orderBy === "+name") endBefore = first.name;
    if (curr.orderBy === "-name") endBefore = first.name;

    return {
      ...curr,
      page: curr.page - 1,
      startAfter: null,
      endBefore
    };
  }

  async fetchClient(request: ClientDetail.Request): Promise<ClientDetail.Response | null> {
    const query = this.fdb.collection("clients").doc(request.id);

    const clientSnapshots = await query.withConverter(clientConverter).get();
    if (!clientSnapshots.exists) return null;
    const assignments = await this.fetchAssignments(request.id);

    return {
      clientDetail: {
        ...clientSnapshots.data(),
        assignments
      }
    };
  }
}
