import React from "react";
import { Prop, MarkdownBlock } from "@fpd-cloud/schemas/core";
import { Typography } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

interface Props {
  propData?: Prop;
  markdown?: string;
}

export default function MarkdownProp(props: Props) {
  let markdown: string = "";
  if (!props.markdown) {
    const data: MarkdownBlock = props.propData.data as MarkdownBlock;
    markdown = data.markdown;
  } else {
    markdown = props.markdown;
  }

  return (
    <Typography>
      <ReactMarkdown plugins={[[gfm, { singleTilde: false }]]}>{markdown}</ReactMarkdown>
    </Typography>
  );
}
