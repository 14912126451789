import firebase from "firebase";

import * as TeamList from "domain/repos/TeamRepo/TeamList";
import TeamRepo from "domain/repos/TeamRepo";

import { teamConverter, teamMemberConverter } from "../converters";
import { minmax } from "domain/minmax";
import { TeamMember, Team } from "@fpd-cloud/schemas/core";

export class FirestoreTeamRepo implements TeamRepo {
  constructor(private db: firebase.firestore.Firestore) {}

  private async getTeamsListFromCurr(curr: TeamList.Request) {
    let query = curr.endBefore
      ? this.db.collection("teams").limitToLast(curr.limit)
      : this.db.collection("teams").limit(curr.limit);

    // set orderBy first
    if (curr.orderBy === "+createdAt") query = query.orderBy("createdAt", "asc");
    if (curr.orderBy === "-createdAt") query = query.orderBy("createdAt", "desc");
    if (curr.orderBy === "+name") query = query.orderBy("name", "asc");
    if (curr.orderBy === "-name") query = query.orderBy("name", "desc");

    // then tell it where we want to start
    if (curr.startAfter) query = query.startAfter(curr.startAfter);
    if (curr.endBefore) query = query.endBefore(curr.endBefore);

    // add filters to our query
    if (curr.withNamePrefix?.length > 0) {
      query = query
        .where("name", ">=", curr.withNamePrefix)
        .where("name", "<=", curr.withNamePrefix + "\uf8ff");
    }

    // 3. run the query / start fetching data
    // -----------------------------------------------------------
    const teamSnapshots = await query.withConverter(teamConverter).get();
    if (teamSnapshots.empty) {
      return [];
    }

    const teamsList = [];
    await Promise.all(
      teamSnapshots.docs.map(async (doc) => {
        const team = doc.data();
        const teamMembers = await this.fetchTeamMembers(team.id);
        teamsList.push({
          team,
          teamMembers
        });
      })
    );

    return teamsList;
  }

  async fetchTeamsList(request: TeamList.Request): Promise<TeamList.Response> {
    if (!request) return null;

    const curr = {
      ...request,
      page: request.page || 1,
      limit: minmax(1, 20, request.limit),
      orderBy: request.orderBy || "+name"
    };

    const teamsList = await this.getTeamsListFromCurr(curr);
    const next = await this.buildNext(curr, teamsList);
    return {
      teamsList,
      pages: {
        curr,
        next,
        prev: this.buildPrev(curr, teamsList)
      }
    };
  }

  private async fetchTeamMembers(teamId: string): Promise<TeamMember[]> {
    const team = await this.db.collection("teams").doc(teamId).withConverter(teamConverter).get();
    const memberRef = await team.ref
      .collection("team-members")
      .limit(100)
      .withConverter(teamMemberConverter)
      .get();

    const teamMembers = memberRef.docs.map((doc) => doc.data());
    return teamMembers;
  }

  private async buildNext(
    curr: TeamList.Request,
    teamsList: { team: Team; teamMembers: TeamMember[] }[]
  ): Promise<TeamList.Request | null> {
    if (teamsList.length === 0 || teamsList.length < curr.limit) {
      return null;
    }

    const last = teamsList[teamsList.length - 1];
    let startAfter: string | Date;
    if (curr.orderBy === "+createdAt") startAfter = last.team.createdAt;
    if (curr.orderBy === "-createdAt") startAfter = last.team.createdAt;
    if (curr.orderBy === "+name") startAfter = last.team.name;
    if (curr.orderBy === "-name") startAfter = last.team.name;

    const nextRequest = {
      ...curr,
      page: curr.page + 1,
      startAfter,
      endBefore: null
    };

    const nextTeamsList = await this.getTeamsListFromCurr(nextRequest);
    if (!nextTeamsList || nextTeamsList.length === 0) return null;
    return nextRequest;
  }

  private buildPrev(
    curr: TeamList.Request,
    teamsList: { team: Team; teamMembers: TeamMember[] }[]
  ): TeamList.Request | null {
    if (curr.page === 1) {
      return null;
    }

    const first = teamsList[0];
    let endBefore: string | Date;
    if (curr.orderBy === "+createdAt") endBefore = first.team.createdAt;
    if (curr.orderBy === "-createdAt") endBefore = first.team.createdAt;
    if (curr.orderBy === "+name") endBefore = first.team.name;
    if (curr.orderBy === "-name") endBefore = first.team.name;

    return {
      ...curr,
      page: curr.page - 1,
      startAfter: null,
      endBefore
    };
  }
}
