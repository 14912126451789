import firebase from "firebase";

import * as AdhocTaskBlueprintList from "domain/repos/AdhocTaskBlueprintRepo/AdhocTaskBlueprintList";
import AdhocTaskBlurprintRepo from "domain/repos/AdhocTaskBlueprintRepo";
import { adhocTaskBlueprintConverter } from "../converters";
import { minmax } from "domain/minmax";

export class FirestoreAdhocTaskBlueprintRepo implements AdhocTaskBlurprintRepo {
  constructor(private fdb: firebase.firestore.Firestore) {}

  async fetchTaskBlueprintList(
    request: AdhocTaskBlueprintList.Request
  ): Promise<AdhocTaskBlueprintList.Response> {
    const curr = {
      ...request,
      limit: minmax(1, 100, request.limit),
      orderBy: request.orderBy || "+createdAt"
    };

    let query = this.fdb.collection("adhoc-task-blueprints").limit(curr.limit);

    if (curr.orderBy === "+createdAt") query = query.orderBy("createdAt", "asc");
    if (curr.orderBy === "-createdAt") query = query.orderBy("createdAt", "desc");
    if (curr.orderBy === "+taskName") query = query.orderBy("taskName", "asc");
    if (curr.orderBy === "-taskName") query = query.orderBy("taskName", "desc");

    const snapshots = await query.withConverter(adhocTaskBlueprintConverter).get();
    if (snapshots.empty) return null;

    const lists = [];
    snapshots.docs.forEach((doc) => {
      const data = doc.data();
      if (
        data.assignmentBlueprintId === "" ||
        data.assignmentBlueprintId === request.assignmentBlueprintId
      ) {
        lists.push(data);
      }
    });
    return {
      adhocTaskBlueprintsList: lists
    };
  }
}
