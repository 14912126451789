import { Grid, Select, MenuItem } from "@material-ui/core";
import MyPhoneNumber from "material-ui-phone-number";
import React from "react";

import { capitalizeFirstLetter } from "../../utils/helper";
import { PhoneRecord, PhoneType } from "@fpd-cloud/schemas/core";

interface Props {
  phoneData: PhoneRecord;
  handlePhoneProp: (data: PhoneRecord) => void;
  onBlur?: () => void;
  isSmallWdith?: boolean;
}

const phoneTypes = ["home", "mobile", "work", "other", "unknown"];

export default function PhoneRecordComponent(props: Props) {
  const { isSmallWdith } = props;
  return (
    <Grid item xs={isSmallWdith ? 12 : 6} style={{ display: "flex" }}>
      <Select
        value={props.phoneData.phoneType}
        onChange={(evt) => {
          props.handlePhoneProp({ ...props.phoneData, phoneType: evt.target.value as PhoneType });
        }}
        inputProps={{ "aria-label": "Phone Type" }}
        style={{ width: "40%", marginRight: "20px" }}
        onBlur={() => {
          if (props.onBlur) props.onBlur();
        }}
      >
        {phoneTypes.map((pt) => (
          <MenuItem value={pt} key={pt}>
            {capitalizeFirstLetter(pt)}
          </MenuItem>
        ))}
      </Select>
      <MyPhoneNumber
        label={`${capitalizeFirstLetter(props.phoneData.phoneType)} Phone Number`}
        defaultCountry='us'
        disableDropdown
        value={props.phoneData.phoneNumber || ""}
        onChange={(value) => {
          props.handlePhoneProp({ ...props.phoneData, phoneNumber: value });
        }}
        onBlur={() => {
          if (props.onBlur) props.onBlur();
        }}
        fullWidth
        variant='outlined'
        style={{ width: "60%" }}
        inputProps={{
          "aria-label": "Phone Number"
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
    </Grid>
  );
}
