import firebase from "firebase";

import * as ClientSnapshotDetail from "domain/repos/ClientSnapshotRepo/ClientSnapshotDetail";
import ClientSnapshotRepo from "domain/repos/ClientSnapshotRepo";
import { clientSnapshotConverter } from "../converters";

export class FirestoreClientSnapshotRepo implements ClientSnapshotRepo {
  constructor(private fdb: firebase.firestore.Firestore) {}

  async fetchClientSnapshot(
    request: ClientSnapshotDetail.Request
  ): Promise<ClientSnapshotDetail.Response | null> {
    const query = this.fdb.collection("snapshots").doc(request.clientId);

    const snapshot = await query.withConverter(clientSnapshotConverter).get();
    if (!snapshot.exists) return null;

    return {
      clientSnapshot: snapshot.data()
    };
  }
}
