import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";

import reducers from "./reducers";
import sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "user",
  storage,
  whitelist: ["user"] // which reducer want to store
};
const pReducer = persistReducer(persistConfig, reducers);

const bindMiddleware = (middleware: any) => {
  return applyMiddleware(...middleware);
};

function configureStore() {
  const store = createStore(pReducer, {}, composeWithDevTools(bindMiddleware([sagaMiddleware])));
  persistStore(store);

  sagaMiddleware.run(sagas);
  return store;
}

export default configureStore;
