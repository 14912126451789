import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";

interface Props {
  title?: string;
  content: string;
  confirmText?: string;
  cancelText?: string;
  onCancel: () => void;
  onSuccess: () => void;
}

const DEFAULT_CONFIRM_TEXT = "Delete";
const DEFAULT_CANCEL_TEXT = "Cancel";

export default function ConfirmDialog(props: Props) {
  return (
    <Dialog open={true} onClose={props.onCancel}>
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      <DialogContent dividers>
        <DialogContentText color='textPrimary'>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' variant='contained' disableElevation onClick={props.onSuccess}>
          {props.confirmText || DEFAULT_CONFIRM_TEXT}
        </Button>
        <Button color='secondary' variant='text' disableElevation onClick={props.onCancel}>
          {props.cancelText || DEFAULT_CANCEL_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
