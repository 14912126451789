import React from "react";

import { Prop, TimeField } from "@fpd-cloud/schemas/core";
import { TextField } from "@material-ui/core";
import { convertFirebaseDateToNormalDate } from "utils/helper";

interface Props {
  propData: Prop;
  handleAssignmentProp: (data: Prop) => void;
  onBlur?: (updatedProp?: any) => void;
}

export default function TimePropComponent(props: Props) {
  const data: TimeField = props.propData.data as TimeField;

  const timeValue = convertFirebaseDateToNormalDate(data.timeValue);
  const addZeroNumToStr = (num: number) => {
    if (num < 10) return "0" + num;
    return num;
  };

  return (
    <TextField
      id='time'
      label={data.timeLabel}
      type='time'
      value={
        data.timeValue &&
        `${addZeroNumToStr(timeValue.hour())}:${addZeroNumToStr(timeValue.minute())}`
      }
      variant='outlined'
      margin='normal'
      required={data.constraints?.timeValue.isRequired}
      onChange={(evt) => {
        const hour = parseInt(evt.target.value.split(":")[0], 10);
        const minute = parseInt(evt.target.value.split(":")[1], 10);
        timeValue.hour(hour);
        timeValue.minute(minute);
        data.timeValue = timeValue.toDate();
        props.handleAssignmentProp({
          ...props.propData,
          data
        });
      }}
      onBlur={() => {
        if (props.onBlur) props.onBlur();
      }}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        step: 300 // 5 min
      }}
      fullWidth
    />
  );
}
