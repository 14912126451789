import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { Provider as AlertProvider } from "react-alert";
import { v4 as uuidv4 } from "uuid";
import RootApp from "./containers";
import theme from "./theme";
import configureStore from "./redux/configureStore";
import AlertTemplate from "./components/AlertTemplate";
import * as serviceWorker from "./serviceWorker";
import Layout from "../src/components/layout";
import { Typography, makeStyles, Theme } from "@material-ui/core";
import "./index.css";

const useStyles = makeStyles((styleTheme: Theme) => ({
  errorDiv: {
    padding: styleTheme.spacing(2, 8, 4, 8)
  }
}));

const store = configureStore();

const user = store.getState().user;

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_RELEASE_STAGE,
  metadata: {
    errorData: { errorId: uuidv4().slice(0, 8) }
  },
  onError: (event) => {
    event.setUser(user.me.id, user.me.email, user.me.displayName);
  }
});

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

const ErrorView = (error, info) => {
  const errorId = Bugsnag.getMetadata("errorData", "errorId");
  const classes = useStyles();

  return (
    <Layout>
      <div className={classes.errorDiv}>
        <Typography>
          An error has occured.
          <br />
          ErrorId: {errorId}
          <br />
          Field Pros Direct has been notified.
          <br />
          Email us at{" "}
          <a href='mailto:techsupport@fieldprosdirect.com'>techsupport@fieldprosdirect.com</a> if
          you need this expedited.
        </Typography>
      </div>
    </Layout>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AlertProvider template={AlertTemplate} position='bottom center'>
        <ErrorBoundary FallbackComponent={ErrorView}>
          <RootApp />
        </ErrorBoundary>
      </AlertProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
