import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import React, { useEffect } from "react";

import AdminBumper from "./AdminBumper";
import AssignmentList from "./AssignmentList";
import AssignmentDetail from "./AssignmentDetail";
import CreateAssignment from "./CreateAssignment";
import Users from "./Users";
import Clients from "./Clients";
import Profile from "./Profile";
import CreateClient from "./CreateClient";
import ClientDetail from "./ClientDetail";
import Teams from "./Teams";
import { getUser } from "../../redux/actions/user";
import { GlobalState, User } from "utils/types";
import { userIsAdminRedir, userIsNotAdminRedir } from "utils/authHelper";

interface Props {
  me: User;
  getUser: (data: any) => void;
}

function AdminApp(props: Props) {
  const currentUser = props.me;

  const loadResource = () => {
    if (currentUser)
      props.getUser({
        uid: currentUser.id
      });
  };
  useEffect(() => {
    loadResource();
    // eslint-disable-next-line
  }, []);
  return (
    <Switch>
      <Route
        exact
        path='/'
        render={() => {
          if (!currentUser.permissions.includes("admin-app:access")) {
            return <Redirect to='/admin-bumper' />;
          } else return <Redirect to='/assignment-list' />;
        }}
      />
      <Route exact path='/admin-bumper' component={userIsNotAdminRedir(AdminBumper)} />
      <Route exact path='/assignment-list' component={userIsAdminRedir(AssignmentList)} />
      <Route exact path='/assignment-detail/:id' component={userIsAdminRedir(AssignmentDetail)} />
      <Route exact path='/create-assignment' component={userIsAdminRedir(CreateAssignment)} />
      <Route exact path='/users' component={userIsAdminRedir(Users)} />
      <Route exact path='/client-list' component={userIsAdminRedir(Clients)} />
      <Route exact path='/create-client' component={userIsAdminRedir(CreateClient)} />
      <Route exact path='/user-profile' component={userIsAdminRedir(Profile)} />
      <Route exact path='/teams' component={userIsAdminRedir(Teams)} />
      <Route exact path='/client-detail/:id' component={userIsAdminRedir(ClientDetail)} />
    </Switch>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  me: state.user.me
});
const mapDispatchToProps = {
  getUser
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminApp);
