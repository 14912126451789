import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  appId: process.env.REACT_APP_FIREBASE_appId,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
  measurementId: process.env.REACT_APP_FIREBASE_measurementId,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket
});

const auth = firebase.auth();
export const db = firebase.firestore();

if (window.location.hostname === "localhost") {
  auth.useEmulator("http://localhost:9099/");
  db.settings({
    host: "localhost:9080",
    ssl: false
  });
}

export default firebase;
