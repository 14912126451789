import {
  TextField,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  makeStyles
} from "@material-ui/core";
import { useSelector } from "react-redux";
import React from "react";

import { Prop, AddressRecord, AddressRecordConstraints } from "@fpd-cloud/schemas/core";
import { STATE_LIST } from "utils/costant";
import { userPermissionSelector } from "redux/selectors/userSelector";

interface Props {
  propData: Prop;
  handleAssignmentProp: (data: Prop) => void;
  onBlur?: () => void;
}

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: 300
  }
}));

export default function AddressRecordComponent(props: Props) {
  const classes = useStyles();
  const permissions = useSelector(userPermissionSelector);
  const getReadOnly = (mutableIf) => {
    if (!mutableIf) return false;
    let readOnly = false;
    const pIndex = permissions.findIndex((permission) => {
      return permission === mutableIf;
    });
    readOnly = pIndex === -1;
    return readOnly;
  };

  const data: AddressRecord = props.propData.data as AddressRecord;
  const constraints: AddressRecordConstraints = data.constraints;
  const cityMutableIf = constraints?.addressCity.mutableIf;
  const address1MutableIf = constraints?.addressLine1.mutableIf;
  const address2MutableIf = constraints?.addressLine2.mutableIf;
  const stateMutableIf = constraints?.addressState.mutableIf;
  const zipMutableIf = constraints?.addressZip.mutableIf;
  const cityReadOnly = getReadOnly(cityMutableIf);
  const address1ReadOnly = getReadOnly(address1MutableIf);
  const address2ReadOnly = getReadOnly(address2MutableIf);
  const stateReadOnly = getReadOnly(stateMutableIf);
  const zipReadOnly = getReadOnly(zipMutableIf);

  return (
    <Grid container spacing={2} style={{ margin: 0 }}>
      <Grid item xs={12}>
        <TextField
          label='Street Address 1'
          variant='outlined'
          required={constraints?.addressLine1.isRequired}
          fullWidth
          value={data.addressLine1 || ""}
          onChange={(evt) => {
            data.addressLine1 = evt.target.value;
            props.handleAssignmentProp({
              ...props.propData,
              data
            });
          }}
          onBlur={() => {
            if (props.onBlur) props.onBlur();
          }}
          inputProps={{
            "aria-label": "Street Address 1",
            maxLength: `${constraints?.addressLine1.maxLength}`
          }}
          InputProps={{
            readOnly: address1ReadOnly
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label='Street Address 2'
          variant='outlined'
          fullWidth
          required={constraints?.addressLine2.isRequired}
          value={data.addressLine2 || ""}
          onChange={(evt) => {
            data.addressLine2 = evt.target.value;
            props.handleAssignmentProp({
              ...props.propData,
              data
            });
          }}
          onBlur={() => {
            if (props.onBlur) props.onBlur();
          }}
          inputProps={{
            "aria-label": "Street Address 2",
            maxLength: `${constraints?.addressLine2.maxLength}`
          }}
          InputProps={{
            readOnly: address2ReadOnly
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label='City'
          variant='outlined'
          required={constraints?.addressCity.isRequired}
          fullWidth
          value={data.addressCity || ""}
          onChange={(evt) => {
            data.addressCity = evt.target.value;
            props.handleAssignmentProp({
              ...props.propData,
              data
            });
          }}
          onBlur={() => {
            if (props.onBlur) props.onBlur();
          }}
          inputProps={{ "aria-label": "City", maxLength: `${constraints?.addressCity.maxLength}` }}
          InputProps={{
            readOnly: cityReadOnly
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormControl required={constraints?.addressState.isRequired} fullWidth>
          <InputLabel id='state-select-label' style={{ marginLeft: 14, marginTop: -4 }}>
            State
          </InputLabel>
          <Select
            variant='outlined'
            labelId='state-select-label'
            value={data.addressState || ""}
            onChange={(evt) => {
              if (stateReadOnly) return;
              data.addressState = evt.target.value as string;
              props.handleAssignmentProp({
                ...props.propData,
                data
              });
              if (props.onBlur) props.onBlur();
            }}
            fullWidth
            inputProps={{ "aria-label": "State" }}
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            <MenuItem value='' />
            {STATE_LIST.map((usState) => (
              <MenuItem key={usState} value={usState}>
                {usState}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <TextField
          label='Zip Code'
          variant='outlined'
          required={constraints?.addressZip.isRequired}
          fullWidth
          value={data.addressZip || ""}
          onChange={(evt) => {
            data.addressZip = evt.target.value;
            props.handleAssignmentProp({
              ...props.propData,
              data
            });
          }}
          onBlur={() => {
            if (props.onBlur) props.onBlur();
          }}
          inputProps={{
            "aria-label": "Zip Code",
            maxLength: `${constraints?.addressZip.maxLength}`
          }}
          InputProps={{
            readOnly: zipReadOnly
          }}
        />
      </Grid>
    </Grid>
  );
}
