import { Grid } from "@material-ui/core";
import React from "react";

import TextProp from "./text-prop";
import DateProp from "./date-prop";
import HeadingPropComponent from "./heading-prop";
import ContactRecordComponent from "./contact-record";
import AddressRecordComponent from "./address-record";
import SelectionFieldComponent from "./selection-field";
import MarkdownPropComponent from "./markdown-prop";
import TimeProp from "./time-prop";
import FieldProSelectionFieldComponent from "./field-pro-selection-field";

import { Prop, TextField as TextFieldProp } from "@fpd-cloud/schemas/core";

interface Props {
  propData: Prop;
  handleUpdateProps: (data: Prop) => void;
  onBlur?: (updatedProp?: any) => void;
  isSmallWdith?: boolean;
}

export default function DisplayProp(props: Props) {
  const { propData, isSmallWdith } = props;

  const displayIndividualProp = () => {
    if (propData.type === "Header") {
      return <HeadingPropComponent propData={propData} />;
    } else if (propData.type === "AddressRecord") {
      return (
        <AddressRecordComponent
          propData={propData}
          handleAssignmentProp={props.handleUpdateProps}
          onBlur={props.onBlur}
        />
      );
    } else if (propData.type === "ContactRecord") {
      return (
        <ContactRecordComponent
          propData={propData}
          handleAssignmentProp={props.handleUpdateProps}
          onBlur={props.onBlur}
          isSmallWdith={isSmallWdith}
        />
      );
    } else if (propData.type === "SelectionField") {
      return (
        <SelectionFieldComponent
          propData={propData}
          handleSelectionField={props.handleUpdateProps}
          onBlur={props.onBlur}
        />
      );
    } else if (propData.type === "FieldProSelectionField") {
      return (
        <FieldProSelectionFieldComponent
          propData={propData}
          handleFieldProSelectionField={props.handleUpdateProps}
          onBlur={props.onBlur}
        />
      );
    } else if (propData.type === "MarkdownBlock") {
      return (
        <Grid item xs={isSmallWdith ? 6 : 4}>
          <MarkdownPropComponent propData={propData} />
        </Grid>
      );
    } else if (propData.type === "DateField") {
      return (
        <Grid item xs={isSmallWdith ? 12 : 4}>
          <DateProp
            propData={propData}
            handleAssignmentProp={props.handleUpdateProps}
            onBlur={props.onBlur}
          />
        </Grid>
      );
    } else if (propData.type === "TextField") {
      const data: TextFieldProp = props.propData.data as TextFieldProp;
      return (
        <Grid item xs={data.isMultiline ? 12 : isSmallWdith ? 6 : 4}>
          <TextProp
            propData={propData}
            handleAssignmentProp={props.handleUpdateProps}
            onBlur={props.onBlur}
          />
        </Grid>
      );
    } else if (propData.type === "TimeField") {
      return (
        <Grid item xs={isSmallWdith ? 6 : 4}>
          <TimeProp
            propData={propData}
            handleAssignmentProp={props.handleUpdateProps}
            onBlur={props.onBlur}
          />
        </Grid>
      );
    }
  };
  return <>{displayIndividualProp()}</>;
}
