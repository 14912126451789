import React, { useState } from "react";
import { Grid, Link, makeStyles, Theme, IconButton } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import { GlobalState, UserState } from "utils/types";
import { compose } from "redux";
import { connect } from "react-redux";


import ConfirmDialog from "./modals/ConfirmDialog";

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: "white",
    margin: theme.spacing(0, 4),
    padding: "20px 0",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      borderBottom: "4px solid #01B1C3",
      paddingBottom: 16
    },
    "&:active": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      borderBottom: "4px solid #01B1C3",
      paddingBottom: 16
    }
  },
  exit: {
    color: "white",
    margin: theme.spacing(0, 2),
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "none"
    }
  }
}));

interface Props {
  page?: string;
  children?: React.ReactNode;
  userState: UserState;
}

function LayOut(props: Props) {
  const userRole = props.userState.me.permissions.find(permission => permission.includes("role"))

  const classes = useStyles();
  const [isOpenModal, setOpenModal] = useState(false);

  const { page } = props;

  const activeStyle = { color: "#01B1C3", borderBottom: "4px solid #01B1C3", paddingBottom: 16 };

  return (
    <>
      {isOpenModal && (
        <ConfirmDialog
          content='Do you want to log out from Field Pros Admin App?'
          confirmText='Yes, Log Out'
          onCancel={() => setOpenModal(false)}
          onSuccess={() => {
            localStorage.clear();
            window.location.reload();
          }}
        />
      )}
      <header>
        <Grid container alignItems='center' style={{ background: "#1F373F", height: "60px" }}>
          <Grid item xs={2}>
            <Link
              href='/assignment-list'
              style={{ marginLeft: 40 }}>
              <img id='header-logo' alt='Field Pros Direct' src='../assets/images/fpd_logo_w.svg' />
            </Link>
          </Grid>
          <Grid item xs={10}>
            <Grid container justify='flex-end'>
              <Link
                href='/assignment-list'
                className={classes.link}
                style={page === "assignments" ? activeStyle : {}}
              >
                Assignments
              </Link>
              <Link
                href='/client-list'
                className={classes.link}
                style={page === "clients" ? activeStyle : {}}
              >
                Clients
              </Link>
              <Link
                href='/users'
                className={classes.link}
                style={page === "users" ? activeStyle : {}}
              >
                Users
              </Link>
              {userRole === "admin-app:role-admin" ?
              <Link
                href='/teams'
                className={classes.link}
                style={page === "teams" ? activeStyle : {}}
              >
                Teams
              </Link>
              : ""
              }
              <Link
                href='/user-profile'
                className={classes.link}
                style={page === "profile" ? activeStyle : {}}
              >
                Profile
              </Link>
              <IconButton className={classes.exit} onClick={() => setOpenModal(true)}>
                <ExitToApp />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </header>
      {props.children && props.children}
    </>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  userState: state.user
})

export default compose(connect(mapStateToProps, null))(LayOut);
