import React, { useState } from "react";
import { Theme, makeStyles, Typography, Grid, Drawer, Button } from "@material-ui/core";
import { GpsFixed } from "@material-ui/icons";
import { connect } from "react-redux";
import { compose } from "redux";
import { useParams } from "react-router-dom";

import { GlobalState, AssignmentDetail, AssignmentState } from "utils/types";
import TaskDetail from "./TaskDetail";
import { updateTaskStatus, readAssignment } from "redux/actions/assignment";
import { createAdhocTaskRest } from "redux/actions/envelope";
import { Task } from "@fpd-cloud/schemas/core";
import AssignTask from "./AssignTask";
import TaskDetailSidebar from "./TaskDetailSidebar";
import { ExntendedTaskEnvelope } from "utils/types";
import AddhocTaskModal from "components/modals/AddhocTaskModal";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100%",
    padding: "2em"
  },
  yellowBtn: {
    backgroundColor: theme.palette.info.main,
    padding: "8px 12px",
    color: "white",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.palette.info.light
    }
  }
}));

interface Props {
  assignmentEnvelope: AssignmentDetail;
  assignmentState: AssignmentState;
  updateTaskStatus: (data: any) => void;
  createAdhocTaskRest: (data: any) => void;
  readAssignmentDetail: (id: string) => void;
}

function RightPanel(props: Props) {
  const classes = useStyles();
  const [assignTask, setAssignTask] = useState<Task>(null);
  const [detailedTask, setDetailedTask] = useState<ExntendedTaskEnvelope>(null);
  const [isOpenAdhocTaskModal, setOpenAdhocTaskModal] = useState(false);
  const { id } = useParams(); // tslint:disable-line

  const assignmentEnvelope = props.assignmentEnvelope;
  if (!assignmentEnvelope || !assignmentEnvelope.taskEnvelopes) return null;

  const handleUpdateTaskStatus = (id: string, status: string) => { //tslint:disable-line
    props.updateTaskStatus({ id, status });
  };

  const handleBackToAssignmentDetail = () => {
    setAssignTask(null);
  };

  if (props.assignmentState.error != null) {
    throw new Error(props.assignmentState.error);
  }

  return (
    <div className={classes.container}>
      {!assignTask ? (
        <>
          <Grid container justify="flex-end" style={{ marginBottom: '20px' }}>
            <Button className={classes.yellowBtn} onClick={() => setOpenAdhocTaskModal(true)}>
              Add a Task
            </Button>
          </Grid>
          <Grid container style={{ marginBottom: "2em" }}>
            <Grid item xs={8}>
              <Typography
                component='h1'
                variant='h5'
                style={{ display: "flex", alignItems: "center" }}
                color='secondary'
              >
                <GpsFixed style={{ marginRight: "20px" }} />
                Assignment Task
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Due date</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Status</Typography>
            </Grid>
          </Grid>
          {assignmentEnvelope.taskEnvelopes.map((taskEnvelope) => (
            <TaskDetail
              key={taskEnvelope.task.id}
              taskEnvelope={taskEnvelope}
              handleUpdateTaskStatus={handleUpdateTaskStatus}
              setAssignTask={setAssignTask}
              setDetailedTask={setDetailedTask}
            />
          ))}
          <Drawer anchor='right' open={Boolean(detailedTask)} onClose={() => setDetailedTask(null)}>
            {detailedTask && (
              <TaskDetailSidebar
                taskEnvelope={detailedTask}
                handleClose={() => setDetailedTask(null)}
              />
            )}
          </Drawer>
          {isOpenAdhocTaskModal && (
            <AddhocTaskModal
              assignmentBlueprintId={assignmentEnvelope.assignment.assignmentBlueprintId}
              onClose={() => setOpenAdhocTaskModal(false)}
              onSave={adhocTaskBlueprint => {
                props.createAdhocTaskRest({
                  body: {
                    assignment: assignmentEnvelope.assignment,
                    adhocTaskBlueprint
                  },
                  success: () => {
                    setOpenAdhocTaskModal(false);
                    props.readAssignmentDetail(id);
                  }
                });
              }}
            />
          )}
        </>
      ) : (
        <AssignTask task={assignTask} handleBack={handleBackToAssignmentDetail} />
      )}
    </div>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  assignmentEnvelope: state.assignment.assignmentEnvelope,
  assignmentState: state.assignment
});

const mapDispatchToProps = {
  updateTaskStatus,
  createAdhocTaskRest,
  readAssignmentDetail: readAssignment
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RightPanel);
