import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import React from "react";

import { userIsNotAuthenticatedRedir } from "utils/authHelper";
import LogIn from "./LogIn";
import Admin from "./Admin";
import { GlobalState, User } from "utils/types";

interface Props {
  me: User;
}

function RootApp(props: Props) {
  const renderApp = () => {
    return props.me && props.me.id ? <Admin /> : <Redirect to='/login' />;
  };
  return (
    <Router>
      <Route path='/' render={renderApp} />
      <Route exact path='/login' component={userIsNotAuthenticatedRedir(LogIn)} />
    </Router>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  me: state.user.me
});

export default connect(mapStateToProps)(RootApp);
