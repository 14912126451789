import { Autocomplete } from "@material-ui/lab";
import { TextField, LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { Prop, FieldProSelectionField, FieldPro } from "@fpd-cloud/schemas/core";
import { GlobalState, FieldProState } from "utils/types";
import { readAllFieldProsFromRepo } from "redux/actions/fieldPro";

import * as FieldProList from "domain/repos/FieldProRepo/FieldProList";

interface Props {
  propData: Prop;
  fieldProState: FieldProState;
  readAllFieldProsFromRepo: (request: FieldProList.Request) => void;
  handleFieldProSelectionField: (data: Prop) => void;
  onBlur?: () => void;
}

function FieldProSelectionFieldComponent(props: Props) {
  const [request] = useState<FieldProList.Request>({});
  useEffect(() => {
    props.readAllFieldProsFromRepo(request);
    // eslint-disable-next-line
  }, [request]);

  const data: FieldProSelectionField = props.propData.data as FieldProSelectionField;
  const fieldPros = props.fieldProState.fieldPros as any;
  const fieldProsList = fieldPros?.fieldPros || [];

  if (props.fieldProState.error != null) {
    throw new Error(props.fieldProState.error);
  }

  return props.fieldProState.status === "INIT" ? (
    <LinearProgress />
  ) : (
    <Autocomplete
      options={fieldProsList}
      getOptionLabel={(option: FieldPro) => option.displayName}
      style={{ width: 300 }}
      defaultValue={fieldProsList.find((fieldPro) => fieldPro.id === data.selectionId)}
      onChange={(evt, value) => {
        if (value) {
          const updatedValue = value as FieldPro;
          data.selectionId = updatedValue.id;
          data.selectionName = updatedValue.displayName;
          data.selectionEmail = updatedValue.email;
          props.handleFieldProSelectionField({
            ...props.propData,
            data
          });
        }
      }}
      renderInput={(params) => (
        <TextField
          onBlur={() => {
            if (props.onBlur) props.onBlur();
          }}
          required={true}
          {...params}
          label='Select Field Pro'
          variant='outlined'
        />
      )}
    />
  );
}

const mapStateToProps = (state: GlobalState) => ({
  fieldProState: state.fieldPro
});

const mapDispatchToProps = {
  readAllFieldProsFromRepo
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FieldProSelectionFieldComponent
);
