import React from "react";
import { Card, CardContent, List, ListItem } from "@material-ui/core";
import { FILTER_TYPES } from "utils/costant";

interface Props {
  addFilterSelect: boolean;
  assignmentTypeSelectable: boolean;
  setSelectedFilterName: (name: string) => void;
}

export default function FilterSelect(props: Props) {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, name) => {
    e.stopPropagation();
    props.setSelectedFilterName(name);
  };
  return (
    <Card
      style={{
        position: "absolute",
        zIndex: 999,
        width: "190px",
        marginTop: props.addFilterSelect ? "10px" : "10px",
        marginLeft: "-20px"
      }}
    >
      <CardContent>
        <List>
          {Object.keys(FILTER_TYPES).map((name) => (
            <ListItem
              button
              key={name}
              onClick={(e) => handleClick(e, name)}
              disabled={name === "Assignment Type" && !props.assignmentTypeSelectable}
            >
              {name}
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
