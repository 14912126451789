import firebase from "firebase";
import { TaskBlueprint } from "@fpd-cloud/schemas/core";

const { fromDate } = firebase.firestore.Timestamp;

export const taskBlueprintConverter = {
  toFirestore(ab: TaskBlueprint): firebase.firestore.DocumentData {
    const data: firebase.firestore.DocumentData = { ...ab };
    if (ab.createdAt) data.createdAt = fromDate(new Date(ab.createdAt));
    if (ab.updatedAt) data.updatedAt = fromDate(new Date(ab.updatedAt));
    return data;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): TaskBlueprint {
    const data = snapshot.data(options) as TaskBlueprint;
    return {
      ...data,
      createdAt: data.createdAt || new Date(),
      updatedAt: data.updatedAt || new Date()
    };
  }
};
