import firebase from "firebase";

import * as UserList from "domain/repos/UserRepo/UserList";
import UserRepo from "domain/repos/UserRepo";
import { userConverter } from "../converters";
import { minmax } from "../../../domain/minmax";
import { User } from "@fpd-cloud/schemas/core";

export class FirestoreUserRepo implements UserRepo {
  constructor(private fdb: firebase.firestore.Firestore) {}

  async fetchUsers(request: UserList.Request): Promise<UserList.Response> {
    // 1. "normalize" the request to ensure page, limit and orderBy have a value:
    // -----------------------------------------------------------
    const curr = {
      ...request,
      page: request.page || 1,
      limit: minmax(1, 20, request.limit),
      orderBy: request.orderBy || "+displayName"
    };

    // 2. build a query, based on the normalized request aka: curr
    // -----------------------------------------------------------
    let query = curr.endBefore
      ? this.fdb.collection("users").limitToLast(curr.limit)
      : this.fdb.collection("users").limit(curr.limit);

    // set orderBy first
    if (curr.orderBy === "+id") query = query.orderBy("id", "asc");
    if (curr.orderBy === "-id") query = query.orderBy("id", "desc");
    if (curr.orderBy === "+displayName") query = query.orderBy("displayName", "asc");
    if (curr.orderBy === "-displayName") query = query.orderBy("displayName", "desc");
    if (curr.orderBy === "+email") query = query.orderBy("email", "asc");
    if (curr.orderBy === "-email") query = query.orderBy("email", "desc");

    // then tell it where we want to start
    if (curr.startAfter) query = query.startAfter(curr.startAfter);
    if (curr.endBefore) query = query.endBefore(curr.endBefore);

    // add filters to our query
    if (curr.withEmailEq?.length) {
      query = query.where("email", "==", curr.withEmailEq);
    }

    // 3. run the query / start fetching data
    // -----------------------------------------------------------
    // fetch Users
    const userSnapshots = await query.withConverter(userConverter).get();
    if (userSnapshots.empty) {
      return null;
    }
    const users = await this.buildUsers(userSnapshots);
    const next = await this.buildNext(curr, users);
    return {
      users,
      pages: {
        curr,
        next,
        prev: this.buildPrev(curr, users)
      }
    };
  }

  private buildUsers = async (userSnapshots) => {
    const users = [];
    await Promise.all(
      userSnapshots.docs.map(async (doc) => {
        const userData = doc.data();
        const userIdentitySnapshot = await this.fdb
          .collection("user-identities")
          .limit(2)
          .where("internalUserId", "==", userData.id)
          .get();
        const userIdentity = userIdentitySnapshot.docs.map((userIdentityDoc) => {
          return userIdentityDoc.data();
        });
        users.push({
          ...userData,
          realm: (userIdentity[0] || {}).realmId || ""
        });
        // users.push({ ...userData, realm: "TO DO" });
      })
    );
    return users;
  };

  private buildNext = async (
    curr: UserList.Request,
    users: User[]
  ): Promise<UserList.Request | null> => {
    if (users.length === 0 || users.length < curr.limit) {
      return null;
    }

    const last = users[users.length - 1];
    let startAfter: string | undefined;
    if (curr.orderBy === "+id") startAfter = last.id;
    if (curr.orderBy === "-id") startAfter = last.id;
    if (curr.orderBy === "+displayName") startAfter = last.displayName;
    if (curr.orderBy === "-displayName") startAfter = last.displayName;
    if (curr.orderBy === "+email") startAfter = last.email;
    if (curr.orderBy === "-email") startAfter = last.email;

    const nextRequest = {
      ...curr,
      page: curr.page + 1,
      startAfter,
      endBefore: null
    };

    const nextUsers = await this.fetchUsers(nextRequest);
    if (!nextUsers) return null;
    return nextRequest;
  };

  private buildPrev(curr: UserList.Request, users: User[]): UserList.Request | null {
    if (users.length === 0 || curr.page === 1) {
      return null;
    }

    const first = users[0];
    // eslint-disable-next-line
    let endBefore: string | Date;
    if (curr.orderBy === "+id") endBefore = first.id;
    if (curr.orderBy === "-id") endBefore = first.id;
    if (curr.orderBy === "+displayName") endBefore = first.displayName;
    if (curr.orderBy === "-displayName") endBefore = first.displayName;
    if (curr.orderBy === "+email") endBefore = first.email;
    if (curr.orderBy === "-email") endBefore = first.email;

    return {
      ...curr,
      page: curr.page - 1,
      startAfter: null
    };
  }
}
