import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Theme, makeStyles, Tabs, Tab } from "@material-ui/core";
import TasksTab from "./TasksTab";
import { GlobalState } from "utils/types";
import EscalationTab from "./EscalationTab";
import NotesTab from "./Notes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    backgroundColor: "white",
    padding: theme.spacing(3, 6)
  },
  tabPanel: {
    paddingTop: theme.spacing(2)
  }
}));

export default function RightPanel() {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState("Tasks");
  const [taskTabName, setTaskTabName] = useState("Tasks");
  const assignmentEnvelope = useSelector(
    (state: GlobalState) => state.assignment.assignmentEnvelope
  );
  useEffect(() => {
    if (assignmentEnvelope) {
      const openTasksLength = assignmentEnvelope.taskEnvelopes.filter(
        (envelope) =>
          envelope.task.taskStatus !== "completed" && envelope.task.taskStatus !== "cancelled"
      ).length;
      setTaskTabName(openTasksLength ? `Tasks (${openTasksLength})` : " Tasks");
    }
  }, [assignmentEnvelope]);
  const renderTabContent = (value: string) => {
    if (value === "Tasks") return <TasksTab />;
    else if (value === "Escalations") return <EscalationTab />;
    else return <NotesTab />;
  };
  return (
    <div className={classes.container}>
      <Tabs value={tabValue} onChange={(e, value) => setTabValue(value)} textColor='secondary'>
        <Tab label={taskTabName} value='Tasks' />
        <Tab label='Escalations' value='Escalations' />
        <Tab label='Notes' value='Notes' />
      </Tabs>

      <div className={classes.tabPanel}>{renderTabContent(tabValue)}</div>
    </div>
  );
}
