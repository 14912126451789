import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import firebase from "utils/firebase";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  makeStyles,
  MenuItem,
  Button
} from "@material-ui/core";
import { useAlert } from "react-alert";

import { ClientSnapshot } from "@fpd-cloud/schemas/core";
import { clientDetailSelector, clientSnapshotSelelctor } from "redux/selectors/clientSelector";
import { STATE_LIST } from "utils/costant";
import { updateClient } from "redux/actions/client";
import { saveClientSnapshot, fetchClientSnapshot } from "redux/actions/client";
// Sub containers for additional information for snapshot
import FPDContactInformation from "./FPDContactInformation";
import ClaimVolume from "./ClaimVolume";
import CliamIntake from "./CliamIntake";
import AssignmentInformation from "./AssignmentInformation";
import Reporting from "./Reporting";
import { ClientDetail } from "utils/types";
import { authUserSelector } from "redux/selectors/userSelector";

const { serverTimestamp } = firebase.firestore.FieldValue;

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: 300
  }
}));

export default function Snapshot() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();
  const clientDetail = useSelector(clientDetailSelector);
  const snapshot = useSelector(clientSnapshotSelelctor);
  const [clientSnapshot, setClientSnapshot] = useState<ClientSnapshot | null>(null);
  const [selectedClient, setSelectedClient] = useState<ClientDetail>(clientDetail);
  const authUser = useSelector(authUserSelector);
  useEffect(() => {
    if (snapshot) {
      setClientSnapshot({ ...snapshot });
    }
    // eslint-disable-next-line
  }, [snapshot]);
  useEffect(() => {
    if (clientDetail) {
      setSelectedClient(clientDetail);
      dispatch(
        fetchClientSnapshot({
          clientId: clientDetail.id
        })
      );
    }
    // eslint-disable-next-line
  }, [clientDetail]);

  if (!clientDetail) return;

  const handleSaveClient = () => {
    const { assignments, ...data } = selectedClient;
    dispatch(
      updateClient({
        data
      })
    );
  };
  const handleSubmit = (e) => {
    const snapCreatedAt =
      !clientSnapshot || clientSnapshot.createdAt === undefined
        ? serverTimestamp()
        : clientSnapshot.createdAt;
    const snapCreatedBy =
      !clientSnapshot || clientSnapshot.createdBy === undefined
        ? authUser.id
        : clientSnapshot.createdBy;
    e.preventDefault();
    handleSaveClient();
    dispatch(
      saveClientSnapshot({
        data: {
          ...clientSnapshot,
          clientId: clientDetail.id,
          createdAt: snapCreatedAt,
          createdBy: snapCreatedBy,
          id: clientDetail.id,
          updatedAt: serverTimestamp(),
          updatedBy: authUser.id
        },
        successCB: () => {
          alert.success("Client detail is updated successfully!", { timeout: 5000 }); // Could we add a message if there was an error? Currently if this fails, the user would not know.
          dispatch(
            fetchClientSnapshot({
              clientId: clientDetail.id
            })
          );
        }
      })
    );
  };
  const handleUpdateSnapshotData = (fieldKey: string, data: any) => {
    const newSnapshotData = clientSnapshot || {};
    newSnapshotData[fieldKey] = data;
    setClientSnapshot({ ...(newSnapshotData as any) });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} style={{ marginTop: 16 }}>
        <Grid item xs={6}>
          <TextField
            label='Client Name'
            placeholder='Client Name'
            value={selectedClient?.name || ""}
            variant='outlined'
            required
            fullWidth
            onChange={(e) => {
              setSelectedClient({
                ...selectedClient,
                name: e.target.value
              });
            }}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <TextField
            label='Street Address 1'
            variant='outlined'
            placeholder='Street Address 1'
            required
            value={selectedClient.primaryAddress.addressLine1}
            fullWidth
            onChange={(e) => {
              setSelectedClient({
                ...selectedClient,
                primaryAddress: {
                  ...selectedClient.primaryAddress,
                  addressLine1: e.target.value
                }
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label='Street Address 2'
            placeholder='Street Address 2'
            variant='outlined'
            value={selectedClient.primaryAddress.addressLine2}
            fullWidth
            onChange={(e) => {
              setSelectedClient({
                ...selectedClient,
                primaryAddress: {
                  ...selectedClient.primaryAddress,
                  addressLine2: e.target.value
                }
              });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label='City'
            placeholder='City'
            variant='outlined'
            required
            value={selectedClient.primaryAddress.addressCity}
            fullWidth
            onChange={(e) => {
              setSelectedClient({
                ...selectedClient,
                primaryAddress: {
                  ...selectedClient.primaryAddress,
                  addressCity: e.target.value
                }
              });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id='state-select-label' style={{ marginLeft: 14, marginTop: -4 }}>
              State
            </InputLabel>
            <Select
              variant='outlined'
              labelId='state-select-label'
              value={selectedClient.primaryAddress.addressState}
              fullWidth
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              onChange={(e) => {
                setSelectedClient({
                  ...selectedClient,
                  primaryAddress: {
                    ...selectedClient.primaryAddress,
                    addressState: e.target.value as string
                  }
                });
              }}
            >
              <MenuItem value='' />
              {STATE_LIST.map((usState) => (
                <MenuItem key={usState} value={usState}>
                  {usState}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label='Postal / Zip Code'
            placeholder='Postal / Zip Code'
            variant='outlined'
            required
            value={selectedClient.primaryAddress.addressZip}
            fullWidth
            onChange={(e) => {
              setSelectedClient({
                ...selectedClient,
                primaryAddress: {
                  ...selectedClient.primaryAddress,
                  addressZip: e.target.value
                }
              });
            }}
          />
        </Grid>
        <FPDContactInformation
          clientSnapshot={clientSnapshot}
          handleUpdateSnapshotData={handleUpdateSnapshotData}
        />
        <ClaimVolume
          clientSnapshot={clientSnapshot}
          handleUpdateSnapshotData={handleUpdateSnapshotData}
        />
        <CliamIntake
          clientSnapshot={clientSnapshot}
          handleUpdateSnapshotData={handleUpdateSnapshotData}
        />
        <AssignmentInformation
          clientSnapshot={clientSnapshot}
          handleUpdateSnapshotData={handleUpdateSnapshotData}
        />
        <Reporting
          clientSnapshot={clientSnapshot}
          handleUpdateSnapshotData={handleUpdateSnapshotData}
        />
        <Grid item xs={12} style={{ textAlign: "center", marginTop: 20 }}>
          <Button type='submit' variant='contained' color='secondary' size='large'>
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
