import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import { useAlert } from "react-alert";
import { Autocomplete } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

import { TeamMember, User } from "@fpd-cloud/schemas/core";
import { TeamDetail, GlobalState } from "utils/types";
import { readTeamMembers, addTeamMembers } from "redux/actions/team";

interface Props {
  teamList: TeamDetail[];
  memberList: TeamMember[];
  userList: User[];
  team: TeamDetail;
  searchName: string;
  onClose: () => void;
  readTeamMembers: (data: any) => void;
  addTeamMembers: (data: any) => void;
  setSearchName: (name: string) => void;
}


const useStyles = makeStyles((theme: Theme) => ({
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "600px"
  }
}));

function AddTeamMembers(props: Props) {
  const { onClose, team, userList, memberList } = props;
  const [selectedTeam, setSelectedTeam] = useState<TeamDetail>(team);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const alert = useAlert();
  const classes = useStyles();

  useEffect(() => {
    props.readTeamMembers({ teamId: selectedTeam.team.id });
    // eslint-disable-next-line
  }, [selectedTeam]);

  useEffect(() => {
    setSelectedUsers(
      userList.filter((user) => memberList.findIndex((member) => member.id === user.id) > -1)
    );
    // eslint-disable-next-line
  }, [memberList]);

  const handleAddTeamMembers = () => {
    const members: TeamMember[] = selectedUsers.map((user) => ({
      id: user.id,
      teamId: selectedTeam.team.id,
      userName: user.displayName,
      userEmail: user.email,
      createdAt: new Date(),
      updatedAt: new Date()
    }));

    props.addTeamMembers({
      teamId: selectedTeam.team.id,
      members,
      successCB: () => {
        alert.success("Add team members successfully!", {
          timeout: 5000
        });
        props.onClose();
      }
    });
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        <div className={classes.titleWrapper}>
          Add Member
          <Close style={{ cursor: "pointer" }} onClick={onClose} />
        </div>
      </DialogTitle>
      <DialogContent
        dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{selectedTeam.team.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={selectedUsers}
              options={userList}
              getOptionLabel={(option) => `${option.email} \n-\n (${option.displayName})`}
              renderInput={(params) => (
                <TextField {...params} label='Search User By Email' variant='outlined' />
              )}
              fullWidth
              multiple
              onChange={(e, newValue) => {
                setSelectedUsers(newValue as User[]);
              }}
              onInputChange={(e, str) => {
                props.setSearchName(str);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button color='secondary' variant='contained' onClick={handleAddTeamMembers}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  teamList: state.team.teamListResponse.teamsList,
  memberList: state.team.memberList,
  userList: state.user.users
});

const mapDispatchToProps = {
  readTeamMembers,
  addTeamMembers
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddTeamMembers);
