import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  CircularProgress,
  Theme,
  Collapse,
  Box,
  Grid
} from "@material-ui/core";
import { PersonAddOutlined, HighlightOff } from "@material-ui/icons";
import moment from "moment";
import { useAlert } from "react-alert";

import Layout from "components/layout";
import { readAllTeamList, removeTeamMember } from "redux/actions/team";
import { readUsersList } from "redux/actions/user";
import { GlobalState, TeamState, TeamDetail, UserState } from "utils/types";
import * as TeamList from "domain/repos/TeamRepo/TeamList";
import AddTeamMembers from "components/modals/AddTeamMembers";
import ConfirmDialog from "components/modals/ConfirmDialog";
import { TeamMember } from "@fpd-cloud/schemas/core";
import TablePaginationComponent from "components/common/table-pagination";

interface Props {
  teamState: TeamState;
  userState: UserState;
  readAllTeamList: (request: any) => void;
  readUsersList: (data?: any) => void;
  removeTeamMember: (data: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  topBar: {
    minHeight: "110px",
    marginBottom: theme.spacing(2),
    background: "#ffffff",
    padding: theme.spacing(3, 8),
    display: "flex",
    justifyContent: "space-between"
  },
  tableContainer: {
    backgroundColor: "#E5E5E5",
    padding: theme.spacing(2, 8, 4, 8),
    "& th": {
      color: "#01788B",
      fontWeight: "bold"
    }
  },
  action: {
    marginLeft: "5px",
    cursor: "pointer"
  },
  circle: {
    minWidth: 16,
    minHeight: 16,
    borderRadius: "50%",
    border: "1px solid rgba(0, 0, 0, 0.54)",
    margin: "16px 16px 16px 0"
  },
  close: {
    visibility: "hidden",
    cursor: "pointer"
  },
  memberWrapper: {
    "&:hover": {
      background: "#F1FDFF"
    },
    "&:hover $close": {
      visibility: "visible"
    }
  },
  searchInput: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    height: "40px",
    backgroundImage: "url('../../../assets/images/search-icon.svg')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "15px 12px",
    padding: "10px 40px",
    borderRadius: "4px",
    marginRight: "20px",
    minWidth: 295
  }
}));

function TeamsList(props: Props) {
  const classes = useStyles();
  const alert = useAlert();
  const [searchName, setSearchName] = useState("");
  const [selectedTeam, setSelectedTeam] = useState<TeamDetail>(null);
  const [openedTeam, setOpenedTeam] = useState<TeamDetail>(null);
  const [deleteMember, setDeleteMember] = useState<TeamMember>(null);
  const userRole = props.userState.me.permissions.find(permission => permission.includes("role"))


  const [request, setRequest] = useState<TeamList.Request>({
    page: 1,
    limit: 10
  });

  useEffect(() => {
    if (userRole === "admin-app:role-admin") props.readAllTeamList(request);
    // eslint-disable-next-line
  }, [request]);

  // useEffect(() => {
  //   props.readUsersList();
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    props.readUsersList({ name: searchName });
    // eslint-disable-next-line
  }, [searchName]);

  const renderTable = (teamsList?: TeamDetail[]) => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Data Created</TableCell>
            <TableCell>No.of members</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        {userRole !== "admin-app:role-admin" ?
      (<TableBody style={{ backgroundColor: "white" }}>
        <TableRow>
          <TableCell colSpan={10} data-testid='no-assignment' style={{ textAlign: "center" }}>
            You do not have permission to view this content. If you feel this is in error, please contact an Administrator.
          </TableCell>
        </TableRow>
        </TableBody>)
          :
          !teamsList || props.teamState.status === "INIT" ? (
          <TableBody style={{ backgroundColor: "white" }}>
            <TableRow>
              <TableCell colSpan={5} style={{ textAlign: "center" }}>
                <CircularProgress color='primary' />
              </TableCell>
            </TableRow>
          </TableBody>
          ) : teamsList.length === 0 ?
            (<TableBody style={{ backgroundColor: "white" }}>
            <TableRow>
              <TableCell colSpan={5} style={{ textAlign: "center" }}>
                No teams found
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody style={{ backgroundColor: "white" }}>
            {teamsList.map((team: TeamDetail) => {
              let createdAt = team.team.createdAt;
              if (typeof team.team.createdAt.getMonth !== "function")
                createdAt = ((team.team.createdAt as any) as firebase.firestore.Timestamp).toDate();
              return (
                <React.Fragment key={team.team.id}>
                  <TableRow
                    hover
                    key={team.team.id}
                    onClick={() => {
                      if (openedTeam && openedTeam.team.id === team.team.id) setOpenedTeam(null);
                      else setOpenedTeam(team);
                    }}
                    data-testid='team-row'
                  >
                    <TableCell>{team.team.name}</TableCell>
                    <TableCell>{moment(createdAt).format("ll")}</TableCell>
                    <TableCell>{team.teamMembers?.length || 0}</TableCell>
                    <TableCell>
                      <PersonAddOutlined
                        className={classes.action}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedTeam(team);
                        }}
                      />
                      {/* <Create className={classes.action} />
                      <DeleteOutline className={classes.action} /> */}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                      <Collapse
                        in={openedTeam && openedTeam.team.id === team.team.id}
                        timeout='auto'
                        unmountOnExit
                      >
                        {team.teamMembers && team.teamMembers.length > 0 && (
                          <Box margin={1}>
                            <Typography variant='h6'>Team Members</Typography>
                            <Grid
                              container
                              spacing={4}
                              style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                            >
                              {team.teamMembers.map((member) => (
                                <Grid item xs={3} className={classes.memberWrapper} key={member.id}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: "#43464B"
                                    }}
                                  >
                                    <div className={classes.circle} />
                                    <div>
                                      <Typography>{member.userName}</Typography>
                                      <Typography style={{ fontSize: 14 }}>
                                        {member.userEmail}
                                      </Typography>
                                    </div>
                                    <div style={{ flex: 1 }} />
                                    <HighlightOff
                                      className={classes.close}
                                      onClick={() => setDeleteMember(member)}
                                    />
                                  </div>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        )}
      </Table>
    );
  };

  return (
    <Layout page="teams">
      {selectedTeam && (
        <AddTeamMembers
          team={selectedTeam}
          searchName={searchName}
          setSearchName={setSearchName}
          onClose={() => {
            setSelectedTeam(null);
            props.readAllTeamList(request);
          }}
        />
      )}
      {deleteMember && (
        <ConfirmDialog
          content={`Are you sure to remove user from ${openedTeam.team.name}?`}
          onSuccess={() => {
            props.removeTeamMember({
              teamId: openedTeam.team.id,
              memberId: deleteMember.id,
              successCB: () => {
                props.readAllTeamList(request);
                alert.success(`${deleteMember.userName} is removed from ${openedTeam.team.name}`, {
                  timeout: 5000
                });
              }
            });
            setDeleteMember(null);
          }}
          onCancel={() => setDeleteMember(null)}
        />
      )}
      <div className={classes.topBar}>
        <Typography variant='h5'>Teams List Page</Typography>
        <input
          placeholder='Search for teams by team name'
          className={classes.searchInput}
          onChange={(evt) => {
            setRequest({
              ...request,
              withNamePrefix: `${evt.target.value as string}`
            });
          }}
        />
      </div>
      <div className={classes.tableContainer}>
        {renderTable(props.teamState.teamListResponse?.teamsList)}
        {props.teamState && props.teamState.teamListResponse && (
          <TablePaginationComponent
            setRequest={setRequest}
            response={props.teamState.teamListResponse}
          />
        )}
      </div>
    </Layout>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  teamState: state.team,
  userState: state.user
});

const mapDispatchToProps = {
  readAllTeamList,
  readUsersList,
  removeTeamMember
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TeamsList);
