import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Checkbox,
  RadioGroup,
  FormGroup
} from "@material-ui/core";
import { useSelector } from "react-redux";
import React from "react";

import { Prop, SelectionField } from "@fpd-cloud/schemas/core";
import { userPermissionSelector } from "redux/selectors/userSelector";
import MarkdownProp from "./markdown-prop";

export const getSelectedValue = (data: SelectionField) => {
  // Handling AllowMultipleSelections
  if (!data.allowMultipleSelections) {
    let selectedValue = "";
    data.choices.forEach((choice) => {
      if (choice.selected === true) selectedValue = choice.value;
    });
    return selectedValue;
  } else if (data.allowMultipleSelections) {
    const selectedValues = [];
    data.choices.forEach((choice) => {
      if (choice.selected === true) selectedValues.push(choice.value);
    });
    return selectedValues;
  }
};

interface Props {
  propData: Prop;
  handleSelectionField: (data: Prop) => void;
  onBlur?: () => void;
}

export default function SelectionFieldComponent(props: Props) {
  const permissions = useSelector(userPermissionSelector);

  const data: SelectionField = props.propData.data as SelectionField;
  const constraints = data.constraints;
  let readOnly = false;
  if (constraints?.choices.mutableIf) {
    const pIndex = permissions.findIndex((permission) => {
      return permission === constraints.choices.mutableIf;
    });

    readOnly = pIndex === -1;
  }

  const handleRadioChange = (event: React.ChangeEvent<any>, index: number) => {
    if (readOnly) return;
    data.choices.forEach((choice, i) => {
      if (i === index) data.choices[i].selected = true;
      else data.choices[i].selected = false;
    });
    data.choices[index].selected = true;
    props.handleSelectionField({
      ...props.propData,
      data
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<any>, index: number) => {
    if (readOnly) return;
    data.choices[index].selected = event.target.checked;
    props.handleSelectionField({
      ...props.propData,
      data
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (readOnly) return;
    const selectedValue = event.target.value as string;
    const selectedValue1 = event.target.value as string[];

    if (!data.allowMultipleSelections) {
      data.choices.forEach((choice, i) => {
        if (choice.value === selectedValue) data.choices[i].selected = true;
        else data.choices[i].selected = false;
      });
    } else {
      data.choices.forEach((choice, i) => {
        if (selectedValue1.findIndex((val) => val === choice.value) > -1)
          data.choices[i].selected = true;
        else data.choices[i].selected = false;
      });
    }
    props.handleSelectionField({
      ...props.propData,
      data
    });
  };

  return (
    <Grid container spacing={2} style={{ margin: 0 }}>
      <Grid item xs={12}>
        <FormControl required={constraints?.choices.isRequired} component='fieldset' fullWidth>
          {data.selectionLabel && (
            <FormLabel id='selectionLabel' component='legend'>
              {data.selectionLabel}
            </FormLabel>
          )}
          {data.selectionProlog && <MarkdownProp markdown={data.selectionProlog} />}
          {data.preferredLayout === "radio-buttons" && (
            <RadioGroup
              name={data.selectionLabel}
              value={getSelectedValue(data)}
              onBlur={() => {
                if (props.onBlur) props.onBlur();
              }}
            >
              {data.choices.map((choice, index) => (
                <FormControlLabel
                  key={choice.value}
                  value={choice.value}
                  control={
                    <Radio
                      required={constraints?.choices.isRequired}
                      inputProps={{ "aria-label": choice.value }}
                    />
                  }
                  label={choice.value}
                  onChange={(e) => handleRadioChange(e, index)}
                />
              ))}
            </RadioGroup>
          )}
          {data.preferredLayout === "checkboxes" && (
            <FormGroup
              style={{ width: "100%" }}
              onBlur={() => {
                if (props.onBlur) props.onBlur();
              }}
              row
            >
              {data.choices.map((choice, index) => (
                <FormControlLabel
                  style={{ width: "30%" }}
                  key={choice.value}
                  value={choice.value}
                  checked={choice.selected}
                  control={<Checkbox />}
                  label={choice.value}
                  onChange={(e) => handleCheckboxChange(e, index)}
                />
              ))}
            </FormGroup>
          )}
          {data.preferredLayout === "dropdown-menu" && (
            <Select
              multiple={data.allowMultipleSelections}
              value={getSelectedValue(data)}
              labelId='selectionLabel'
              onChange={(e) => handleSelectChange(e)}
              onBlur={() => {
                if (props.onBlur) props.onBlur();
              }}
            >
              {data.choices.map((choice) => (
                <MenuItem key={choice.value} value={choice.value}>
                  {choice.value}
                </MenuItem>
              ))}
            </Select>
          )}
          {data.selectionEpilog && <MarkdownProp markdown={data.selectionEpilog} />}
        </FormControl>
      </Grid>
    </Grid>
  );
}
