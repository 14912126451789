import React from "react";
import { useAlert } from "react-alert";
import { connect } from "react-redux";
import { compose } from "redux";
import { useHistory } from "react-router-dom";
import { Grid, makeStyles, Button, Typography, Theme } from "@material-ui/core";

import firebase from "utils/firebase";

import { getUser, signUpUser, createUserIdentity } from "../../redux/actions/user";
import { GlobalState, User } from "utils/types";

const { serverTimestamp } = firebase.firestore.FieldValue;

const useStyles = makeStyles((theme: Theme) => ({
  left: {
    backgroundImage: "url('../assets/images/home_page_img.png')",
    width: "100%",
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  normalMargin: {
    margin: theme.spacing(3, 0)
  },
  login: {
    margin: theme.spacing(3, 0),
    border: "1px solid rgba(0, 0, 0, 0.05)",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
    background: "#FFFFFF",
    borderRadius: "3px",
    padding: theme.spacing(1, 1.5),
    cursor: "pointer"
  }
}));

interface Props {
  me: User;
  getUser: (data: any) => void;
  signUpUser: (data: any) => void;
  createUserIdentity: (data: any) => void;
}

function LogIn(props: Props) {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();

  const googleProvider = new firebase.auth.GoogleAuthProvider();

  const addNewUser = (user: any) => {
    const data = {
      createdAt: serverTimestamp(),
      createdBy: user.uid,
      displayName: user.displayName,
      email: user.email,
      id: user.uid,
      permissions: [],
      phone: user.phoneNumber || "",
      updatedAt: serverTimestamp(),
      updatedBy: user.uid,
      lastSignInAt: serverTimestamp()
    };
    const userIdentity = {
      realmId: "fpd-cloud",
      internalUserId: user.uid,
      externalUserId: user.uid
    };
    props.signUpUser({
      ...data,
      successCB: () => {
        alert.success(`${user.displayName} signed successfully`, {
          timeout: 5000
        });
        history.push("/");
        props.createUserIdentity(userIdentity);
      }
    });
    return data;
  };

  const googleSignIn = () => {
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then(async (result: any) => {
        try {
          const gsuiteIndex = result.user.email.indexOf("fieldprosdirect");
          const atIndex = result.user.email.indexOf("@");
          if (gsuiteIndex > -1 && atIndex > -1 && gsuiteIndex > atIndex) {
            const userRefQuery = await firebase
              .firestore()
              .collection("users")
              .doc(`${result.user.uid}`);
            const userRef = await userRefQuery.get();
            let userData = userRef.data();
            if (!userData) {
              userData = addNewUser(result.user);
            } else {
              props.getUser({
                uid: userData.id,
                successCB: async () => {
                  const userDataWithSignIn = {
                    ...userData,
                    lastSignInAt: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                    updatedBy: userData.id
                  };
                  await userRefQuery.set(userDataWithSignIn, { merge: true });
                  history.push("/");
                }
              });
            }
          } else {
            alert.error("You don't have the access to the admin portal", {
              timeout: 5000
            });
            return;
          }
        } catch (e) {
          alert.error(e.message, {
            timeout: 5000
          });
          return;
        }
      });
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8} className={classes.left} />
      <Grid item xs={12} md={4}>
        <Grid container alignItems='center' style={{ height: "100%", textAlign: "center" }}>
          <div style={{ width: "100%" }}>
            <img
              className={classes.normalMargin}
              src='../assets/images/fpd_logo_1.svg'
              alt='Background'
            />
            <Typography variant='h6'>Welcome to Field Pros Direct Admin Portal</Typography>
            <Typography variant='h6'>For Authroized User Only</Typography>
            <Button className={classes.login} onClick={googleSignIn}>
              <img src='../assets/images/google_logo.svg' alt='Company Logo' />
              <p style={{ color: "#019EB8" }}>SIGN IN WITH GOOGLE</p>
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state: GlobalState) => ({});
const mapDispatchToProps = {
  getUser,
  signUpUser,
  createUserIdentity
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(LogIn);
