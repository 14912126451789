import { put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

import { db } from "utils/firebase";
import {
  READ_SUPERVISORS,
  READ_TEAM_LIST,
  READ_ALL_TEAM_LIST,
  READ_TEAM_MEMBERS,
  ADD_TEAM_MEMBERS,
  REMOVE_TEAM_MEMBER
} from "../actions/team";
import { FirestoreTeamMemberRepo } from "data-access/firestore/repos/FirestoreTeamMemberRepo";
import { FirestoreTeamRepo } from "data-access/firestore/repos/FirestoreTeamRepo";
import TeamMemberRepo from "domain/repos/TeamMemberRepo";
import TeamListRepo from "domain/repos/TeamRepo";
import { TeamMember } from "@fpd-cloud/schemas/core";
import { apiSuccess, apiError } from "redux/makeRequest";

let teamMemberRepo: TeamMemberRepo;
teamMemberRepo = new FirestoreTeamMemberRepo(db);
let teamListRepo: TeamListRepo;
teamListRepo = new FirestoreTeamRepo(db);

function* doReadSupervisorListSaga(action: AnyAction) {
  try {
    const response = yield teamMemberRepo.fetchSupervisorList({});
    yield put({
      type: apiSuccess(action.type),
      payload: response ? response.supervisorList : []
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doReadTeamListSaga(action: AnyAction) {
  try {
    const response = yield teamMemberRepo.fetchTeamList({
      ...action.payload
    });
    yield put({
      type: apiSuccess(action.type),
      payload: response.teamList
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doReadAllTeamList(action: AnyAction) {
  try {
    const response = yield teamListRepo.fetchTeamsList(action.payload || {});
    yield put({
      type: apiSuccess(action.type),
      payload: response
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doAddTeamMembers(action: AnyAction) {
  try {
    const { successCB, teamId, members } = action.payload;
    const teamMemberRef = yield db.collection("teams").doc(teamId).collection("team-members");
    members.forEach(async (member) => {
      await teamMemberRef.doc(member.id).set(member);
    });
    if (successCB) successCB();
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doReadTeamMembers(action: AnyAction) {
  try {
    const { teamId } = action.payload;
    const snapshots = yield db.collection("teams").doc(teamId).collection("team-members").get();
    const memberList: TeamMember[] = [];
    snapshots.forEach((snapshot) => {
      memberList.push(snapshot.data());
    });
    yield put({
      type: apiSuccess(action.type),
      payload: memberList
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doRemoveTeamMember(action: AnyAction) {
  try {
    const { successCB, teamId, memberId } = action.payload;
    yield db.collection("teams").doc(teamId).collection("team-members").doc(memberId).delete();
    if (successCB) successCB();
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

export default function* teamSaga() {
  yield takeLatest(READ_SUPERVISORS, doReadSupervisorListSaga);
  yield takeLatest(READ_TEAM_LIST, doReadTeamListSaga);
  yield takeLatest(READ_ALL_TEAM_LIST, doReadAllTeamList);
  yield takeLatest(ADD_TEAM_MEMBERS, doAddTeamMembers);
  yield takeLatest(READ_TEAM_MEMBERS, doReadTeamMembers);
  yield takeLatest(REMOVE_TEAM_MEMBER, doRemoveTeamMember);
}
