import React, { useState, useEffect } from "react";
import firebase from "utils/firebase";
import Dropzone, { IFileWithMeta, ILayoutProps } from "react-dropzone-uploader";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  makeStyles,
  Typography,
  TextField,
  NativeSelect,
  Button,
  CircularProgress
} from "@material-ui/core";
import { BackupOutlined, ArrowBack } from "@material-ui/icons";
import { saveClientFile } from "redux/actions/client";
import { clientDetailSelector } from "redux/selectors/clientSelector";
import { authUserSelector } from "redux/selectors/userSelector";
import { getEnvironmentValue } from "utils/helper";
import { v4 as uuidv4 } from "uuid";

const { serverTimestamp } = firebase.firestore.FieldValue;

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    border: "1px dashed #019EB8",
    borderRadius: 4,
    padding: "30px",
    textAlign: "center"
  },
  icon: {
    fontSize: 128,
    color: "rgba(0,0,0,0.1)"
  },
  browser: {
    background: "#019EB8",
    width: "180px",
    minHeight: "60px",
    color: "white",
    margin: "0 auto",
    padding: "20px 15px",
    borderRadius: 4,
    cursor: "pointer"
  },
  form: {
    display: "flex",
    minWidth: 400,
    width: 400,
    margin: "0 auto",
    flexDirection: "column",
    justifyContent: "center"
  },
  progress: {
    position: "absolute"
  }
}));

interface Props {
  isFirstDocument: boolean;
  fetchUploadedDocuments: () => void;
  finishUploadingDocument: () => void;
}

const CATEGORIES_LIST = ["Contracts", "Billing Information", "Guidelines", "Other"];

interface SubmitParams {
  file: IFileWithMeta;
  fileName: string;
  category: string;
  setFileLoading: (loading: boolean) => void;
}

function FileUpload({ isFirstDocument, fetchUploadedDocuments, finishUploadingDocument }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();
  const clientDetail = useSelector(clientDetailSelector);
  const authUser = useSelector(authUserSelector);

  const fileTypes = [
    // 'application/xls',
    // 'application/pdf',
    // 'text/csv',
    // 'application/msword',
    // 'application/vnd.ms-word.document.macroEnabled.12',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/vnd.ms-excel',
    // 'application/vnd.ms-excel.sheet.macroEnabled.12',
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ".pdf",
    ".doc",
    ".csv",
    ".xls",
    ".docx",
    ".xlsx"
  ];

  const handleSubmit = ({ file, fileName, category, setFileLoading }: SubmitParams) => {
    const STOREATE_REF_PATH = `clients/${clientDetail.name}/${getEnvironmentValue()}/documents/`;
    const newFileDocumentId = uuidv4();
    const storageRef = firebase.storage().ref(STOREATE_REF_PATH + newFileDocumentId);
    const uploadTask = storageRef.put(file.file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (progress === 0) setFileLoading(true);
        if (progress === 100) setFileLoading(false);
      },
      (error) => {
        alert.error(error || "Document upload failed", { timeout: 5000 });
        return;
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          const clientFileDetail = {
            clientId: clientDetail.id,
            clientName: clientDetail.name,
            documentCategory: category,
            documentName: file.meta.name,
            documentType: file.meta.type,
            fileName,
            filePath: downloadURL,
            status: "completed-okay",
            uploadedBy: authUser.id,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            createdBy: authUser.id,
            updatedBy: authUser.id,
            id: newFileDocumentId
          };
          dispatch(
            saveClientFile({
              clientFileDetail,
              successCB: () => {
                alert.success("Document is uploaded successfully!", { timeout: 5000 });
                fetchUploadedDocuments();
                finishUploadingDocument();
              }
            })
          );
        });
      }
    );
  };

  const Layout = ({ input, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
    const file = files[0];
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [category, setCategory] = useState("Contracts");
    const [isFileLoading, setFileLoading] = useState(false);

    useEffect(() => {
      if (file && !isFileLoading) {
        const dIndex = file.meta.name.lastIndexOf(".");
        setFileType(file.meta.name.slice(dIndex + 1));
        setFileName(file.meta.name.slice(0, dIndex));
      }
    }, [file, isFileLoading]);
    return (
      <div>
        <div {...dropzoneProps}>
          <BackupOutlined className={classes.icon} />
          {files.length < maxFiles && !isFileLoading ? (
            <>
              <Typography style={{ color: "#2B323A" }}>
                {"Drag & Drop files to upload documents"}
              </Typography>
              <Typography style={{ margin: "20px 0", color: "#2B323A" }}>or</Typography>
              {input}
              <Typography style={{ color: "rgba(43, 50, 58, 0.54)", marginTop: 10 }}>
                Only .pdf, .doc, .docx, .xls, and .xlsx files can be uploaded
              </Typography>
            </>
          ) : (
            <div className={classes.form}>
              <TextField
                label='File Name (Optional)'
                placeholder='File Name'
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                fullWidth
                style={{ marginBottom: 20 }}
                disabled={isFileLoading}
              />
              <NativeSelect
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                disabled={isFileLoading}
              >
                {CATEGORIES_LIST.map((c) => (
                  <option key={c} value={c}>
                    {c}
                  </option>
                ))}
              </NativeSelect>
              <Button
                color='secondary'
                variant='contained'
                onClick={() =>
                  handleSubmit({
                    file,
                    fileName: `${fileName}.${fileType}`,
                    category,
                    setFileLoading
                  })
                }
                style={{ padding: "10px 0", marginTop: 20 }}
                disabled={isFileLoading}
              >
                {isFileLoading ? <CircularProgress /> : "Submit"}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      {!isFirstDocument && (
        <Button startIcon={<ArrowBack />} color='primary' onClick={finishUploadingDocument}>
          Back to list
        </Button>
      )}
      <div className={classes.container}>
        <Dropzone
          accept={fileTypes.join(",")}
          maxFiles={1}
          multiple={false}
          canCancel={false}
          inputContent={
            <div key='dropzone-preview'>
              <div className={classes.browser}>Browse Documents</div>
            </div>
          }
          LayoutComponent={Layout}
        />
      </div>
    </div>
  );
}

export default FileUpload;
