import { createAction } from "redux-actions";

export const READ_CLIENTS_REPO = "READ_CLIENTS_REPO";
export const READ_CLIENTS = "READ_CLIENTS";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const GET_CLIENT = "GET_CLIENT";
export const GET_CLIENT_FILES = "GET_CLIENT_FILES";
export const SAVE_CLIENT_FILE = "SAVE_CLIENT_FILE";
export const UPDATE_CLIENT_FILE_STATUS = "UPDATE_CLIENT_FILE_STATUS";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const SAVE_CLIENT_SNAPSHOT = "SAVE_CLIENT_SNAPSHOT";
export const FETCH_CLIENT_SNAPSHOT = "FETCH_CLIENT_SNAPSHOT";

export const readAllClientsFromRepo = createAction(READ_CLIENTS_REPO);
export const readAllClients = createAction(READ_CLIENTS);
export const createClient = createAction(CREATE_CLIENT);
export const getClient = createAction(GET_CLIENT);
export const getClientFilesByClientId = createAction(GET_CLIENT_FILES);
export const saveClientFile = createAction(SAVE_CLIENT_FILE);
export const updateClientFileStatus = createAction(UPDATE_CLIENT_FILE_STATUS);
export const updateClient = createAction(UPDATE_CLIENT);
export const saveClientSnapshot = createAction(SAVE_CLIENT_SNAPSHOT);
export const fetchClientSnapshot = createAction(FETCH_CLIENT_SNAPSHOT);
