export const AllPropGroups = [
  {
    path: "customer-details",
    label: "Customer Details"
  },
  {
    path: "customer-address",
    label: "Customer Address"
  },
  {
    path: "policy-details",
    label: "Policy Details"
  },
  {
    path: "examiner-details",
    label: "Examiner Details"
  },
  {
    path: "insured-details",
    label: "Insured Details"
  },
  {
    path: "insured-address",
    label: "Insured Address"
  },
  {
    path: "claimant-details",
    label: "Claimant Details"
  },
  {
    path: "claimant-address",
    label: "Claimant Address"
  },
  {
    path: "assignment-details",
    label: "Assignment Details"
  },
  {
    path: "assignment-address",
    label: "Address (If different from Insured address)"
  },
  {
    path: "coverage",
    label: "Coverage"
  }
];

export const STATE_LIST = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

export const statusFilterValues = [
  "unassigned",
  "assigned",
  "started",
  "completed",
  "cancelled",
  "obstructed"
];

export const FILTER_TYPES = {
  "Client Name": "client",
  "Assignment Type": "type",
  "Assignment Status": "assignment_status",
  "Supervisor Name": "supervisor"
};

export const assignmentStatus = ["open", "closed", "cancelled", "reopened"];

export const subTaskStatusValues = ["new", "started", "completed", "cancelled", "obstructed"];
