import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, makeStyles, Theme, CircularProgress } from "@material-ui/core";

import Layout from "components/layout";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import { getClient } from "redux/actions/client";
import { clientDetailSelector } from "redux/selectors/clientSelector";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexDirection: "row",
    width: "100%",
    minHeight: "calc(100vh - 60px)"
  },
  right: {
    minHeight: "calc(100vh - 60px)",
    padding: theme.spacing(2),
    width: "100%",
    flexDirection: "row",
    backgroundColor: "#E5E5E5"
  },
  left: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px 0px 0px 4px",
    flexDirection: "column",
    display: "flex",
    width: "100%",
    position: "relative"
  }
}));

function ClientDetail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const clientDetail = useSelector(clientDetailSelector);

  useEffect(() => {
    if (id) {
      dispatch(getClient(id));
    }
  }, [dispatch, id]);

  if (!clientDetail) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <Layout page="clients">
      <Grid container className={classes.container}>
        <Grid item xs={3} className={classes.left}>
          <LeftPanel />
        </Grid>
        <Grid item xs={9} className={classes.right}>
          <RightPanel />
        </Grid>
      </Grid>
    </Layout>
  );
}

export default ClientDetail;
