import firebase from "firebase";
import { User } from "@fpd-cloud/schemas/core";
const { fromDate } = firebase.firestore.Timestamp;

export const userConverter = {
  toFirestore(u: User): firebase.firestore.DocumentData {
    const d: firebase.firestore.DocumentData = { ...u };
    if (u.createdAt) d.createdAt = fromDate(new Date(u.createdAt));
    if (u.updatedAt) d.updatedAt = fromDate(new Date(u.updatedAt));
    return d;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): User {
    const d = snapshot.data(options);
    return {
      id: d.id,
      createdAt: d.createdAt?.toDate(),
      updatedAt: d.updatedAt?.toDate(),
      displayName: d.displayName,
      email: d.email,
      lastSignInAt: d.lastSignInAt?.toDate(),
      phone: d.phone,
      permissions: d.permissions
    };
  }
};
